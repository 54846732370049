import React from 'react'
import './_search-card.scss'

import { Image } from 'types'
import { Heading } from '../heading/heading'
import { Tag } from '../tag/tag'

export interface SearchCardProps {
  url?: string
  title?: string
  text?: string
  image?: Image
  meta?: { published?: string; updated?: string; tag?: { tag?: string } }
}

export const SearchCard = ({ url, title, text, image, meta }: SearchCardProps) => (
  <li className='search-card'>
    {url && (
      <a href={url} className='search-card__link'>
        <div className='search-card__content'>
          <Heading size='s' level={2} className='search-card__title'>
            <span className='search-card__title-span'>{title} </span>
          </Heading>
          <div className='search-card__ingress'>{text}</div>
        </div>

        {image && (
          <div className='search-card__image-container'>
            <img className='search-card__image' src={image.url} alt={image.alt} />
          </div>
        )}
        {meta && (
          <div className='search-card__meta-data'>
            <div className='search-card__date'>{meta.published}</div>
            <div className='search-card__updated'>{meta.updated}</div>
            <div className='search-card__tag'>{meta.tag?.tag && <Tag tag={meta.tag?.tag} />}</div>
          </div>
        )}
      </a>
    )}
    <hr className='search-card__line'></hr>
  </li>
)
