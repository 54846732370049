import React from 'react'
import './_logo.scss'
import Lottie from 'lottie-react'
import animationData from 'assets/logo/lottie-logo.json'

interface LogoProps extends Omit<React.ComponentProps<typeof Lottie>, 'animationData'> {}

export const Logo = (props: LogoProps) => {
  return <Lottie {...props} animationData={animationData} className='logo' />
}
