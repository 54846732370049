import { Button } from 'components/elements/button/button'
import React from 'react'
import { TitleAndIngress } from '../../../elements/title-and-ingress/title-and-ingress'
import './_promo-header.scss'

interface PromoHeaderProps extends React.ComponentProps<typeof TitleAndIngress> {
  image: { url: string; alt: string }
  button: React.ComponentProps<typeof Button>
}

export const PromoHeader = ({ image, heading, ingress, color, button }: PromoHeaderProps) => {
  return (
    <div className='promo-header'>
      <img className='promo-header__image' src={image.url} alt={image.alt} />
      <div className='promo-header__title-content'>
        <div className='promo-header__title-wrapper'>
          <TitleAndIngress
            ingress={ingress}
            color={color}
            heading={{ ...heading, level: 1, size: 'display' }}
          />
        </div>
        {button && <Button {...button} />}
      </div>
    </div>
  )
}
