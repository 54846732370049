import React from 'react'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

import cn from 'classnames'
import './_important-info-block.scss'
import { useColor } from 'context/themeContext'

export interface ImportantInfoBlockProps {
  text: string
  overrideColor?: string
  className?: string
}

export const ImportantInfoBlock = ({ text, overrideColor, className }: ImportantInfoBlockProps) => {
  const { color } = useColor()
  return (
    <div
      className={cn('important-info-block', className, 
        `important-info-block__color-${overrideColor ? overrideColor : color}`
      )}
    >
      <InfoIcon className='important-info-block__icon' />
      <p className={cn('important-info-block__text', className)}>{text}</p>
    </div>
  )
}
