import React, { useEffect, useRef } from 'react'
import './_layout.scss'
import { Breadcrumbs } from './breadcrumbs/breadcrumbs'
import { Footer } from './footer/footer'
import { Menu, MenuFuncs } from './menu/menu'
import { ColorProvider, colorType } from 'context/themeContext'
import { shoppingCartContextType, userType } from 'types'
import { OFASFooter } from './OFAS-footer/OFAS-footer'
import { OFASMenu, OFASMenuFuncs } from './OFAS-menu/OFAS-menu'
import { UserContextProvider } from 'context/userContext'
import { ShoppingCartContextProvider } from 'context/shoppingCartContext'
import { HeaderContextProvider } from 'context/headerContext'

interface LayoutProps {
  menu: React.ComponentProps<typeof Menu> | React.ComponentProps<typeof OFASMenu>
  breadcrumbs?: React.ComponentProps<typeof Breadcrumbs>
  children?: React.ReactNode
  footer: React.ComponentProps<typeof Footer> | React.ComponentProps<typeof OFASFooter>
  className?: string
  theme?: colorType
  userContext?: userType
  shoppingCartContext?: shoppingCartContextType
  totalAmount?: number
  shippingCost?: number
  isOFAS?: boolean
  isFrontPage?: boolean
}

export const Layout = ({
  menu,
  breadcrumbs,
  children,
  footer,
  className,
  theme = { color: 'white', type: 'solid' },
  userContext,
  shoppingCartContext,
  totalAmount,
  shippingCost,
  isOFAS = false,
  isFrontPage,
}: LayoutProps) => {
  const menuRef = useRef<MenuFuncs | OFASMenuFuncs>(null)
  const closeExpandables = () => menuRef.current?.closeExpandables()

  useEffect(() => {
    let element = document.querySelector('#main-id')
    if (element !== null) {
      element.addEventListener('focusin', closeExpandables)
      return () => element?.removeEventListener('focusin', closeExpandables)
    }
  }, [])

  return (
    <UserContextProvider userProp={userContext ?? null}>
      <ShoppingCartContextProvider
        maxProductAmountProp={shoppingCartContext?.maxProductAmount ?? 10}
        shoppingCartProp={shoppingCartContext?.shoppingCart ?? []}
        shippingCostProp={shippingCost ?? 0}
        totalAmountProp={totalAmount ?? 0}
      >
        <HeaderContextProvider>
          {isOFAS ? <OFASMenu ref={menuRef}  {...menu}/>: <Menu ref={menuRef} {...menu} hasAnimatedLogo={isFrontPage ? true : false} />}
          <ColorProvider value={theme}>
            {children && (
              <main
                id='main-id'
                className={`layout layout__background-${
                  theme.type === 'gradient' ? theme.color : 'white'
                } ${className}`}
              >
                <div className={`layout__content`}>
                  {breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
                  {children}
                </div>
              </main>
            )}
            {isOFAS ? <OFASFooter {...footer} /> : <Footer {...footer} />}
          </ColorProvider>
        </HeaderContextProvider>
      </ShoppingCartContextProvider>
    </UserContextProvider>
  )
}
