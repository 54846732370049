import React from 'react'
import './_product-card.scss'
import cn from 'classnames'

import { Image } from 'types'
import { Heading } from '../heading/heading'
import { useColor } from 'context/themeContext'
import { Button } from '../button/button'
import { useShoppingCart } from 'context/shoppingCartContext'
import { useHeader } from 'context/headerContext'

export interface ProductCardProps {
  productId: number
  url: string
  image: Image
  price: string
  title?: string
  endpoint: string
  shoppingCartUrl?: string
}

export const ProductCard = ({
  image,
  url,
  price,
  title,
  productId,
  endpoint,
  shoppingCartUrl,
}: ProductCardProps) => {
  const { color } = useColor()
  const { shoppingCart, addProduct, maxProductAmount } = useShoppingCart()
  const { setShowHeader } = useHeader()

  const quantity = shoppingCart.find(product => product.id === productId)?.quantity ?? 0

  return (
    <div
      className={cn('product-card', {
        [`color-${color === 'orange' ? 'dark-red' : color}`]: color !== 'white',
      })}
    >
      <a href={url} className='product-card__link'>
        <div
          className={cn('product-card__image', {
            [`bgcolor-${color === 'orange' ? 'dark-red' : color}-1`]: color !== 'white',
          })}
        >
          <img src={image?.url} alt={image?.alt} />
        </div>
        <Heading
          size='s'
          level={3}
          className={cn('product-card__title', {
            [`product-card__title-${color}`]: color,
          })}
          weight={400}
        >
          <span>{title}</span>
        </Heading>
      </a>
      <p className='product-card__price'>{price}</p>
      <div className='product-card__button'>
        <Button
          onClick={async () => {
            if(quantity < maxProductAmount){
              var result = await addProduct(productId, endpoint, 1)
              if (result && shoppingCartUrl) window.location.href = `${shoppingCartUrl}`
              else if(result) setShowHeader(true)
            }
          }}
          color={color === 'dark-red' || color === 'yellow' || color === 'white' ? 'red' : color}
        >
          Legg til i handlekurven
        </Button>
      </div>
    </div>
  )
}
