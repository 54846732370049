import React from 'react'
import { Button } from '../../elements/button/button'
import './_promo-block.scss'
import { ReactComponent as VectorIcon } from 'assets/icons/vector.svg'
import { Image } from 'types'

interface PromoBlockProps {
  tag?: {
    title: string
  }
  title: string
  text: string
  image: Image
  button: React.ComponentProps<typeof Button>
}

export const PromoBlock = ({ tag, title, text, button, image }: PromoBlockProps) => {
  return (
    <div className='promo-block bg'>
      <img className='promo-block__image content-ignore' src={image.url} alt={image.alt} />
      <div className='promo-block__title-content'>
        {tag && (
          <div className='promo-block__tag'>
            <VectorIcon className='promo-block__tagicon' />
            {tag.title}
          </div>
        )}
        <h2 className='promo-block__title'>{title}</h2>
        <p className='promo-block__text'>{text}</p>
        <Button className='promo-block__button' {...button} />
      </div>
    </div>
  )
}
