import { Button } from 'components/elements/button/button'
import { Checkbox } from 'components/elements/forms/checkbox/checkbox'
import { useState, useRef } from 'react'
import { InputField, InputFieldFuncs } from '../../elements/forms/input-field/input-field'
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg'
import { ErrorMessage } from '../../elements/error-message/error-message'
import {
  postalCodeIsValid,
  emailIsValid,
  passwordIsValid,
  phoneNumberIsValid,
} from 'helpers/validation'
import { Collapse } from 'react-collapse'
import cn from 'classnames'
import './_form.scss'
import UserService from 'services/user_service'

interface FormProps {
  inputFields: React.ComponentProps<typeof InputField>[]
  secondaryInputFields?: React.ComponentProps<typeof InputField>[]
  checkboxes?: React.ComponentProps<typeof Checkbox>[]
  submitButtonText?: string
  isCreateUserForm?: boolean
  isEditUserForm?: boolean
  errorMessage: string
  requiredFieldsErrorMessage?: string
  inputErrorMessages: {
    email?: string
    postalCode?: string
    password?: string
    phone?: string
  }
  createUserEndpoint?: string
  editUserEndpoint?: string
}

export const Form = ({
  inputFields,
  secondaryInputFields,
  checkboxes,
  submitButtonText,
  isCreateUserForm = false,
  isEditUserForm = false,
  errorMessage,
  requiredFieldsErrorMessage,
  inputErrorMessages,
  createUserEndpoint,
  editUserEndpoint,
}: FormProps) => {
  const [saved, setSaved] = useState(false)

  const [error, setError] = useState(false)

  const [requiredFieldsMissing, setRequiredFieldsMissing] = useState(false)

  const [inputData, setInputData] = useState(inputFields)
  let longInputFieldArray: React.ComponentProps<typeof InputField>[] = []
  let shortInputFieldArray: React.ComponentProps<typeof InputField>[] = []

  const [secondaryInputData, setSecondaryInputData] = useState(secondaryInputFields)

  const [checkboxesList, setCheckboxesList] = useState(checkboxes)

  const [isOpen, setIsOpen] = useState(false)

  const collapseRef = useRef<HTMLDivElement>(null)
  const inputPhoneRef = useRef<InputFieldFuncs>(null)

  const toggle = () => {
    setIsOpen(!isOpen)
    secondaryInputFields && secondaryInputFields.forEach((input) => (input.value = ''))
  }

  inputFields.forEach((inputField) => {
    inputField.short ? shortInputFieldArray.push(inputField) : longInputFieldArray.push(inputField)
  })

  const newPassword = inputData.find((input) => input.validationType === 'newPassword')

  const hasRequiredFieldsMissing = (inputData: React.ComponentProps<typeof InputField>[]) => {
    if (
      inputData.some((input) => input.required && input.value === '') ||
      inputData.some(
        (input) => input.value && input.validationType === 'email' && !emailIsValid(input.value),
      ) ||
      inputData.some(
        (input) =>
          input.value && input.validationType === 'postalCode' && !postalCodeIsValid(input.value),
      ) ||
      inputData.some(
        (input) =>
          input.value && input.validationType === 'newPassword' && !passwordIsValid(input.value),
      ) ||
      inputData.some(
        (input) =>
          input.value && input.validationType === 'phone' && !phoneNumberIsValid(input.value),
      ) ||
      inputData.some(
        (input) =>
          input.value &&
          input.validationType === 'repeatedNewPassword' &&
          input.value !== newPassword?.value,
      )
    ) {
      return true
    } else return false
  }

  const applyInputErrorsOnSubmit = (inputFields: React.ComponentProps<typeof InputField>[]) => {
    inputFields.forEach((input: React.ComponentProps<typeof InputField>) =>
      (input.required && input.value === '') ||
      (input.value && input.validationType === 'email' && !emailIsValid(input.value)) ||
      (input.value && input.validationType === 'postalCode' && !postalCodeIsValid(input.value)) ||
      (input.value && input.validationType === 'newPassword' && !passwordIsValid(input.value)) ||
      (input.value && input.validationType === 'phone' && (!phoneNumberIsValid(input.value) || (input.countryCodePrefix && inputPhoneRef.current?.getCountryId() === undefined))) ||
      (input.value &&
        input.validationType === 'repeatedNewPassword' &&
        input.value !== newPassword?.value)
        ? ((input.error = true),
          (input.errorText =
            input.validationType === 'email'
              ? inputErrorMessages.email
              : input.validationType === 'postalCode'
              ? inputErrorMessages.postalCode
              : input.validationType === 'newPassword'
              ? inputErrorMessages.password
              : input.validationType === 'repeatedNewPassword'
              ? 'Passord matcher ikke'
              : input.validationType === 'phone'
              ? inputErrorMessages.phone
              : undefined))
        : (input.error = false),
    )
  }

  const validateInput = (inputField: React.ComponentProps<typeof InputField>, e: any) => {
    if (inputField.validationType !== 'email' || 'postalCode' || 'password') {
      inputField.error = e.target.value === '' && inputField.required
    }
    if (inputField.validationType === 'email') {
      inputField.errorText = emailIsValid(e.target.value) ? undefined : inputErrorMessages.email
      inputField.error = !emailIsValid(e.target.value) || e.target.value === ''
    }
    if (inputField.validationType === 'postalCode') {
      inputField.errorText = postalCodeIsValid(e.target.value)
        ? undefined
        : inputErrorMessages.postalCode
      inputField.error = !postalCodeIsValid(e.target.value) || e.target.value === ''
    }
    if (inputField.validationType === 'newPassword') {
      inputField.errorText = passwordIsValid(e.target.value)
        ? undefined
        : inputErrorMessages.password
      inputField.error = !passwordIsValid(e.target.value) || e.target.value === ''
    }
    if (inputField.validationType === 'phone') {
      inputField.errorText = phoneNumberIsValid(e.target.value)
        ? undefined
        : inputErrorMessages.phone
      inputField.error = !phoneNumberIsValid(e.target.value) || e.target.value === ''
    }

    if (inputField.validationType === 'repeatedNewPassword') {
      inputField.errorText =
        e.target.value === newPassword?.value ? undefined : 'Passord matcher ikke'
      inputField.error = e.target.value !== newPassword?.value || e.target.value === ''
    }
  }

  const handleInputChange = (inputField: React.ComponentProps<typeof InputField>, e: any) => {
    inputField.value = e.target.value
    requiredFieldsMissing && validateInput(inputField, e)
  }

  const handleCheckboxChange = (checked: boolean, index: any) => {
    if (checkboxesList) {
      const currentCheckboxesList = checkboxesList
      const clickedItem = currentCheckboxesList[index]
      clickedItem.checked = checked
      setCheckboxesList(currentCheckboxesList)
    }
  }

  const getInputData = () => {
    let data: any = {}

    inputData.forEach((input) => {
      if (input && input.countryCodePrefix && inputPhoneRef.current?.getCountryId() !== undefined) {
        data[input.countryCodePrefix.countryIdParamName] = inputPhoneRef.current.getCountryId()
      }
      if (input && input.id) data[input.id] = input.value
    })

    if (secondaryInputData)
      secondaryInputData.forEach((input) => {
        if (input && input.id) data[input.id] = input.value
      })

    if (checkboxesList)
      checkboxesList.forEach((input) => {
        if (input && input.id) data[input.id] = input.checked
      })

    return data
  }

  return (
    <form
      className={cn('form', {
        'form--create-user': isCreateUserForm,
      })}
    >
      <div className='form__content'>
        <div className='form__input'>
          {longInputFieldArray.map((inputField) => (
            <div key={inputField.id} className='form__input-long'>
              <InputField
                {...inputField}
                value={inputField.value}
                onChange={(e) => {
                  handleInputChange(inputField, e)
                  setInputData([...inputFields])
                }}
                ref={inputField.countryCodePrefix && inputPhoneRef}
              />
            </div>
          ))}
          {shortInputFieldArray.map((inputField, i) => {
            if (i % 2 !== 0) {
              return null
            } else
              return (
                <div key={inputField.id} className='form__input-short'>
                  <InputField
                    {...inputField}
                    value={inputField.value}
                    onChange={(e) => {
                      handleInputChange(inputField, e)
                      setInputData([...inputFields])
                    }}
                  />
                  {shortInputFieldArray[i + 1] && (
                    <InputField
                      key={inputField.id}
                      {...shortInputFieldArray[i + 1]}
                      value={shortInputFieldArray[i + 1].value}
                      onChange={(e) => {
                        handleInputChange(shortInputFieldArray[i + 1], e)
                        setInputData([...inputFields])
                      }}
                    />
                  )}
                </div>
              )
          })}
        </div>
        {/* COMMENTED OUT DUE TO NOT HAVING THESE FIELDS ON THE SUPEROFFICE OBJECT FOR A USER */}
        {/* {secondaryInputFields && (
          <div>
            <Checkbox
              handleClick={toggle}
              label='Send til annen adresse'
              name='newsletter-checkbox'
              id='newsletter-checkbox'
              checked={false}
            />
            <Collapse isOpened={isOpen} theme={{ collapse: 'form__secondary-inputs' }}>
              <div className='form__secondary-inputs-content' ref={collapseRef}>
                <div className='form__input'>
                  {secondaryInputFields.map((inputField) => (
                    <div key={inputField.title} className='form__input-long'>
                      <InputField
                        {...inputField}
                        value={inputField.value}
                        onChange={(e) => {
                          handleInputChange(inputField, e)
                          setSecondaryInputData([...secondaryInputFields])
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Collapse>
          </div>
        )}
        {checkboxesList && (
          <div className='form__checkboxes'>
            {checkboxesList?.map((checkbox, index) => (
              <Checkbox
                key={checkbox.id}
                label={checkbox.label}
                name={checkbox.name}
                id={checkbox.id}
                checked={checkbox.checked}
                handleClick={(checked) => {
                  handleCheckboxChange(checked, index)
                }}
              />
            ))}
          </div>
        )} */}
        {(error || requiredFieldsMissing) && errorMessage && requiredFieldsErrorMessage && (
          <div className='form__error'>
            <ErrorMessage text={error ? errorMessage : requiredFieldsErrorMessage} />
          </div>
        )}

        <div className='form__button'>
          <Button
            type='submit'
            text={submitButtonText}
            disabled={inputData === inputFields && isEditUserForm}
            onClick={(e) => {
              e.preventDefault()
              applyInputErrorsOnSubmit(inputFields)
              secondaryInputFields && isOpen && applyInputErrorsOnSubmit(secondaryInputFields)
              if (
                hasRequiredFieldsMissing(inputData) ||
                (secondaryInputData && hasRequiredFieldsMissing(secondaryInputData) && isOpen)
              ) {
                setRequiredFieldsMissing(true)
                setError(false)
              } else {
                setRequiredFieldsMissing(false)
                isCreateUserForm &&
                  createUserEndpoint &&
                  UserService.createUser(
                    getInputData(),
                    createUserEndpoint,
                    setError,
                  )
                isEditUserForm &&
                  editUserEndpoint &&
                  UserService.editUser(
                    getInputData(),
                    editUserEndpoint,
                    setError,
                    setSaved,
                  )
              }
            }}
          />
          {saved && (
            <div className='form__button__saved'>
              <CheckIcon />
              <p>Lagret</p>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
