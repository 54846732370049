import React from 'react'
import cn from 'classnames'
import './_heading.scss'

interface HeadingProps {
  title?: React.ReactNode
  children?: React.ReactNode
  level?: 1 | 2 | 3 | 4 | 5 | 6
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'hero' | 'display'
  className?: string
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
}

export const Heading = ({ level = 2, size, className, title, children, weight }: HeadingProps) => {
  return React.createElement(
    'h' + level,
    {
      className: cn('heading', className, {
        [`heading__size-${size}`]: size,
      }),
      style: { fontWeight: weight },
    },
    children ? children : title,
  )
}
