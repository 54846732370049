import { Button } from 'components/elements/button/button'
import UserService from 'services/user_service'
import { useState } from 'react'
import { Checkbox } from '../checkbox/checkbox'
import { InputField } from '../input-field/input-field'
import { ErrorMessage } from '../../error-message/error-message'
import './_login-form.scss'

interface LoginFormProps {
  phoneNumberLabel: string
  passwordLabel: string
  errorMessage: string
  emptyInputErrorMessage: string
  returnUrl: string
  forgotPasswordLink?: {
    text: string
    url: string
  }
  createUserLink?: {
    text: string
    url: string
  }
  checkboxLabel?: string
  loginButtonText: string
  endpoint: string
}

export const LoginForm = ({
  phoneNumberLabel,
  passwordLabel,
  errorMessage,
  emptyInputErrorMessage,
  returnUrl,
  forgotPasswordLink,
  createUserLink,
  checkboxLabel,
  loginButtonText,
  endpoint,
}: LoginFormProps) => {
  const [loginInfo, setLoginInfo] = useState({ phone: '', password: '' })
  const [rememberUser, setRememberUser] = useState(false)
  const [error, setError] = useState(false)
  const [emptyInputError, setEmptyInputError] = useState(false)

  // TODO: Functionality for rememberUser

  const handleChange = (key: 'phone' | 'password', value: string) => {
    var newLoginInfo = { ...loginInfo }
    newLoginInfo[key] = value
    setLoginInfo(newLoginInfo)
    setError(false)
    setEmptyInputError(false)
  }

  return (
    <form className='login-form'>
      <div className='login-form__content'>
        {phoneNumberLabel && (
          <InputField
            title={phoneNumberLabel}
            required
            error={emptyInputError}
            onChange={(e) => handleChange('phone', e.target.value)}
          />
        )}
        {passwordLabel && (
          <InputField
            title='Passord'
            required
            type='password'
            error={emptyInputError}
            onChange={(e) => handleChange('password', e.target.value)}
          />
        )}
        {error && errorMessage && <ErrorMessage text={errorMessage} />}
        {emptyInputError && emptyInputErrorMessage && (
          <ErrorMessage text={emptyInputErrorMessage} />
        )}
        {forgotPasswordLink && (
          <a className='login-form__forgot-password' href={forgotPasswordLink.url}>
            <span>{forgotPasswordLink.text}</span>
          </a>
        )}
        {checkboxLabel && (
          <Checkbox handleClick={() => setRememberUser(!rememberUser)} label={checkboxLabel} />
        )}
        {loginButtonText && (
          <div className='login-form__buttons'>
            <Button
              type='submit'
              size='m'
              text={loginButtonText}
              onClick={(e) => {
                e.preventDefault()
                if (loginInfo.phone !== '' && loginInfo.password !== '') {
                  UserService.signIn(loginInfo, setError, returnUrl, endpoint)
                  setEmptyInputError(false)
                } else {
                  setEmptyInputError(true)
                  setError(false)
                }
              }}
            />

            {createUserLink && (
              <Button size='m' text={createUserLink.text} outline url={createUserLink.url} />
            )}
          </div>
        )}
      </div>
    </form>
  )
}
