import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'
import cn from 'classnames'
import { QuantityCounter } from 'components/elements/quantity-counter/quantity-counter'
import { useShoppingCart } from 'context/shoppingCartContext'
import { useColor } from 'context/themeContext'
import { useUser } from 'context/userContext'
import './_checkout-products-overview.scss'

interface CheckoutProductsOverviewProps {
  productTextLabel: string
  productArticleNumberLabel: string
  quantityLabel: string
  priceLabel: string
  removeProductLabel: string
  noProductsText: string
  removeProductEndpoint: string
  editProductQuantityEndpoint: string
  freeProductWarningText?: string
}

export const CheckoutProductsOverview = ({
  productTextLabel,
  productArticleNumberLabel,
  quantityLabel,
  priceLabel,
  removeProductLabel,
  noProductsText,
  removeProductEndpoint,
  editProductQuantityEndpoint,
  freeProductWarningText,
}: CheckoutProductsOverviewProps) => {
  const { shoppingCart, removeProduct } = useShoppingCart()
  const { user } = useUser()
  const { color } = useColor()
  const colorStyle = color === 'green' ? 'green' : 'blue'

  return shoppingCart.length > 0 ? (
    <div className={cn('checkout-products-overview', `checkout-products-overview__${colorStyle}`)}>
      <div className={cn('label-row', `label-row__${colorStyle}`)}>
        <div className='label-row__first-row'>
          <div className='label-row__image'></div>
          <div className='label-row__product'>{productTextLabel}</div>
          <div className='label-row__info'>
            <div className='label-row__info__product-article-number'>{productArticleNumberLabel}</div>
            <div className='label-row__info__quantity'>{quantityLabel}</div>
            <div className='label-row__info__price'>{priceLabel}</div>
          </div>
        </div>
        <div className='label-row__product-remove'></div>
      </div>
      {shoppingCart.find((item) => item.price === 0) !== undefined && user === null && (
        <div className='checkout-products-overview__free-product-warning'>
          <p>{freeProductWarningText}</p>
        </div>
      )}
      {shoppingCart.map((product) => (
        <div key={product.id} className='checkout-products-overview__product'>
          <div className='checkout-products-overview__top-row'>
            <div className='checkout-products-overview__product-link small-screen'>
              <a href={product.url}>
                <span>{product.title}</span>
              </a>
            </div>
            <button
              className='checkout-products-overview__product-remove'
              onClick={() => {
                removeProduct(product.id, removeProductEndpoint)
              }}
            >
              <TrashIcon />
              <span>{removeProductLabel}</span>
            </button>
          </div>
          <div className='checkout-products-overview__bot-row'>
            <div className='checkout-products-overview__product-image'>
              <img src={product.image.url} alt={product.image.alt} />
            </div>
            <div
              className={cn(
                'checkout-products-overview__product-link',
                'large-screen',
                `checkout-products-overview__product-link__${colorStyle}`,
              )}
            >
              <a href={product.url}>
                <span>{product.title}</span>
              </a>
            </div>
            <div className='checkout-products-overview__product-info'>
              <div className='checkout-products-overview__product-article-number'>
                <p className='small-screen'>{productArticleNumberLabel}</p>
                <div className='checkout-products-overview__product-article-number-text'>{product.articleNumber}</div>
              </div>
              <div className='checkout-products-overview__product-quantity'>
                <p className='checkout-products-overview__product-quantity__label small-screen'>
                  {quantityLabel}:
                </p>
                <QuantityCounter
                  productId={product.id}
                  initQuantity={product.quantity}
                  editProductQuantityEndpoint={editProductQuantityEndpoint}
                  removeProductEndpoint={removeProductEndpoint}
                ></QuantityCounter>
              </div>
              <div className='checkout-products-overview__product-price'>
                <p className='checkout-products-overview__product-price__label small-screen'>
                  {priceLabel}:
                </p>
                {product.price.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div>{noProductsText}</div>
  )
}
