import React, {  } from 'react'
import { Layout } from '../../components/layout/layout'
import './list-page.scss'
import { Header } from 'components/layout/header'
import { pagedFilterType, pageProps } from 'types'
import { ArticleList } from 'components/blocks/article-list/article-list'
import { Filter } from 'components/blocks/filter/filter'
import { ListSearchBlock } from 'components/blocks/list-search-block/list-search-block'
import { ArticleCardProps } from 'components/elements/article-card/article-card'

interface ListPageProps extends Omit<pageProps, 'blocks'> {
  filterProps: {
    filterOptions: React.ComponentProps<typeof Filter>['filterOptions']
    clearFilterText: string
    filterLabel: string
  }
  defaultFilter: pagedFilterType
  queryUrl: string
  errorMessage: string
  noResultsText: string
}

export const ListPage = ({
  layout,
  header,
  filterProps,
  defaultFilter,
  queryUrl,
  errorMessage,
  noResultsText,
}: ListPageProps) => {
  return (
    <Layout
      {...layout}
      theme={layout.theme ?? {color: 'green', type: 'gradient'}}
      className='list-page bg-green-1'
    >
      {header && <Header {...header} />}
      <div className='list-page__list-search-block'>
        <ListSearchBlock 
          queryUrl={queryUrl}
          filterProps={{
            clearFilterText: filterProps.clearFilterText,
            filterLabel: filterProps.filterLabel,
            filterOptions: filterProps.filterOptions
          }}
          defaultFilter={defaultFilter}
          noResultsText={noResultsText}
          errorMessage={errorMessage}
          displayData={(results: ArticleCardProps[]) => 
              <ArticleList articles={results} title={''} />
          }
        />
      </div>
    </Layout>
  )
}
