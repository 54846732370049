import React from 'react'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import { Button } from 'components/elements/button/button'
import { RadioListBlock } from 'components/blocks/radio-list-block/radio-list-block'
import { pageProps } from 'types'
import { Accordion } from 'components/blocks/accordion/accordion'
import { CheckoutTotal } from 'components/elements/checkout-total/checkout-total'
import { CheckoutForm } from 'components/blocks/checkout-form/checkout-form'
import { Form } from 'components/blocks/form/form'
import { Checkbox } from 'components/elements/forms/checkbox/checkbox'
import { InputField } from '../../components/elements/forms/input-field/input-field'
import { ErrorMessage } from '../../components/elements/error-message/error-message'
import {
  hasRequiredFieldsMissing,
  validateInput,
  applyInputErrorsOnSubmit,
} from 'helpers/validation'
import axios from 'axios'
import './_checkoutPage.scss'
import { Heading } from 'components/elements/heading/heading'
import { useShoppingCart } from 'context/shoppingCartContext'
import { useUser } from 'context/userContext'
import cn from 'classnames'
import { useColor } from 'context/themeContext'

interface CheckoutPageProps extends pageProps {
  checkoutProductsOverviewProps: {
    productTextLabel: string
    productIdLabel: string
    quantityLabel: string
    priceLabel: string
    removeProductLabel: string
    noProductsText: string
    removeProductEndpoint: string
    editProductQuantityEndpoint: string
    freeProductWarningText?: string
  }
  totalPriceText: string
  priceWithoutShippingLabel: string
  shippingCostLabel: string
  backToStoreLink: React.ComponentProps<typeof Button>
  products: React.ComponentProps<typeof Accordion>
  cardPaymentEndpoint: string
  invoicePaymentEndpoint: string
  serverErrorMessage: string
  submitButtonText: string
  noteInput: React.ComponentProps<typeof InputField>
  loginButton: React.ComponentProps<typeof Button>

  checkoutForm: {
    cardPaymentInfoForm: React.ComponentProps<typeof Form>
    invoicePaymentInfoForm?: React.ComponentProps<typeof Form>
    deliveryAddressForm?: React.ComponentProps<typeof Form>
    shippingRadioListBlock: React.ComponentProps<typeof RadioListBlock>
    paymentRadioListBlock: React.ComponentProps<typeof RadioListBlock>
    paymentInfoTitle: string
    deliveryAddressTitle: string
    invoiceAddressTitle: string
    invoiceAddressForm?: React.ComponentProps<typeof Form>
    requiredFieldsErrorMessage: string
    invoiceLabelNotLoggedIn: string
    useDifferentDeliveryAddress?: React.ComponentProps<typeof Checkbox>
  }
}

export const CheckoutPage = ({
  layout,
  header,
  totalPriceText,
  priceWithoutShippingLabel,
  shippingCostLabel,
  backToStoreLink,
  products,
  checkoutForm,
  cardPaymentEndpoint,
  invoicePaymentEndpoint,
  checkoutProductsOverviewProps,
  serverErrorMessage,
  submitButtonText,
  noteInput,
  loginButton,
}: CheckoutPageProps) => {
  const [currentSelectedPayment, setCurrentSelectedPayment] = React.useState(
    checkoutForm.paymentRadioListBlock.radioList
      ? checkoutForm.paymentRadioListBlock.radioList[0].value
      : '',
  )

  const paymentForm =
    currentSelectedPayment === checkoutForm.paymentRadioListBlock.radioList[0].value
      ? checkoutForm.cardPaymentInfoForm
      : checkoutForm.invoicePaymentInfoForm
  const [formData, setFormData] = React.useState(paymentForm ? paymentForm.inputFields : [])

  const [deliveryAddressFormData, setDeliveryAddressFormData] = React.useState(
    checkoutForm.deliveryAddressForm?.inputFields,
  )

  const [invoiceAddressFormData, setInvoiceAddressFormData] = React.useState(
    checkoutForm.invoiceAddressForm?.inputFields,
  )

  const [currentProducts, setCurrentProducts] = React.useState([])

  const [requiredFieldsMissing, setRequiredFieldsMissing] = React.useState(false)
  const [serverError, setServerError] = React.useState(false)

  const [noteInputData, setNoteInputData] = React.useState(noteInput.value)

  const [errorResMsg, setErrorResMsg] = React.useState('')
  const [disableSubmitButton, setDisableSubmitButton] = React.useState(false)
  const [isChecked, setIsChecked] = React.useState(false)

  const handleInputChange = (
    inputField: React.ComponentProps<typeof InputField>,
    e: any,
    form: React.ComponentProps<typeof Form>,
  ) => {
    inputField.value = e.target.value

    form && requiredFieldsMissing && validateInput(inputField, e, form)

    paymentForm && setFormData([...paymentForm.inputFields])
    checkoutForm.deliveryAddressForm &&
      isChecked &&
      setDeliveryAddressFormData([...checkoutForm.deliveryAddressForm.inputFields])
    checkoutForm.invoiceAddressForm &&
      setInvoiceAddressFormData([...checkoutForm.invoiceAddressForm.inputFields])
  }

  const handleNoteInputChange = (e: any) => {
    noteInput.value = e.target.value
    setNoteInputData(noteInput.value)
  }

  const sendForm = async (endpoint: string) => {
    const payload = {
      paymentInfoFormData: formData,
      invoiceAddressFormData: invoiceAddressFormData,
      deliveryAddressFormData: deliveryAddressFormData,
      products: currentProducts,
      note: noteInputData,
    }
    try {
      const response = await axios.post(endpoint, payload)
      if (response.data.url) window.location.href = `/${response.data.url}`

      if (response.data.errorMessage) {
        setErrorResMsg(response.data.errorMessage)
        return false
      }
      return true
    } catch (e) {
      setServerError(true)
      return false
    }
  }

  const paymentButtonPressed = async (e: any) => {
    e.preventDefault()
    var inputFields = checkoutForm.paymentRadioListBlock.radioList[0].isSelected
      ? checkoutForm.cardPaymentInfoForm.inputFields
      : checkoutForm.invoicePaymentInfoForm?.inputFields

    applyInputErrorsOnSubmit(inputFields ?? [], paymentForm)
    if (checkoutForm.paymentRadioListBlock.radioList[1].isSelected) {
      deliveryAddressFormData &&
        isChecked &&
        applyInputErrorsOnSubmit(deliveryAddressFormData, checkoutForm.invoiceAddressForm)
      invoiceAddressFormData &&
        applyInputErrorsOnSubmit(invoiceAddressFormData, checkoutForm.invoiceAddressForm)
    }

    if (
      hasRequiredFieldsMissing(inputFields ?? []) ||
      (currentSelectedPayment === checkoutForm.paymentRadioListBlock.radioList[1].value &&
        invoiceAddressFormData &&
        hasRequiredFieldsMissing(invoiceAddressFormData)) ||
      (currentSelectedPayment === checkoutForm.paymentRadioListBlock.radioList[1].value &&
        deliveryAddressFormData &&
        isChecked &&
        hasRequiredFieldsMissing(deliveryAddressFormData))
    ) {
      setRequiredFieldsMissing(true)
    } else {
      setRequiredFieldsMissing(false)
      setServerError(false)
      let res = await sendForm(
        currentSelectedPayment === checkoutForm.paymentRadioListBlock.radioList[1].value
          ? invoicePaymentEndpoint
          : cardPaymentEndpoint,
      )
      if (!res) setDisableSubmitButton(false)
    }
  }

  return (
    <Layout {...layout} theme={layout.theme ?? { color: 'green' }} className='shopping-cart-page'>
      {header && <Header {...header} />}
      <div className='shopping-cart-page__body'>
        {products && <Accordion {...products} />}
        {checkoutForm && (
          <CheckoutForm
            setFormData={setFormData}
            currentSelectedPayment={currentSelectedPayment}
            setCurrentSelectedPayment={(e: any) => {
              setRequiredFieldsMissing(false)
              setCurrentSelectedPayment(e)
            }}
            handleInputChange={handleInputChange}
            paymentForm={paymentForm}
            setCurrentProducts={setCurrentProducts}
            requiredFieldsMissing={requiredFieldsMissing}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            {...checkoutForm}
          />
        )}
        <PaymentFormBottomContentWrapper
          noteInput={noteInput}
          handleNoteInputChange={handleNoteInputChange}
          totalPriceText={totalPriceText}
          priceWithoutShippingLabel={priceWithoutShippingLabel}
          shippingCostLabel={shippingCostLabel}
          backToStoreLink={backToStoreLink}
          submitButtonText={submitButtonText}
          handleSubmitButtonPressed={paymentButtonPressed}
          freeProductWarningText={checkoutProductsOverviewProps.freeProductWarningText}
          loginButton={loginButton}
          buttonDisabled={disableSubmitButton}
        />
        {serverError && serverErrorMessage && (
          <div className='checkout-page__error'>
            <ErrorMessage text={serverErrorMessage} />
          </div>
        )}
        {errorResMsg && (
          <div className='checkout-page__error'>
            <ErrorMessage text={errorResMsg} />
          </div>
        )}
      </div>
    </Layout>
  )
}

export const PaymentFormBottomContentWrapper = ({
  noteInput,
  handleNoteInputChange,
  totalPriceText,
  priceWithoutShippingLabel,
  shippingCostLabel,
  backToStoreLink,
  submitButtonText,
  handleSubmitButtonPressed,
  freeProductWarningText,
  loginButton,
  buttonDisabled,
}: any) => {
  const { shoppingCart, totalCost } = useShoppingCart()
  const { user } = useUser()
  const { color } = useColor()
  const colorStyle = color === 'green' ? 'green' : 'blue'

  const onlyFreeProductsAndNoUserLoggedIn = () =>
    shoppingCart.length > 0 && totalCost === 0 && !user

  return onlyFreeProductsAndNoUserLoggedIn() ? (
    <div className='free-products-warning'>
      <p>{freeProductWarningText}</p>
      <Button url={loginButton?.url}>{loginButton?.text}</Button>
    </div>
  ) : (
    <div className='checkout-page__bottom-content'>
      <div>
        <Heading
          className={cn('notes-title', `notes-title__${colorStyle}`)}
          title='Beskjed (valgfritt)'
          level={4}
        ></Heading>
      </div>
      <div className='notes-and-total'>
        <div className='left-content'>
          {noteInput && (
            <InputField
              onChange={(e) => {
                handleNoteInputChange(e)
              }}
              {...noteInput}
              title=''
            />
          )}
        </div>
        <div className='right-content'>
          {totalPriceText && (
            <CheckoutTotal
              className={cn('checkout-page__total', `checkout-page__total__${colorStyle}`)}
              totalPriceText={totalPriceText}
              priceWithoutShippingLabel={priceWithoutShippingLabel}
              shippingCostLabel={shippingCostLabel}
            />
          )}
        </div>
      </div>
      <div className='buttons'>
        {backToStoreLink && (
          <Button className='shopping-cart-page__button' outline={true} {...backToStoreLink} />
        )}
        {submitButtonText && (
          <Button
            disabled={buttonDisabled}
            type='submit'
            form='checkout-form'
            text={submitButtonText}
            onClick={handleSubmitButtonPressed}
          />
        )}
      </div>
    </div>
  )
}
