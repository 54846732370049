import React from 'react'
import cn from 'classnames'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'

import './_breadcrumbs.scss'
import { Link } from 'types'
import { useColor } from 'context/themeContext'

interface BreadcrumbsProps {
  links: Link[]
}

export const Breadcrumbs = ({ links }: BreadcrumbsProps) => {
  const { color, type } = useColor()
  return (
    <div>
      <ul
        className={cn('breadcrumbs', {
          [`breadcrumbs-${color}`]: type === 'solid',
        })}
        aria-label='breadcrumbs'
        role='navigation'
      >
        {links &&
          links.map((link, i) => (
            <li key={i}>
              <a
                href={link.url}
                className='breadcrumbs__link'
                {...(window.location.pathname === link.url ? { ariaCurrent: 'page' } : {})}
              >
                {i === 0 ? (
                  ''
                ) : i === links.length - 1 ? (
                  <>
                    <ArrowLeftIcon className='breadcrumbs__mobilearrow' />
                    <ArrowRightIcon className='breadcrumbs__icon' />
                  </>
                ) : (
                  <ArrowRightIcon className='breadcrumbs__icon' />
                )}
                <span className='breadcrumbs__linktext'>{link.text}</span>
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}
