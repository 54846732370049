import React from 'react'
import { filterPropsType, pagedFilterType, pageProps, searchBarPropsType } from 'types'
import { SearchCard, SearchCardProps } from 'components/elements/search-card/search-card'
import { Layout } from '../../components/layout/layout'
import { Header } from 'components/layout/header'
import './_search-page.scss'
import { ListSearchBlock } from 'components/blocks/list-search-block/list-search-block'

interface SearchPageProps extends Omit<pageProps, 'blocks'> {
  listSearchProps: {
    queryUrl: string
    searchBarProps: searchBarPropsType
    filterProps: filterPropsType
    defaultFilter: pagedFilterType
    errorMessage: string
    noResultsText: string
  }
  layout: React.ComponentProps<typeof Layout>
  header: React.ComponentProps<typeof Header>
}

export const SearchPage = ({ layout, header, listSearchProps }: SearchPageProps) => {
  return (
    <Layout
      {...layout}
      theme={layout.theme ?? { color: 'white', type: 'solid' }}
      className='search-page'
    >
      {header && <Header {...header} />}
      <div className='search-page__list-search-block'>
        <ListSearchBlock
          queryUrl={listSearchProps.queryUrl}
          searchBarProps={{
            placeHolderLabel: listSearchProps.searchBarProps.placeHolderLabel,
            searchButtonLabel: listSearchProps.searchBarProps.searchButtonLabel,
          }}
          filterProps={{
            filterLabel: listSearchProps.filterProps.filterLabel,
            clearFilterText: listSearchProps.filterProps.clearFilterText,
            filterOptions: listSearchProps.filterProps.filterOptions,
          }}
          defaultFilter={listSearchProps.defaultFilter}
          noResultsText={listSearchProps.noResultsText}
          errorMessage={listSearchProps.errorMessage}
          displayData={(results: SearchCardProps[]) => (
            <div className='search-page__results'>
              {results.map((result, i) => (
                <SearchCard
                  key={i}
                  url={result.url}
                  title={result.title}
                  text={result.text}
                  image={result.image}
                  meta={result.meta}
                />
              ))}
            </div>
          )}
        />
      </div>
    </Layout>
  )
}
