import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import { Accordion } from 'components/blocks/accordion/accordion'
import { ImportantInfoBlock } from 'components/blocks/important-info-block/important-info-block'
import './_my-profile-page.scss'

interface MyProfilePageProps {
  layout: React.ComponentProps<typeof Layout>
  header: React.ComponentProps<typeof Header>
  accordionList: React.ComponentProps<typeof Accordion>
  passwordSuccessMessage?: string
}

export const MyProfilePage = ({
  layout,
  header,
  accordionList,
  passwordSuccessMessage,
}: MyProfilePageProps) => {
  return (
    <Layout {...layout} theme={layout.theme ?? { color: 'blue'} } className='my-profile-page'>
      {passwordSuccessMessage && (
        <div className='my-profile-page__success-message'>
          <ImportantInfoBlock
            className='important-info-block__color-green'
            text={passwordSuccessMessage}
          />
        </div>
      )}
      {header && <Header {...header} />}
      {accordionList && <Accordion {...accordionList} />}
    </Layout>
  )
}
