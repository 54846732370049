import React from 'react'
import { Block } from 'types'
import { Form } from '../form/form'
import { MediaBlock } from '../media-block/media-block'
import { RichText } from '../rich-text/rich-text'
import { ChangePasswordForm } from '../../elements/change-password-form/change-password-form'
import { SubscriptionBlock } from '../subscription-block/subscription-block'
import { CheckoutProductsOverview } from '../checkout-products-overview/checkout-products-overview'

interface AccordionComponentListProps {
  blocks: Block[]
}

const AccordionComponents: { [key: string]: any } = {
  RichText: RichText,
  Form: Form,
  ChangePasswordForm: ChangePasswordForm,
  MediaBlock: MediaBlock,
  SubscriptionBlock: SubscriptionBlock,
  CheckoutProductsOverview: CheckoutProductsOverview,
}

export const AccordionComponentList = ({ blocks }: AccordionComponentListProps) => (
  <div className='accordion-component-list'>
    {blocks &&
      blocks.map((block, i) =>
        AccordionComponents[block.name] === undefined ? (
          <div key={i}>
            Component <b>'{block.name}'</b> is not supported
          </div>
        ) : (
          block.data &&
          React.createElement(AccordionComponents[block.name], {
            ...{ ...block.data, key: i },
          })
        ),
      )}
  </div>
)
