import { createContext, useContext, useEffect, useState } from 'react'
import ShoppingCartService from 'services/shopping-cart-service'
import { productType } from 'types'

type ShoppingCartContextType = {
  shoppingCart: productType[]
  productsCount: number
  shippingCost: number
  setShippingCost: React.Dispatch<React.SetStateAction<number>>
  totalCost: number
  setTotalCost: React.Dispatch<React.SetStateAction<number>>
  maxProductAmount: number
  addProduct: (productId: number, endpoint: string, amount: number) => Promise<boolean>
  removeProduct: (productId: number, endpoint: string) => void
  editProductQuantity: (
    productId: number,
    newQuantity: number,
    endpoint: string,
    removeEndpoint: string,
  ) => void
}

const initialShoppingCartContext: ShoppingCartContextType = {
  shoppingCart: [],
  productsCount: 0,
  shippingCost: 0,
  setShippingCost: () => {},
  totalCost: 0,
  setTotalCost: () => {},
  maxProductAmount: 10,
  addProduct: () => new Promise(() => {}),
  removeProduct: () => {},
  editProductQuantity: () => {},
}

export const ShoppingCartContext = createContext<ShoppingCartContextType>(
  initialShoppingCartContext,
)

interface ShoppingCartContextProviderProps {
  children: React.ReactNode
  shoppingCartProp: productType[]
  totalAmountProp: number
  shippingCostProp: number
  maxProductAmountProp: number
}

export const ShoppingCartContextProvider = ({
  children,
  shoppingCartProp,
  totalAmountProp,
  shippingCostProp,
  maxProductAmountProp,
}: ShoppingCartContextProviderProps) => {
  const [shoppingCart, setProducts] = useState<productType[]>(shoppingCartProp)
  const maxProductAmount = maxProductAmountProp
  const [shippingCost, setShippingCost] = useState(shippingCostProp)
  const [totalCost, setTotalCost] = useState(totalAmountProp)
  const [productsCount, setProductsCount] = useState(
    shoppingCart.reduce((accumulator, obj) => {
      return accumulator + obj.quantity
    }, 0),
  )

  async function addProduct(productId: number, endpoint: string, amount: number) {
    var body = await ShoppingCartService.addProduct(
      { productId: productId, quantity: amount },
      endpoint,
    )
    if (body.itemsInCart !== -1) {
      setProductsCount(body.itemsInCart)
      setTotalCost(body.totalprice)
      setShippingCost(body.shippingCost)
      return true
    }
    return false
  }

  async function removeProduct(productId: number, endpoint: string) {
    var body = await ShoppingCartService.removeProduct({ productId: productId }, endpoint)
    if (body.itemsInCart !== -1) {
      var removedProduct = shoppingCart.find((product) => product.id === productId)
      if (removedProduct !== undefined) {
        setProductsCount(body.itemsInCart)
        setTotalCost(body.totalprice)
        setShippingCost(body.shippingCost)
      }
      removeProductFromShoppingCart(productId)
    }
  }

  async function editProductQuantity(
    productId: number,
    newQuantity: number,
    editProductQuantityEndpoint: string,
    removeProductEndpoint: string,
  ) {
    if (newQuantity > 0) {
      var body: any = await ShoppingCartService.editProductQuantity(
        { productId: productId, newQuantity: newQuantity },
        editProductQuantityEndpoint,
      )

      if (body.itemsInCart !== -1) {
        var prevQuantity = shoppingCart.find((product) => product.id === productId)?.quantity

        if (prevQuantity !== undefined) {
          setProducts(
            shoppingCart.map((product) =>
              product.id === productId ? { ...product, quantity: newQuantity } : { ...product },
            ),
          )
          setProductsCount(body.itemsInCart)
          setTotalCost(body.totalprice)
          setShippingCost(body.shippingCost)
        }
      }
    } else if (newQuantity === 0) {
      removeProduct(productId, removeProductEndpoint)
    }
  }

  function removeProductFromShoppingCart(productId: number) {
    setProducts(shoppingCart.filter((product) => product.id !== productId))
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCart,
        productsCount,
        maxProductAmount,
        shippingCost,
        setShippingCost,
        setTotalCost,
        totalCost,
        addProduct,
        removeProduct,
        editProductQuantity,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}

export const useShoppingCart = () => {
  const shoppingCart = useContext(ShoppingCartContext)
  if (shoppingCart === undefined) {
    throw new Error('useShoppingcart was used outside of its Provider')
  }
  return shoppingCart
}
