import { Button } from 'components/elements/button/button'
import { TitleAndIngress } from 'components/elements/title-and-ingress/title-and-ingress'
import { useColor } from 'context/themeContext'
import { isSafari } from 'react-device-detect'
import React from 'react'

import './OFAS-header.scss'

interface OFASHeaderProps extends React.ComponentProps<typeof TitleAndIngress> {
  background?: { videoUrl?: string; imageUrl?: string }
  buttons?: React.ComponentProps<typeof Button>[]
}

export const OFASHeader = ({ heading, ingress, background, buttons }: OFASHeaderProps) => {
  const { color } = useColor()
  const videoRef = React.useRef<HTMLVideoElement>(null)
  
  React.useEffect(() => {
    setTimeout(() => {
      background?.videoUrl && videoRef.current && videoRef.current.pause()
    }, 5000)
  })

  return (
    <div className='OFAS-header'>
      {background && (
        <div
          className={
            'OFAS-header__bg bgcolor-' +
            color +
            (background.videoUrl && color === 'red' ? '-1' : '-1')
          }
        >
          {background.videoUrl && !isSafari ? (
            <video
              ref={videoRef}
              className='OFAS-header__media'
              src={background.videoUrl}
              autoPlay
              muted
              playsInline
            ></video>
          ) : (
            background.imageUrl && (
              <img
                className='OFAS-header__media'
                src={background.imageUrl}
                alt={'background'}
              />
            )
          )}
        </div>
      )}
      <div className='OFAS-header__title-content'>
        <div className='OFAS-header__title-wrapper'>
          <div className={`OFAS-header__title-shadow OFAS-header__title-shadow-${color}`} />
          {background && ((background.videoUrl && !isSafari) || background.imageUrl) && (
            <div
              className={`OFAS-header__title-shadow OFAS-header__title-shadow-${color}`}
            />
          )}
          <TitleAndIngress
            heading={{ ...heading, level: 1, size: 'display' }}
            ingress={ingress}
            color={color === 'yellow' ? 'blue' : color === 'white' ? undefined : color}
          />
        </div>
        {buttons && (
          <div className='OFAS-header__buttons'>
            {buttons.map((button, i) => (
              <Button key={i} {...button} color='dark-red' />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
