import { Button } from 'components/elements/button/button'
import { useColor } from 'context/themeContext'
import React from 'react'
import './_card-list.scss'

interface CardListProps<T> {
  title?: string
  data: T[]
  renderItem: (item: T) => React.ReactNode
  button?: React.ComponentProps<typeof Button>
  grid?: 'normal' | 'newspaper'
}

export type CardListExternalProps = Pick<CardListProps<any>, 'title' | 'button' | 'grid'>

export const CardList = <T extends unknown>({
  title,
  data,
  renderItem,
  button,
  grid = 'normal',
}: CardListProps<T>) => {
  const { color } = useColor()

  return (
    <div className='card-list'>
      {title && <div className='card-list__title'>{title}</div>}
      {data && data.length !== 0 && (
        <ul className={`card-list__cards card-list__cards-${grid}`}>
          {data.map((card, i) => (
            <li key={i}>{renderItem(card)}</li>
          ))}
        </ul>
      )}
      {button && (
        <Button
          className='card-list__button'
          {...button}
          color={
            'orange' === color || 'yellow' === color || 'dark-red' === color || 'red' === color
              ? 'dark-red'
              : color
          }
        />
      )}
    </div>
  )
}
