import { Heading } from 'components/elements/heading/heading'
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg'
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg'

import { Image } from 'types'
import './_course-instructors.scss'
import cn from 'classnames'
import { useColor } from 'context/themeContext'

interface CourseInstructorsProps {
  title: string
  instructors: {
    image: Image
    name: string
    position: string
    info: string
    email: string
    phone?: string
    readMoreLink?: {
      url: string
      text: string
    }
  }[]
  overrideColor?: string
}

export const CourseInstructors = ({ title, instructors, overrideColor }: CourseInstructorsProps) => {
  const { color } = useColor()
  const colorToUse = overrideColor === undefined ? color : overrideColor

  return (
    <div className={cn('course-instructors', {
      [`course-instructors__color-${colorToUse === 'yellow' ? 'dark-red' : colorToUse}`]: colorToUse,
    })}>
      <Heading level={1} size='l' weight={700} className='course-instructors__title'>
        {title}
      </Heading>
      {instructors && (
        <ul className='course-instructors__list'>
          {instructors.map((instructor, index) => (
            <li key={index} className='course-instructors__instructor'>
              <div className='course-instructors__instructor-text'>
                <Heading
                  className='course-instructors__instructor-name'
                  level={2}
                  size='s'
                  weight={700}
                >
                  {instructor.name}
                </Heading>
                <Heading
                  className='course-instructors__instructor-position'
                  level={3}
                  size='xs'
                  weight={700}
                >
                  {instructor.position}
                </Heading>
                <p className='course-instructors__instructor-info'>
                  {instructor.info}{' '}
                  <a href={instructor.readMoreLink?.url}>
                    <span>{instructor.readMoreLink?.text}</span>
                  </a>
                </p>
                {instructor.email && (
                  <a className='course-instructors__link' href={'mailto:' + instructor.email}>
                    <div
                      className={cn('course-instructors__link-icon', {
                        [`course-instructors__link-icon__color-${colorToUse}`]: colorToUse,
                      })}
                    >
                      <EmailIcon />
                    </div>
                    <span>{instructor.email}</span>
                  </a>
                )}
                {instructor.phone && (
                  <div className='course-instructors__link'>
                    <div
                      className={cn('course-instructors__link-icon', {
                        [`course-instructors__link-icon__color-${colorToUse}`]: colorToUse,
                      })}
                    >
                      <PhoneIcon />
                    </div>
                    <span>{instructor.phone}</span>
                  </div>
                )}
              </div>
              <img
                className='course-instructors__instructor-image'
                src={instructor.image.url}
                alt={instructor.image.alt}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
