import React from 'react'
import './_link-list.scss'
import { ReactComponent as VectorIcon } from 'assets/icons/vector.svg'
import { Button } from '../../elements/button/button'

interface LinkListProps {
  list: {
    tag: string
    title: string
    text: string
    button: React.ComponentProps<typeof Button>
  }[]
}

export const LinkList = ({ list }: LinkListProps) => (
  <div className='link-list'>
    <ul className='link-list__items'>
      {list.map((item, i) => (
        <li className='link-list__item' key={i}>
          <div className='link-list__tag'>
            <VectorIcon className='link-list__tagicon' />
            {item.tag}
          </div>
          <div className='link-list__title'>{item.title}</div>
          <div className='link-list__text'>{item.text}</div>
          <div className='link-list__button'>
            <Button {...item.button} />
          </div>
        </li>
      ))}
    </ul>
  </div>
)
