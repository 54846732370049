import React from 'react'
import { Layout } from '../../components/layout/layout'
import { Header } from 'components/layout/header'
import { Button } from 'components/elements/button/button'
import { Search } from 'components/elements/search/search'
import './error-page.scss'

interface ErrorPageProps {
  layout: React.ComponentProps<typeof Layout>
  header: React.ComponentProps<typeof Header>
  button: React.ComponentProps<typeof Button>
  search: {
    text: string
    placeholder: string
    searchPageUrl: string
    enpointUrl: string
  }
}

export const ErrorPage = ({ layout, header, button, search }: ErrorPageProps) => {
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>()
  const searchfn = () => {
    if (searchQuery) {
      window.location.href = search.searchPageUrl + '?query=' + searchQuery
    }
  }
  return (
    <Layout {...layout} theme={layout.theme ?? {color: 'dark-red'}} className='error-page'>
      {header && <Header {...header} />}
      <div className='error-page__wrapper'>
        <div className='error-page__content'>
          {button && (
            <div className='error-page__content-button'>
              <Button {...button} />
            </div>
          )}
          <div className='error-page__content-search'>
            <Search
              searchButtonText={search.text}
              search={searchfn}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={search.placeholder}
              resetSearchString={() => setSearchQuery('')}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
