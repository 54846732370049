import React from 'react'
import { RadioListBlock } from 'components/blocks/radio-list-block/radio-list-block'
import { InputField, InputFieldFuncs } from 'components/elements/forms/input-field/input-field'
import { Form } from 'components/blocks/form/form'
import { ErrorMessage } from 'components/elements/error-message/error-message'
import { Heading } from 'components/elements/heading/heading'
import { useUser } from 'context/userContext'
import { useShoppingCart } from 'context/shoppingCartContext'
import { Collapse } from 'react-collapse'
import { Checkbox } from 'components/elements/forms/checkbox/checkbox'
import './_checkout-form.scss'
import cn from 'classnames'
import { useColor } from 'context/themeContext'

interface CheckoutFormProps {
  shippingRadioListBlock: React.ComponentProps<typeof RadioListBlock>
  paymentRadioListBlock: React.ComponentProps<typeof RadioListBlock>
  handleInputChange: any
  currentSelectedPayment: any
  setFormData: any
  setCurrentSelectedPayment: (value: string) => void
  paymentForm?: React.ComponentProps<typeof Form>
  paymentInfoTitle: string
  deliveryAddressTitle: string
  invoiceAddressTitle: string
  deliveryAddressForm?: React.ComponentProps<typeof Form>
  invoiceAddressForm?: React.ComponentProps<typeof Form>
  setCurrentProducts: any
  requiredFieldsErrorMessage: string
  requiredFieldsMissing: boolean
  invoiceLabelNotLoggedIn: string
  isChecked: boolean
  setIsChecked: any
  useDifferentDeliveryAddress?: React.ComponentProps<typeof Checkbox>
}

export const CheckoutForm = ({
  shippingRadioListBlock,
  paymentRadioListBlock,
  setFormData,
  handleInputChange,
  currentSelectedPayment,
  setCurrentSelectedPayment,
  paymentForm,
  deliveryAddressForm,
  invoiceAddressForm,
  setCurrentProducts,
  paymentInfoTitle,
  deliveryAddressTitle,
  invoiceAddressTitle,
  requiredFieldsMissing,
  requiredFieldsErrorMessage,
  invoiceLabelNotLoggedIn,
  isChecked,
  setIsChecked,
  useDifferentDeliveryAddress,
}: CheckoutFormProps) => {
  const { user } = useUser()
  const { shoppingCart, totalCost } = useShoppingCart()
  const { color } = useColor()
  const colorStyle = color === 'green' ? 'green' : 'blue'

  const currentSelectedShipping = shippingRadioListBlock.radioList
    ? shippingRadioListBlock.radioList[0].value
    : ''

  shippingRadioListBlock.radioList.forEach((radio) => {
    radio.isSelected = currentSelectedShipping === radio.value
  })

  if (user === null) {
    // Invoice is not possible if customer is not logged in
    paymentRadioListBlock.radioList[1].disabled = true
    paymentRadioListBlock.radioList[1].label = invoiceLabelNotLoggedIn
  } else if (shoppingCart.length > 0 && totalCost === 0) {
    paymentRadioListBlock.radioList[0].disabled = true
    setCurrentSelectedPayment(paymentRadioListBlock.radioList[1].value ?? '')
  }

  paymentRadioListBlock.radioList.forEach((radio) => {
    radio.isSelected = currentSelectedPayment === radio.value
  })

  const onRadioChange = (e: any) => {
    setCurrentSelectedPayment(e.target.value)
    paymentForm && setFormData([...paymentForm.inputFields])
  }

  let longInputFieldArray: React.ComponentProps<typeof InputField>[] = []
  let shortInputFieldArray: React.ComponentProps<typeof InputField>[] = []
  let longInputFieldArrayDelivery: React.ComponentProps<typeof InputField>[] = []
  let shortInputFieldArrayDelivery: React.ComponentProps<typeof InputField>[] = []
  let longInputFieldArrayInvoice: React.ComponentProps<typeof InputField>[] = []
  let shortInputFieldArrayInvoice: React.ComponentProps<typeof InputField>[] = []

  paymentForm &&
    paymentForm.inputFields.forEach((inputField) => {
      inputField.short
        ? shortInputFieldArray.push(inputField)
        : longInputFieldArray.push(inputField)
    })

  invoiceAddressForm &&
    invoiceAddressForm.inputFields.forEach((inputField) => {
      inputField.short
        ? shortInputFieldArrayInvoice.push(inputField)
        : longInputFieldArrayInvoice.push(inputField)
    })

  deliveryAddressForm &&
    deliveryAddressForm.inputFields.forEach((inputField) => {
      inputField.short
        ? shortInputFieldArrayDelivery.push(inputField)
        : longInputFieldArrayDelivery.push(inputField)
    })

  const toggle = () => {
    setIsChecked(!isChecked)
    deliveryAddressForm && deliveryAddressForm.inputFields.forEach((input) => (input.value = ''))
  }

  React.useEffect(() => {
    setCurrentProducts(shoppingCart)
  })

  const onlyFreeProductsAndNoUserLoggedIn = () =>
    shoppingCart.length > 0 && totalCost === 0 && !user

  return onlyFreeProductsAndNoUserLoggedIn() ? (
    <div></div>
  ) : (
    <div className='checkout-form'>
      <div className='checkout-form__radio-list-blocks'>
        {shippingRadioListBlock && (
          <RadioListBlock
            currentSelected={currentSelectedShipping}
            radioList={shippingRadioListBlock.radioList}
            title={shippingRadioListBlock.title}
          />
        )}
        {paymentRadioListBlock && (
          <RadioListBlock
            currentSelected={currentSelectedPayment}
            onChange={onRadioChange}
            radioList={paymentRadioListBlock.radioList}
            title={paymentRadioListBlock.title}
          />
        )}
      </div>
      <form
        className={cn('checkout-form__form', `checkout-form__form__${colorStyle}`)}
        id='checkout-form'
      >
        <fieldset>
          <legend>
            <Heading
              className={cn('checkout-form__title', `checkout-form__title__${colorStyle}`)}
              level={2}
            >
              {paymentInfoTitle}
            </Heading>
          </legend>
          <div className='form__input'>
            {longInputFieldArray.map((inputField) => (
              <div key={inputField.id} className='form__input-long'>
                <InputField
                  {...inputField}
                  value={inputField.value}
                  onChange={(e) => {
                    handleInputChange(inputField, e, paymentForm)
                  }}
                />
              </div>
            ))}
            {shortInputFieldArray.map((inputField, i) => {
              if (i % 2 !== 0) {
                return null
              } else
                return (
                  <div key={inputField.id} className='form__input-short'>
                    <InputField
                      {...inputField}
                      value={inputField.value}
                      onChange={(e) => {
                        handleInputChange(inputField, e, paymentForm)

                        paymentForm && setFormData([...paymentForm.inputFields])
                      }}
                    />
                    {shortInputFieldArray[i + 1] && (
                      <InputField
                        key={inputField.id}
                        {...shortInputFieldArray[i + 1]}
                        value={shortInputFieldArray[i + 1].value}
                        onChange={(e) => {
                          handleInputChange(shortInputFieldArray[i + 1], e, paymentForm)

                          paymentForm && setFormData([...paymentForm.inputFields])
                        }}
                      />
                    )}
                  </div>
                )
            })}
          </div>
        </fieldset>
        {invoiceAddressForm && currentSelectedPayment === paymentRadioListBlock.radioList[1].value && (
          <fieldset>
            <legend>
              <Heading
                className={cn('checkout-form__title', `checkout-form__title__${colorStyle}`)}
                level={2}
              >
                {invoiceAddressTitle}
              </Heading>
            </legend>
            <div className='form__input'>
              {longInputFieldArrayInvoice.map((inputField) => (
                <div key={inputField.id} className='form__input-long'>
                  <InputField
                    {...inputField}
                    value={inputField.value}
                    onChange={(e) => {
                      handleInputChange(inputField, e, invoiceAddressForm)
                    }}
                  />
                </div>
              ))}
              {shortInputFieldArrayInvoice.map((inputField, i) => {
                if (i % 2 !== 0) {
                  return null
                } else
                  return (
                    <div key={inputField.id} className='form__input-short'>
                      <InputField
                        {...inputField}
                        value={inputField.value}
                        onChange={(e) => {
                          handleInputChange(inputField, e, invoiceAddressForm)
                        }}
                      />
                      {shortInputFieldArrayInvoice[i + 1] && (
                        <InputField
                          key={inputField.id}
                          {...shortInputFieldArrayInvoice[i + 1]}
                          value={shortInputFieldArrayInvoice[i + 1].value}
                          onChange={(e) => {
                            handleInputChange(
                              shortInputFieldArrayInvoice[i + 1],
                              e,
                              invoiceAddressForm,
                            )
                          }}
                        />
                      )}
                    </div>
                  )
              })}
            </div>
          </fieldset>
        )}
        {deliveryAddressForm &&
          useDifferentDeliveryAddress &&
          currentSelectedPayment === paymentRadioListBlock.radioList[1].value && (
            <fieldset>
              <div>
                <Checkbox
                  handleClick={toggle}
                  {...useDifferentDeliveryAddress}
                  checked={isChecked}
                />

                <Collapse isOpened={isChecked} theme={{ collapse: 'form__secondary-inputs' }}>
                  <div className='form__secondary-inputs-content'>
                    <legend>
                      <Heading
                        className={cn(
                          'checkout-form__title',
                          `checkout-form__title__${colorStyle}`,
                        )}
                        level={2}
                      >
                        {deliveryAddressTitle}
                      </Heading>
                    </legend>
                    <div className='form__input'>
                      {longInputFieldArrayDelivery.map((inputField) => (
                        <div key={inputField.id} className='form__input-long'>
                          <InputField
                            {...inputField}
                            value={inputField.value}
                            onChange={(e) => {
                              handleInputChange(inputField, e, deliveryAddressForm)
                            }}
                          />
                        </div>
                      ))}
                      {shortInputFieldArrayDelivery.map((inputField, i) => {
                        if (i % 2 !== 0) {
                          return null
                        } else
                          return (
                            <div key={inputField.id} className='form__input-short'>
                              <InputField
                                {...inputField}
                                value={inputField.value}
                                onChange={(e) => {
                                  handleInputChange(inputField, e, deliveryAddressForm)
                                }}
                              />
                              {shortInputFieldArrayDelivery[i + 1] && (
                                <InputField
                                  key={inputField.id}
                                  {...shortInputFieldArrayDelivery[i + 1]}
                                  value={shortInputFieldArrayDelivery[i + 1].value}
                                  onChange={(e) => {
                                    handleInputChange(
                                      shortInputFieldArrayDelivery[i + 1],
                                      e,
                                      deliveryAddressForm,
                                    )
                                  }}
                                />
                              )}
                            </div>
                          )
                      })}
                    </div>
                  </div>
                </Collapse>
              </div>
            </fieldset>
          )}
        {requiredFieldsMissing && requiredFieldsErrorMessage && (
          <div className='checkout-form__error'>
            <ErrorMessage text={requiredFieldsErrorMessage} />
          </div>
        )}
      </form>
    </div>
  )
}
