import React from 'react'
import { TitleAndIngress } from '../../../elements/title-and-ingress/title-and-ingress'
import cn from 'classnames'
import './_article-header.scss'
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg'

interface ArticleHeaderProps extends React.ComponentProps<typeof TitleAndIngress> {
  image: { url: string; alt: string; photographer: string }
  photographerLabel?: string
}

export const ArticleHeader = ({ image, heading, color, photographerLabel }: ArticleHeaderProps) => {
  return (
    <div
      className={cn({
        [`article-header__bg-${color}`]: color,
      })}
    >
      <div className={cn('article-header')}>
        <div>
          <img className='article-header__image' src={image.url} alt={image.alt} />
          <div className='article-header__image-text'>
            {image.photographer && <ArrowUpIcon className='article-header__image-icon' />}
            {image.photographer && `${photographerLabel ?? 'Foto: '}${image.photographer}`}
          </div>
        </div>

        <div>
          <TitleAndIngress
            className='article-header__content'
            {...{ heading: { ...heading, level: 1, weight: 700, size: 'display' }, color }}
          />
        </div>
      </div>
    </div>
  )
}
