import React from 'react'

import './_course-page.scss'
import { pageProps } from 'types'
import { Layout } from '../../components/layout/layout'
import { ComponentList } from '../../components/blocks/component-list/component-list'
import { Header } from 'components/layout/header'

interface CoursePageProps extends pageProps {}

export const CoursePage = ({ layout, header, blocks }: CoursePageProps) => (
  <Layout {...layout} theme={layout.theme ?? {color: 'yellow', type: 'solid'}} className='course-page'>
    {header && <Header {...{ ...header, data: { ...header.data, background: 'yellow' } }} />}
    {blocks && <ComponentList blocks={blocks} />}
  </Layout>
)
