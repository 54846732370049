import { ReactComponent as LockIcon } from 'assets/icons/lock.svg'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { ErrorMessage } from 'components/elements/error-message/error-message'
import './_input-field.scss'
import cn from 'classnames'
import { useColor } from 'context/themeContext'

export interface InputFieldFuncs {
  getCountryId(): string | undefined
}

interface InputFieldProps {
  title?: string
  short?: boolean
  locked?: boolean
  value?: string
  id?: string
  required?: boolean
  type?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  error?: boolean
  errorText?: string
  validationType?: 'email' | 'phone' | 'postalCode' | 'newPassword' | 'repeatedNewPassword'
  placeholder?: string
  countryCodePrefix?: {
    countryCodePrefixList: {
      countryId: string
      dialInPrefix: string
    }[]
    defaultCountryId?: string
    countryIdParamName: string
  }
}

export const InputField = forwardRef<InputFieldFuncs, InputFieldProps>(
  (
    {
      title,
      short,
      locked,
      value,
      id,
      required,
      onChange,
      type,
      error,
      errorText,
      placeholder,
      countryCodePrefix,
    }: InputFieldProps,
    ref,
  ) => {
    const { color } = useColor()
    const colorStyle = color === 'green' ? 'green' : 'blue'
    const [countryId, setCountryId] = useState<string | undefined>(
      countryCodePrefix?.defaultCountryId,
    )

    useImperativeHandle(ref, () => ({
      getCountryId() {
        return countryId
      },
    }))

    return (
      <div className={cn('input-field')}>
        <label className={cn('input-field__label', `input-field__label__${colorStyle}`)} htmlFor={id}>
          {' '}
          {required ? title + '*' : title}
        </label>
        <div className={countryCodePrefix ? 'input-field__phone' : ''}>
          {countryCodePrefix && (
            <div
              className={cn('input-field__field', {
                'input-field__field__phone-prefix': countryCodePrefix,
                'input-field__error': error,
              })}
            >
              <select
                value={countryId}
                onChange={(e) => setCountryId(e.target.value)}
                className={cn('input-field__prefix-input', `input-field__prefix-input__${colorStyle}`)}
                aria-describedby='error'
                aria-invalid={error}
              >
                {countryCodePrefix.countryCodePrefixList &&
                  countryCodePrefix.countryCodePrefixList.map((x, index) => (
                    <option key={index} value={x.countryId}>
                      +{x.dialInPrefix}
                    </option>
                  ))}
              </select>
            </div>
          )}
          <div
            className={cn(
              'input-field__field', `input-field__field__${colorStyle}`,
              {
                [`input-field__short`]: short,
                [`input-field__locked input-field__locked__${colorStyle}`]: locked,
                [`input-field__error`]: error,
              },
              { 'input-field__field__phone': countryCodePrefix },
            )}
          >
            <input
              value={value}
              id={id}
              disabled={locked}
              onChange={onChange}
              className={cn('input-field__input', `input-field__input__${color}`)}
              type={type}
              placeholder={placeholder}
              aria-describedby='error'
              aria-invalid={error}
            />
            {locked && <LockIcon className={cn('input-field__lock', `input-field__lock__${colorStyle}`)} />}
          </div>
        </div>
        {errorText && error && (
          <div className='input-field__error-text'>
            <ErrorMessage textId='error' text={errorText} />
          </div>
        )}
      </div>
    )
  },
)
