import { Layout } from '../../components/layout/layout'
import { Header } from 'components/layout/header'
import { ListSearchBlock } from 'components/blocks/list-search-block/list-search-block'
import { EntranceLinks } from 'components/blocks/entrance-links/entrance-links'
import { pagedFilterType, pageProps } from 'types'
import { ProductCard, ProductCardProps } from 'components/elements/product-card/product-card'
import './_product-list-page.scss'
import { ProductPageList } from 'components/blocks/product-page-list/product-page-list'

interface ProductPageProps extends pageProps {
  entranceLinks: React.ComponentProps<typeof EntranceLinks>
  listSearchProps: {
    queryUrl: string
    defaultFilter: pagedFilterType
    noResultsText: string
    errorMessage: string
  }
}

export const ProductListPage = ({
  layout,
  header,
  entranceLinks,
  listSearchProps,
}: ProductPageProps) => {return(
  <Layout
    {...layout}
    theme={layout.theme ?? { color: 'green', type: 'solid' }}
    className='product-page'
  >
    {header && <Header {...{ ...header, data: { ...header.data, background: 'green' } }} />}
    <div className='product-list-page'>{entranceLinks && <EntranceLinks {...entranceLinks} />}
    <ListSearchBlock
      queryUrl={listSearchProps.queryUrl}
      defaultFilter={{
        page: listSearchProps.defaultFilter.page,
        pageSize: listSearchProps.defaultFilter.pageSize,
      }}
      noResultsText={listSearchProps.noResultsText}
      errorMessage={listSearchProps.errorMessage}
      displayData={(results: ProductCardProps[]) => (
        <div className='product-list-page__results'>
          <ProductPageList products={results} />
        </div>
      )}
    />
    </div>
  </Layout>
)}
