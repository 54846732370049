import React, { useEffect, useRef, useState } from 'react'
import './_dropdown.scss'
import cn from 'classnames'
import { ReactComponent as ArrowUpDownIcon } from 'assets/icons/arrow-up-down.svg'

interface DropDownProps<T> {
  options: T[]
  setValue: (value: T) => void
  value: T
}

export const DropDown = ({ options, setValue, value }: DropDownProps<string>) => {
  const [isActive, setIsActive] = useState(false)
  const btnRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeDropDown = (e: MouseEvent) => {
      if (e.composedPath()[0] !== btnRef.current) {
        setIsActive(false)
      }
    }

    document.addEventListener('click', closeDropDown)
    return () => document.removeEventListener('click', closeDropDown)
  }, [])

  return (
    <div className='dropdown'>
      <div
        ref={btnRef}
        onClick={(e) => {
          setIsActive(!isActive)
        }}
        className='dropdown__btn'
      >
        {value}

        <ArrowUpDownIcon className='dropdown__icon' />
      </div>
      <div className='dropdown__content' style={{ display: isActive ? 'block' : 'none' }}>
        {options.map((option, i) => (
          <div
            key={i}
            onClick={() => {
              setValue(option)
              setIsActive(!isActive)
            }}
            className='dropdown__item'
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  )
}
