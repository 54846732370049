import React from 'react'
import './_meta-info.scss'
import { Colors } from 'types'
import cn from 'classnames'
import { Tag } from '../tag/tag'
import { useColor } from 'context/themeContext'

interface MetaInfoProps {
  published: string
  updated: string
  author: string
  publishedLabel: string
  updatedLabel: string
  authorLabel: string
  tag?: React.ComponentProps<typeof Tag>
}

export const MetaInfo = ({ published, updated, author, publishedLabel, updatedLabel, authorLabel, tag }: MetaInfoProps) => {
  const { color } = useColor()
  
  return (
  <div
    className={cn('meta-info ', {
      [`meta-info-${color}`]: color,
    })}
  >
    <div className={cn('meta-info__content ', {
      [`meta-info__content-${color}`]: color,
    })}>
      <div>{authorLabel}{author}</div>
      <div>{publishedLabel}{published}</div>
      <div>{updatedLabel}{updated}</div>
    </div>
    {tag && <Tag {...tag} />}
  </div>
)}
