import React from 'react'
import cn from 'classnames'
import { TitleAndIngress } from '../../../elements/title-and-ingress/title-and-ingress'
import './_simple-header.scss'
import { useColor } from 'context/themeContext'

interface SimpleHeaderProps extends React.ComponentProps<typeof TitleAndIngress> {}

export const SimpleHeader = ({ heading, ingress }: SimpleHeaderProps) => {
  const { color, type } = useColor()

  return (
    <div
      className={cn({
        [`bgcolor-${color}${color === 'orange' || color === 'green' ? '-1' : '-2'}`]:
          type === 'solid',
      })}
    >
      <div className={'simple-header'}>
        <div className='simple-header__title-content'>
          <div className='simple-header__title-wrapper'>
            <TitleAndIngress
              heading={{ ...heading, level: 1, size: 'display' }}
              ingress={ingress}
              color={color === 'yellow' ? 'blue' : color === 'white' ? undefined : color}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
