import React from 'react'
import { Heading } from 'components/elements/heading/heading'
import { ReactComponent as FileLinkIcon } from 'assets/icons/file-link.svg'

import { Image, Link } from 'types'
import './_margin.scss'
import cn from 'classnames'
import { useColor } from 'context/themeContext'

interface MarginProps {
  type?: 'default' | 'contact' | 'downloads'
  title: string
  text?: string
  downloads?: Link[]
  contact?: { image: Image; name: string; email: string; phone: string }
}

export const Margin = ({ type, title, text, downloads, contact }: MarginProps) => {
  const { color } = useColor()
  return (
    <div className={cn('margin', {
      [`margin__color-${color}`]: color,
    },  {'margin__contact-position': contact && type === 'contact'})}>
      <h2 className='margin__title'>
        {title}
      </h2>
      {text && <div className='margin__text' dangerouslySetInnerHTML={{ __html: text }}></div>}
      {downloads && type === 'downloads' && (
        <ul className='margin__downloads'>
          {downloads.map((link) => (
            <li>
              <a className='margin__link' href={link.url} download>
                <span>{link.text}</span> <FileLinkIcon className='margin__link-icon' />
              </a>
            </li>
          ))}
        </ul>
      )}
      {contact && type === 'contact' && (
        <div className='margin__contact'>
          {contact.image && contact.image.url ? (
            <img
              className='margin__contact-image'
              src={contact.image.url}
              alt={contact.image.alt}
            />
          ) : (
            <div className='margin__contact-no-image'></div>
          )}
          <Heading className='margin__contact-name' level={3} size='xs'>
            {contact.name}
          </Heading>
          <a className='margin__link margin__contact-phone' href={'tel:' + contact.phone}>
            <span>{contact.phone}</span>
          </a>
          <a className='margin__link margin__contact-email' href={'mailto:' + contact.email}>
            <span>{contact.email}</span>
          </a>
        </div>
      )}
    </div>
  )
}

// Johan skal skjekke når monica kommer tilbake om vi skal bruke mailto: eller copy to clipboard
