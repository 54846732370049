import { ComponentList } from 'components/blocks/component-list/component-list'
import { ShortcutsBlock } from 'components/blocks/shortcuts-block/shortcuts-block'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import React from 'react'
import { pageProps } from 'types'
import './front-page.scss'
interface FrontpageProps extends pageProps {
  shortcuts: React.ComponentProps<typeof ShortcutsBlock>
}

export const FrontPage = ({ layout, header, shortcuts, blocks }: FrontpageProps) => (
  <Layout
    {...layout}
    theme={layout.theme ?? { color: 'orange', type: 'solid' }}
    className='front-page'
    isFrontPage={true}
  >
    {header && <Header {...header} />}
    {shortcuts && <ShortcutsBlock {...shortcuts} />}
    {blocks && <ComponentList blocks={blocks} />}
  </Layout>
)
