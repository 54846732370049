import React from 'react'
import { Button } from 'components/elements/button/button'
import { Heading } from 'components/elements/heading/heading'
import { RichText } from 'components/blocks/rich-text/rich-text'
import './_subscription-block.scss'

export interface SubscriptionBlockProps {
  title: string
  text: string
  button?: React.ComponentProps<typeof Button>
}

export const SubscriptionBlock = ({ title, text, button }: SubscriptionBlockProps) => {
  return (
    <div className='subscription-block'>
      <Heading size='s' level={3}>
        {title}
      </Heading>
      <div className='subscription-block__text'>
        <RichText html={text} />
      </div>
      {button && (
        <div>
          <Button {...button} />
        </div>
      )}
    </div>
  )
}
