import { LoginForm } from 'components/elements/forms/login-form/login-form'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import './_login-page.scss'

interface LoginPageProps {
  layout: React.ComponentProps<typeof Layout>
  header: React.ComponentProps<typeof Header>
  loginForm: React.ComponentProps<typeof LoginForm>
}

export const LoginPage = ({ layout, header, loginForm }: LoginPageProps) => (
  <Layout 
    {...layout}
    theme={layout.theme ?? { color: 'blue' }}
    className='login-page'>
    {header && <Header {...header} />}
    <LoginForm {...loginForm} />
  </Layout>
)
