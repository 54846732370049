import React, { useEffect } from 'react'

import { useShoppingCart } from 'context/shoppingCartContext'

interface CheckoutTotalProps {
  totalPriceText: string
  priceWithoutShippingLabel: string
  shippingCostLabel: string
  className?: string
}

export const CheckoutTotal = ({ totalPriceText, priceWithoutShippingLabel, shippingCostLabel, className }: CheckoutTotalProps) => {
  const { totalCost, shippingCost } = useShoppingCart()

  return (
    <div className={className}>
      {totalPriceText && (
        <div>
          {totalCost !== 0 && (
            <div>
              <div className='row'>
                <p>{priceWithoutShippingLabel} </p>
                <p>{(totalCost - shippingCost)?.toFixed(2)}</p>
              </div>
              <div className='row'>
                <p>{shippingCostLabel} </p>
                <p>{shippingCost === -1 ? "0" : shippingCost?.toFixed(2)}</p>
              </div>
              <div className='divider' />
            </div>
          )}
          <div className='row'>
            <p className='total-label'>{totalPriceText}</p>
            <p className='total-price'>{totalCost?.toFixed(2)}</p>
          </div>
        </div>
      )}
    </div>
  )
}
