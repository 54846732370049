import React from 'react'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import { ComponentList } from 'components/blocks/component-list/component-list'
import { Block } from 'types'
import { MetaInfo } from 'components/elements/meta-info/meta-info'
import { InfoBlock } from 'components/blocks/info-block/info-block'
import './fsm-article-page.scss'

interface FireSafetyMagazineArticlePageProps {
  layout: React.ComponentProps<typeof Layout>
  header: React.ComponentProps<typeof Header>
  metaInfo: React.ComponentProps<typeof MetaInfo>
  ingress: string
  blocks: Block[]
  hasSubscription: Boolean
  subscriptionWallContent?: React.ComponentProps<typeof InfoBlock>
}

export const FireSafetyMagazineArticlePage = ({
  layout,
  header,
  metaInfo,
  ingress,
  blocks,
  hasSubscription,
  subscriptionWallContent,
}: FireSafetyMagazineArticlePageProps) => (
  <Layout {...layout} theme={layout.theme ?? {color: 'dark-red', type: 'gradient'}} className='fsm-article-page'>
    {header && <Header {...header} />}

    {!hasSubscription && (
      <div className='fsm-article-page__subscription-wall'>
        <div className='fsm-article-page__subscription-wall-gradient'>
          {metaInfo && (
            <div className='article-page__header'>
              <MetaInfo {...metaInfo} />
              <p className='article-page__ingress'>{ingress}</p>
            </div>
          )}
          {subscriptionWallContent && (
            <div className='fsm-article-page__subscription-wall-content'>
              <InfoBlock {...subscriptionWallContent} />
            </div>
          )}
        </div>
      </div>
    )}
    {blocks && hasSubscription && <ComponentList blocks={blocks} />}
  </Layout>
)
