import React from 'react'
import cn from 'classnames'
import './_info-block.scss'
import { Button } from 'components/elements/button/button'
import { Heading } from 'components/elements/heading/heading'
import { Colors } from 'types'
import { useColor } from 'context/themeContext'

interface InfoBlockProps {
  title: string
  text: string
  image?: { url: string; alt: string }
  button?: React.ComponentProps<typeof Button>
  background?: boolean
  className?: string
  overrideColor?: Colors
}

export const InfoBlock = ({
  title,
  text,
  image,
  button,
  background = false,
  className,
  overrideColor
}: InfoBlockProps) => {
  const { color } = useColor()
  const colorToUse: Colors = overrideColor ?? color
  return (
    <div>
      <div
        className={cn('info-block', {
          [`info-block__color-${colorToUse}`]: color,
          [`info-block__background-${colorToUse}`]: background,
          [`info-block__big`]: image,
        })}
      >
        <div className='info-block__content'>
          <Heading
            size='m'
            level={3}
            className={cn('info-block__title', {
              [`info-block__title-${colorToUse}`]: color,
            })}
          >
            {title}
          </Heading>
          <div
            className={cn('info-block__text', {
              [`info-block__text-color-${colorToUse}`]: color,
            })}
            dangerouslySetInnerHTML={{__html: text}}
          >
          </div>
          {button && (
            <div>
              <Button
                className={cn('info-block__button', className, {
                  [`info-block__button-${colorToUse}`]: color,
                })}
                {...button}
                color={
                  'orange' === colorToUse || 'yellow' === colorToUse || 'dark-red' === colorToUse || 'red' === colorToUse ? 'dark-red' : colorToUse
                }
              />
            </div>
          )}
        </div>
        {image?.url && image.alt && (
          <img className='info-block__image' src={image.url} alt={image.alt} />
        )}
      </div>
    </div>
  )
}
