import { ReactComponent as EmptyRadioIcon } from 'assets/icons/radio.svg'
import { ReactComponent as CheckedRadioIcon } from 'assets/icons/radio-checked.svg'
import { ReactComponent as DisabledRadioIcon } from 'assets/icons/radio-disabled.svg'
import cn from 'classnames'
import './_radio.scss'
import { useShoppingCart } from 'context/shoppingCartContext'
import { useColor } from 'context/themeContext'

interface RadioProps {
  label: string
  name?: string
  id?: string
  value?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  isSelected?: boolean
  readOnly?: boolean
  disabled?: boolean
  extraShippingLabel?: string
}

export const Radio = ({
  label,
  name,
  id,
  value,
  onChange,
  isSelected,
  readOnly,
  disabled,
  extraShippingLabel,
}: RadioProps) => {
  const { shippingCost } = useShoppingCart()
  const { color } = useColor();
  const radioClassName = color === "green" ? "radio__box__green" : "radio__box__blue";

  const renderRadioShippingText = () => {
      return shippingCost === -1 ? ` For tung handlekurv (35kg maks)` : ` (${shippingCost} ${extraShippingLabel})`
  }

  return (
    <div className='radio'>
      <label
        className={cn('radio__container', {
          'radio__container--disabled': disabled,
        })}
        htmlFor={id}
      >
        <input
          type='radio'
          className='radio__input'
          value={value}
          name={name}
          id={id}
          onChange={onChange}
          checked={isSelected}
          readOnly={readOnly}
          disabled={disabled}
        />
        {isSelected ? (
          <CheckedRadioIcon className={cn('radio__box', radioClassName)} />
        ) : disabled ? (
          <DisabledRadioIcon className={cn('radio__box', radioClassName)} />
        ) : (
          <EmptyRadioIcon className={cn('radio__box', radioClassName)} />
        )}
        {label}
        {extraShippingLabel && renderRadioShippingText()}
      </label>
    </div>
  )
}
