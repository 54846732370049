import React from 'react'
import './_rich-text.scss'

interface RichTextProps {
  html: string
}

export const RichText = ({ html }: RichTextProps) => (
  <div className='rich-text' dangerouslySetInnerHTML={{ __html: html }}></div>
)
