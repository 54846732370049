import axios from "axios"

const editUser = (
    data: {},
    endpoint: string,
    setError: React.Dispatch<React.SetStateAction<boolean>>,
    setSaved: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    axios
        .post(endpoint, data)
        .then((response) => {
            if (response.data.success) {
                setSaved(true)
            } else {
                setError(true)
            }
        })
        .catch((e) => {
            console.error(e)
            setError(true)
            console.log(endpoint)
        })
}

const editPassword = (
    data: {}[],
    endpoint: string,
    setError: React.Dispatch<React.SetStateAction<string>>,
    errorMessage: string,
  ) => {
    axios
        .post(endpoint, { data })
        .then((response) => {
            if (response.data.success === true) {
                window.location.replace(response.data.url)
            } else {
                setError(response.data.errorMessage)
            }
        })
        .catch((e) => {
            console.error(e)
            setError(errorMessage)
        })
}

const signIn = (
    currentLogin: { phone: string; password: string },
    setError: React.Dispatch<React.SetStateAction<boolean>>,
    returnUrl: string,
    endpoint: string,
  ) => {
    axios
      .post(endpoint, currentLogin)
      .then((response) => {
        if (response.data.success === true) {
          window.location.replace(returnUrl)
        } else {
            setError(true)
        }
    })
    .catch((e) => {
        console.log("here");
        console.error(e)
        setError(true)
      })
}

const forgotPassword = (
        phoneNumber: string,
        endpoint: string,
        setError: React.Dispatch<React.SetStateAction<boolean>>,
        setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
        axios
            .post(endpoint, phoneNumber)
            .then((response) => {
                console.log(response);
                if (response.data.success === true) {
                    console.log('passord tilbakestillingslenke sendt!')
                    setSuccess(true)
                } else {
                    console.log('Noe gikk galt under sending av sms')
                    setError(true)
                }
            })
            .catch((e) => {
                console.error(e)
                setError(true)
            })
}

const signOut = () => {
    //   axios(`${userUrl}/signOut`)
    //     .then(() => {
    //       setStatus('loggedOut')
    //       setUser(undefined)
    //     })
    //     .catch((e) => {
    //       console.error(e)
    //       setStatus('loggedOut')
    //     })
    // }, [userUrl])
}

const createUser =  (
      data: {},
      endpoint: string,
      setError: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
      axios
        .post(endpoint, data)
        .then((response) => {
          if (response.data.success === true) {
            window.location.replace(response.data.url)
          } else {
            setError(true)
          }
        })
        .catch((e) => {
          console.error(e)
          setError(true)
          console.log(endpoint)
        })
}


const UserService = {    
    editUser,
    editPassword,
    signIn,
    forgotPassword,
    createUser,
    signOut
}


export default UserService;