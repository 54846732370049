import { ComponentList } from 'components/blocks/component-list/component-list'
import { ShortcutsBlock } from 'components/blocks/shortcuts-block/shortcuts-block'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import React from 'react'
import { pageProps } from 'types'
import './varme-arbeider-page.scss'
interface VarmeArbeiderpageProps extends pageProps {
  shortcuts: React.ComponentProps<typeof ShortcutsBlock>
}

export const VarmeArbeiderPage = ({
  layout,
  header,
  shortcuts,
  blocks,
}: VarmeArbeiderpageProps) => (
  <Layout
    {...layout}
    theme={layout.theme ?? { color: 'blue', type: 'solid' }}
    className='varme-arbeider-page'
  >
    {header && <Header {...header} />}
    {shortcuts && <ShortcutsBlock {...shortcuts} />}
    {blocks && <ComponentList blocks={blocks} />}
  </Layout>
)
