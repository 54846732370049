import React from 'react'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import { Button } from 'components/elements/button/button'
import { pageProps } from 'types'

import './_checkoutConfirmationPage.scss'

interface CheckoutConfirmationPageProps extends pageProps {
  text: string
  orderReference?: string
  orderReferenceLabel?: string
  button: React.ComponentProps<typeof Button>
}

export const CheckoutConfirmationPage = ({
  layout,
  header,
  orderReference,
  orderReferenceLabel,
  text,
  button,
}: CheckoutConfirmationPageProps) => {
  return (
    <Layout {...layout} theme={layout.theme ?? {color: 'white', type: 'solid'}}>
      {header && <Header {...header}/>}
      <div className='checkout-confirmation-page'>
        {orderReference && orderReferenceLabel && <p className='checkout-confirmation-page__transaction-id'>{orderReferenceLabel}{orderReference}</p>}
        <p className='checkout-confirmation-page__text'>{text}</p>
        <Button {...button}></Button>
      </div>
    </Layout>
  )
}
