import React from 'react'
import cn from 'classnames'
import { ReactComponent as CrossCircle } from 'assets/icons/cross-circle.svg'
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg'
import './_certificate-card.scss'

interface CertificateCardProps {
  numberLabel: string
  number: string
  statusLabel: string
  statusText: string
  status: boolean
  validTo?: string
}

export const CertificateCard = ({
  numberLabel,
  number,
  statusLabel,
  statusText,
  status,
  validTo,
}: CertificateCardProps) => {

  const reformatDate = (date: string) => {
    var values = date.split("-")
    return `${values[2]}-${values[1]}-${values[0]}`
  }

  return (
    <div className={cn('certificate-info', `certificate-info__color-${status ? 'green' : 'red'}`)}>
      <div className='certificate-info__field'>
        <p className='certificate-info__field__name'>{numberLabel}: </p>
        {number}
      </div>
      <div className='certificate-info__field'>
        <p className='certificate-info__field__name'>{statusLabel}: </p>
        <div className='certificate-info__field__status'>
          {status ? (
            <span>
              <CheckCircle className='valid-icon' />
            </span>
          ) : (
            <span>
              <CrossCircle className='unvalid-icon' />
            </span>
          )}
          <p>{statusText}</p>
          {validTo && <p>{reformatDate(validTo)}</p>}
        </div>
      </div>
    </div>
  )
}
