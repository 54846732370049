import { createContext, useContext } from 'react'
import { userType } from 'types'

type UserContextType = {
  user: userType | null
}

const initialUserContext: UserContextType = {
  user: null,
}

const UserContext = createContext<UserContextType>(initialUserContext)

interface UserContextProviderProps {
  children: React.ReactNode
  userProp: userType | null
}

export const UserContextProvider = ({ children, userProp }: UserContextProviderProps) => {
  const user: userType | null = userProp

  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const userContext = useContext(UserContext)
  if (useContext === undefined) {
    throw new Error('useUserContext was used outside of its Provider')
  }
  return userContext
}
