import { Button } from 'components/elements/button/button'
import { TitleAndIngress } from 'components/elements/title-and-ingress/title-and-ingress'
import { useColor } from 'context/themeContext'
import { isSafari } from 'react-device-detect';
import React from 'react'

import './_graphical-header.scss'

interface GraphicalHeaderProps extends React.ComponentProps<typeof TitleAndIngress> {
  background?: { videoUrl?: string; imageUrl?: string }
  buttons?: React.ComponentProps<typeof Button>[]
}

export const GraphicalHeader = ({
  heading,
  ingress,
  background,
  buttons,
}: GraphicalHeaderProps) => {
  const { color } = useColor()
  const videoRef = React.useRef<HTMLVideoElement>(null)

  React.useEffect(() => {
    setTimeout(() => {
      background?.videoUrl && videoRef.current && videoRef.current.pause()
    }, 5000)
  })

  return (
    <div className='graphical-header'>
      {background && (
        <div
          className={
            'graphical-header__bg bgcolor-' +
            color +
            (background.videoUrl && color === 'orange' ? '-2' : '-1')
          }
        >
          {background.videoUrl && !isSafari ? (
            <video
              ref={videoRef}
              className='graphical-header__media'
              src={background.videoUrl}
              autoPlay
              muted
              playsInline
            ></video>
          ) : (
            background.imageUrl && (
              <img
                className='graphical-header__media'
                src={background.imageUrl}
                alt={'background'}
              />
            )
          )}
        </div>
      )}
      <div className='graphical-header__title-content'>
        <div className='graphical-header__title-wrapper'>
          {background && ((background.videoUrl && !isSafari ) || background.imageUrl) && (
            <div
              className={`graphical-header__title-shadow graphical-header__title-shadow-${color}`}
            />
          )}
          <TitleAndIngress
            heading={{ ...heading, level: 1, size: 'display' }}
            ingress={ingress}
            color={color === 'yellow' ? 'blue' : color === 'white' ? undefined : color}
          />
        </div>
        {buttons && (
          <div className='graphical-header__buttons'>
            {buttons.map((button, i) => (
              <Button
                key={i}
                {...button}
                color={
                  color === 'dark-red' || color === 'yellow' || color === 'red' ? 'dark-red' : color
                }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
