import React from 'react'

import './_employee-list-page.scss'
import { pageProps } from 'types'
import { Layout } from '../../components/layout/layout'
import { Header } from 'components/layout/header'
import { EmployeeList, EmployeeListProps } from 'components/blocks/employee-list/employee-list'

interface EmployeeListPageProps extends pageProps {
    employeeListProps: EmployeeListProps
}

export const EmployeeListPage = ({ layout, header, employeeListProps }: EmployeeListPageProps) => (
  <Layout {...layout} theme={layout.theme ?? {color: 'yellow'}} className='employee-list-page'>
    {header && <Header {...{ ...header, data: { ...header.data, background: 'yellow' } }} />}
    <div className='employee-list-page__employee-list'>
        {employeeListProps && <EmployeeList {...employeeListProps} />}
    </div>
  </Layout>
)
