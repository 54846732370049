import React from 'react'
import { ComponentList } from 'components/blocks/component-list/component-list'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import { pageProps } from 'types'
import './fsm-landing-page.scss'
interface FireSafetyMagazineLandingpageProps extends pageProps {}

export const FireSafetyMagazineLandingPage = ({
  layout,
  header,
  blocks,
}: FireSafetyMagazineLandingpageProps) => (
  <Layout {...layout} theme={layout.theme ?? {color: 'red', type: 'solid'}} className='fsm-landing-page'>
    {header && <Header {...header} />}
    {blocks && <ComponentList blocks={blocks} />}
  </Layout>
)
