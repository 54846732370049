import React from 'react'
import { Layout } from '../../components/layout/layout'
import { ComponentList } from '../../components/blocks/component-list/component-list'
import { InfoLink } from 'components/elements/info-link/info-link'

import './category-page.scss'
import { Header } from 'components/layout/header'
import { Block } from 'types'

interface CategoryPageProps {
  layout: React.ComponentProps<typeof Layout>
  header: React.ComponentProps<typeof Header>
  links: React.ComponentProps<typeof InfoLink>[]
  blocks: Block[]
}

export const CategoryPage = ({ layout, header, links = [], blocks }: CategoryPageProps) => (
  <Layout {...layout} theme={layout.theme ?? {color: 'green', type: 'gradient'}} className='category-page'>
    {header && <Header {...header} />}
    {links && (
      <ul className='category-page__links'>
        {links.map((link, i) => (
          <li key={i} className='category-page__link'>
            <InfoLink {...link}/>
          </li>
        ))}
      </ul>
    )}
    {blocks && <ComponentList blocks={blocks} />}
  </Layout>
)
