import { Button } from 'components/elements/button/button'
import { useState } from 'react'
import { InputField } from '../forms/input-field/input-field'
import { ErrorMessage } from '../error-message/error-message'
import { passwordIsValid } from 'helpers/validation'
import cn from 'classnames'
import './_change-password-form.scss'
import UserService from 'services/user_service'

interface ChangePasswordFormProps {
  inputFields: React.ComponentProps<typeof InputField>[]
  submitButtonText: string
  errorMessage: string
  requiredFieldsErrorMessage?: string
  inputErrorMessages: {
    passwordNotValid?: string
    passwordDoesNotMatch?: string
  }
  endpoint: string
}

export const ChangePasswordForm = ({
  inputFields,
  submitButtonText,
  errorMessage,
  requiredFieldsErrorMessage,
  inputErrorMessages,
  endpoint,
}: ChangePasswordFormProps) => {
  const [error, setError] = useState('')
  const [requiredFieldsMissing, setRequiredFieldsMissing] = useState(false)
  const [inputData, setInputData] = useState(inputFields)

  const newPassword = inputData.find((input) => input.validationType === 'newPassword')

  const hasRequiredFieldsMissing = (inputData: React.ComponentProps<typeof InputField>[]) => {
    if (
      inputData.some((input) => input.required && input.value === '') ||
      inputData.some(
        (input) =>
          input.value &&
          input.validationType === 'repeatedNewPassword' &&
          input.value !== newPassword?.value,
      )
    ) {
      return true
    } else return false
  }

  const applyInputErrorsOnSubmit = (inputFields: React.ComponentProps<typeof InputField>[]) => {
    inputFields.forEach((input: React.ComponentProps<typeof InputField>) =>
      (input.required && input.value === '') ||
      (input.value && input.validationType === 'newPassword' && !passwordIsValid(input.value)) ||
      (input.value &&
        input.validationType === 'repeatedNewPassword' &&
        input.value !== newPassword?.value)
        ? ((input.error = true),
          (input.errorText =
            input.validationType === 'newPassword'
              ? inputErrorMessages.passwordNotValid
              : input.validationType === 'repeatedNewPassword' && input.value !== ''
              ? inputErrorMessages.passwordDoesNotMatch
              : undefined))
        : (input.error = false),
    )
  }

  const validateInput = (inputField: React.ComponentProps<typeof InputField>, e: any) => {
    if (inputField.validationType !== 'newPassword' || 'repeatedNewPassword') {
      inputField.error = e.target.value === '' && inputField.required
    }
    if (inputField.validationType === 'newPassword') {
      inputField.errorText = passwordIsValid(e.target.value)
        ? undefined
        : inputErrorMessages.passwordNotValid
      inputField.error = !passwordIsValid(e.target.value) || e.target.value === ''
    }

    if (inputField.validationType === 'repeatedNewPassword') {
      inputField.errorText =
        e.target.value === newPassword?.value ? undefined : inputErrorMessages.passwordDoesNotMatch
      inputField.error = e.target.value !== newPassword?.value || e.target.value === ''
    }
  }

  const handleInputChange = (inputField: React.ComponentProps<typeof InputField>, e: any) => {
    inputField.value = e.target.value
    requiredFieldsMissing && validateInput(inputField, e)
  }

  return (
    <div className={cn('form form--edit-password', {})}>
      <div className='form__content'>
        <div className='form__input'>
          {inputFields.map((inputField) => (
            <div key={inputField.id} className='form__input-long'>
              <InputField
                {...inputField}
                value={inputField.value}
                onChange={(e) => {
                  handleInputChange(inputField, e)
                  setInputData([...inputFields])
                }}
              />
            </div>
          ))}
        </div>

        {(error || requiredFieldsMissing) && requiredFieldsErrorMessage && (
          <div className='form__error'>
            <ErrorMessage text={error ? error : requiredFieldsErrorMessage} />
          </div>
        )}

        <div className='form__button'>
          <Button
            text={submitButtonText}
            onClick={() => {
              applyInputErrorsOnSubmit(inputFields)
              if (hasRequiredFieldsMissing(inputData)) {
                setRequiredFieldsMissing(true)
              } else {
                setRequiredFieldsMissing(false)
                UserService.editPassword(inputData, endpoint, setError, errorMessage)
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}
