import { ReactComponent as EmptyCheckboxIcon } from 'assets/icons/checkbox.svg'
import { ReactComponent as CheckedCheckboxIcon } from 'assets/icons/checkbox-checked.svg'
import { useState } from 'react'
import './_checkbox.scss'

interface CheckboxProps {
  label: string
  name?: string
  id?: string
  checked?: boolean
  handleClick?: (isChecked: boolean) => void
}

export const Checkbox = ({ label, name, id, handleClick, checked }: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked)

  const handleChange = () => {
    setIsChecked(!isChecked)
    handleClick && handleClick(!isChecked)
  }

  return (
    <div className='checkbox'>
      <label className='checkbox__container' htmlFor={id}>
        <input
          type='checkbox'
          className='checkbox__input'
          value={label}
          name={name}
          id={id}
          onChange={(e) => handleChange()}
          checked={isChecked}
        />
        {isChecked ? (
          <CheckedCheckboxIcon className='checkbox__box' />
        ) : (
          <EmptyCheckboxIcon className='checkbox__box' />
        )}
        {label}
      </label>
    </div>
  )
}
