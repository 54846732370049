import { useEffect, useRef, useState } from 'react'
import { Heading } from 'components/elements/heading/heading'
import './_accordion.scss'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-up.svg'
import { Block } from 'types'
import { AccordionComponentList } from './accordion-component-list'
import { Collapse } from 'react-collapse'
import { useColor } from 'context/themeContext'
import cn from 'classnames';

interface AccordionProps {
  title?: string
  accordionList: AccordionItemProps[]
}

interface AccordionItemProps {
  title: string
  blocks: Block[]
}

interface AccordionElementProps {
  accordionItem: AccordionItemProps
  index: number
  open?: number
  setOpen: (newOpen: number) => void
  colorStyle: string
}

export const Accordion = ({ title, accordionList }: AccordionProps) => {
  const [open, setOpen] = useState<number>()
  const { color } = useColor();

  const updateOpen = (newIndex: number) => {
    if (newIndex === open) {
      setOpen(undefined)
    } else setOpen(newIndex)
  }

  const colorStyle = color === "green" ? "green" : "blue"

  return (
    <div className='accordion'>
      {title && (
        <Heading level={2} size='m' weight={700} className={cn('accordion__title', `accordion__title__${colorStyle}`)}>
          {title}
        </Heading>
      )}
      <hr className={cn('accordion__line', `accordion__line__${colorStyle}`)} />
      {accordionList.map((accordionItem, i) => (
        <AccordionItem
          key={i}
          accordionItem={accordionItem}
          index={i}
          open={open}
          setOpen={updateOpen}
          colorStyle={colorStyle}
        />
      ))}
    </div>
  )
}

const AccordionItem = ({ accordionItem, index, open, setOpen, colorStyle }: AccordionElementProps) => {
  const [isOpen, setIsOpen] = useState(index === open)

  const collapseRef = useRef<HTMLDivElement>(null)
  const handleClick = () => {
    setOpen(index)
  }

  useEffect(() => {
    if (index === open) {
      setIsOpen(true)
    } else setIsOpen(false)
  }, [open, index])

  return (
    <div className='accordion__item'>
      <button
        onClick={handleClick}
        className='accordion__item__header'
        aria-expanded={isOpen}
        aria-controls={'accordion-section-' + accordionItem.title.replace(/ /g, '-') + index}
        id={'accordion-id-' + accordionItem.title.replace(/ /g, '-') + index}
      >
        <Heading title={accordionItem.title} className={cn('accordion__item__title', `accordion__item__title__${colorStyle}` )} />
        <ArrowIcon
          style={{
            transform: isOpen ? 'none' : 'rotate(-0.5turn)',
          }}
          className={cn('accordion__item__icon', `accordion__item__icon__${colorStyle}`)}
        />
      </button>
      <Collapse isOpened={isOpen} theme={{ collapse: 'accordion__item__content' }}>
        <div
          id={'accordion-section-' + accordionItem.title.replace(/ /g, '-') + index}
          aria-labelledby={'accordion-id-' + accordionItem.title.replace(/ /g, '-') + index}
          className={cn('accordion__item__child', `accordion__item__child__${colorStyle}`)}
          ref={collapseRef}
          hidden={!isOpen}
        >
          {accordionItem.blocks && <AccordionComponentList blocks={accordionItem.blocks} />}
        </div>
      </Collapse>
      <hr className='accordion__line' />
    </div>
  )
}
