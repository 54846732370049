import { Radio } from 'components/elements/forms/radio/radio'
import { Heading } from 'components/elements/heading/heading'
import './_radio-list-block.scss'
import cn from "classnames"
import { useColor } from 'context/themeContext'

interface RadioListBlockProps {
  title: string
  radioList: React.ComponentProps<typeof Radio>[]
  currentSelected?: string
  onChange?: (e: any) => void
}

export const RadioListBlock = ({
  title,
  radioList,
  currentSelected,
  onChange,
}: RadioListBlockProps) => {
  const { color } = useColor();
  const colorStyle = color === "green" ? "green" : "blue";

  return (
    <div className={cn('radio-list-block', `radio-list-block__${colorStyle}`)}>
      {title && <Heading className={cn('radio-list-block__title', `radio-list-block__title__${colorStyle}`)}>{title}</Heading>}
      {radioList &&
        radioList.map((radio) => (
          <div key={radio.id} className='radio-list-block__radio'>
            <Radio
              onChange={onChange ? onChange : undefined}
              isSelected={currentSelected === radio.value}
              {...radio}
            />
          </div>
        ))}
    </div>
  )
}
