import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg'
import cn from 'classnames'
import { Heading } from 'components/elements/heading/heading'
import { useColor } from 'context/themeContext'
import './_list.scss'

interface ListProps {
  numbers?: boolean
  title: string
  list: {
    title: string
    ingress?: string
  }[]
  background?: boolean
}

export const List = ({ numbers = false, title, list, background = false }: ListProps) => {
  const { color } = useColor()
  return list.length > 0 ? (
    <div
      className={cn('list', {
        [`list__background-${color}`]: background,
      })}
    >
      <h1 className={cn('list__title', { [`list__title__hide`]: !numbers })}>{title}</h1>
      {numbers ? (
        <ol className='list__ordered-list'>
          {list.map((listItem) => (
            <li
              className={cn('list__ordered-list__element', {
                [`list__ordered-list__element__color-${color}`]: color,
              })}
            >
              <div className='list__ordered-list__item'>
                <Heading
                  level={1}
                  weight={700}
                  title={listItem.title}
                  className={cn('list__ordered-list__header', {
                    [`list__color-${color}`]: color,
                  })}
                />
                {listItem.ingress &&
                  listItem.ingress
                    .split('\n')
                    .map((ingress) => <p className='list__ordered-list__ingress'>{ingress}</p>)}
              </div>
            </li>
          ))}
        </ol>
      ) : (
        <ul className='list__unordered-list'>
          {list.map((listItem) => (
            <li className='list__unordered-list__item'>
              <CheckIcon
                className={cn('list__unordered-list__checkmark', {
                  [`list__unordered-list__checkmark-${color}`]: color,
                })}
              />
              <Heading
                level={1}
                weight={700}
                title={listItem.title}
                className={cn('list__unordered-list__header', {
                  [`list__color-${color}`]: color,
                })}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : null
}
