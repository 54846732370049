import React from 'react'
import { TitleAndIngress } from '../../../elements/title-and-ingress/title-and-ingress'
import cn from 'classnames'
import './_image-header.scss'
import { useColor } from 'context/themeContext'
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg'

interface ImageHeaderProps extends React.ComponentProps<typeof TitleAndIngress> {
  image: { url: string; alt: string; photographer: string }
  photographerLabel?: string
}

export const ImageHeader = ({ image, heading, ingress, photographerLabel }: ImageHeaderProps) => {
  const { color, type } = useColor()

  return (
    <div
      className={cn({
        [`bgcolor-${color === 'yellow' ? color + '-3' : color + '-1'}`]: type === 'solid',
      })}
    >
      <div className={cn('image-header')}>
        <TitleAndIngress
          className='image-header__content'
          {...{ heading: { ...heading, level: 1, size: 'display' }, ingress }}
          color={color === 'yellow' ? 'blue' : color === 'white' ? undefined : color}
        />
        <img className='image-header__image' src={image.url} alt={image.alt} />
        <div className='image-header__image-text'>
          {image.photographer && <ArrowUpIcon className='image-header__image-icon' />}
          {image.photographer && `${photographerLabel ?? 'Foto: '}${image.photographer}`}
        </div>
      </div>
    </div>
  )
}
