import React, { useState } from 'react'
import { Button } from 'components/elements/button/button'
import { Heading } from 'components/elements/heading/heading'
import { Tag } from 'components/elements/tag/tag'
import { useColor } from 'context/themeContext'
import { ReactComponent as PlusIcon } from 'assets/icons/plus-circle.svg'
import { ReactComponent as MinusIcon } from 'assets/icons/minus-circle.svg'

import { Image, Link } from 'types'
import { useShoppingCart } from 'context/shoppingCartContext'
import './_product.scss'
import { useHeader } from 'context/headerContext'

interface ProductProps {
  id: number
  text: string
  textHeading: string
  image: Image
  price: string
  priceDecorationText?: string
  keyInfo: { [key: string]: string }
  keyInfoLabel: string
  tags: Link[]
  tagTitle: string
  addToCartEndpoint: string
  addToCartButtonText: string
  amountErrorText: string
  priceInformationText?: string
  amountLabel: string
  shoppingCartUrl?: string
}

export const Product = ({
  id,
  text,
  image,
  price,
  priceDecorationText,
  keyInfo,
  textHeading,
  tags,
  tagTitle,
  addToCartEndpoint,
  amountErrorText,
  priceInformationText,
  amountLabel,
  keyInfoLabel,
  addToCartButtonText,
  shoppingCartUrl,
}: ProductProps) => {
  const { color } = useColor()
  const { addProduct } = useShoppingCart()
  const { setShowHeader } = useHeader()
  const { shoppingCart, maxProductAmount } = useShoppingCart()
  
  const quantity = shoppingCart.find((product) => product.id === id)?.quantity ?? 0
  const [amount, setAmount] = useState<number>(quantity >= maxProductAmount ? 0 : 1)
  const [showQuantityWarning, setShowQuantityWarning] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)

  return (
    <div className='product'>
      <div className='product__image-col'>
        <div className={`product__image-wrapper bgcolor-${color}-1`}>
          <img className='product__image' src={image.url} alt={image.alt} />
        </div>
      </div>
      <div className='product__price'>
        <strong>
          {price}
          {priceDecorationText && priceDecorationText}
        </strong>{' '}
        {priceInformationText && priceInformationText}
      </div>
      <div>
        <div className='product__amount'>
          <p>{amountLabel}</p>
          <div className='product__amount-picker'>
            <button
              onClick={() => {
                if (amount > 0) {
                  setAmount(amount - 1)
                  if (amount - 1 <= maxProductAmount) setShowQuantityWarning(false)
                } else {
                  setAmount(0)
                }
              }}
              className='product__amount-button'
            >
              <MinusIcon />
            </button>
            <input
              type='number'
              value={amount}
              onChange={(e) => {
                const val = Number(e.target.value)
                setAmount(val)
              }}
              className='product__amount-input'
            />
            <button
              onClick={() => {
                if (amount + quantity < maxProductAmount) {
                  setAmount(amount + 1)
                  setShowQuantityWarning(false)
                } else setShowQuantityWarning(true)
              }}
              className='product__amount-button'
            >
              <PlusIcon />
            </button>
          </div>
          <div>
            {addToCartButtonText && (
              <Button
                color='dark-red'
                className='product__shop-button'
                disabled={buttonLoading}
                onClick={async () => {
                  if (amount + quantity <= maxProductAmount) {
                    setButtonLoading(true)
                    var result = amount && (await addProduct(id, addToCartEndpoint, amount))
                    if (result && shoppingCartUrl) window.location.href = `${shoppingCartUrl}`
                    else if (result) setShowHeader(true)
                  } else {
                    setShowQuantityWarning(true)
                  }
                }}
              >
                {addToCartButtonText}
              </Button>
            )}
          </div>
        </div>
        {((amount !== undefined && (amount + quantity > maxProductAmount || amount < 1)) ||
          showQuantityWarning) && <div className='product__amount-error'>{amountErrorText}</div>}
      </div>
      {keyInfo && keyInfoLabel && (
        <div className='product__key-info'>
          <Heading level={3} size='xs' weight={700}>
            {keyInfoLabel}
          </Heading>
          <ul>
            {Object.entries(keyInfo).map(([key, value], i) => (
              <li key={i}>{`${key}: ${value}`}</li>
            ))}
          </ul>
        </div>
      )}
      <div className='product__info'>
        <Heading level={3} size='xs' weight={700}>
          {textHeading}
        </Heading>
        {text}
      </div>
      {tags && (
        <div className='product__tags'>
          <Heading level={3} size='xs' weight={700}>
            {tagTitle}
          </Heading>
          {tags.map(({ text, url }, i) => (
            <Tag
              key={i}
              url={url}
              tag={text}
              color={
                color === 'yellow' || color === 'white' || color === 'dark-red' ? 'red' : color
              }
            />
          ))}
        </div>
      )}
    </div>
  )
}
