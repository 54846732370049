import './_product-page.scss'
import { pageProps } from 'types'
import { Layout } from '../../components/layout/layout'
import { ComponentList } from '../../components/blocks/component-list/component-list'
import { Header } from 'components/layout/header'
import { Product } from 'components/blocks/product/product'
import { ProductPageList } from 'components/blocks/product-page-list/product-page-list'

interface ProductPageProps extends pageProps {
  product: React.ComponentProps<typeof Product>
  otherProducts: React.ComponentProps<typeof ProductPageList>
}

export const ProductPage = ({
  layout,
  header,
  blocks,
  product,
  otherProducts,
}: ProductPageProps) => (
  <Layout {...layout} theme={layout.theme ?? { color: 'green' }} className='product-page'>
    {header && <Header {...{ ...header, data: { ...header.data, background: 'green' } }} />}
    {product && <Product {...product} />}
    {blocks && <ComponentList blocks={blocks} />}
    {otherProducts && <ProductPageList {...otherProducts} />}
  </Layout>
)
