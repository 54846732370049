import { CheckoutProductsOverview } from 'components/blocks/checkout-products-overview/checkout-products-overview'
import { Button } from 'components/elements/button/button'
import { CheckoutTotal } from 'components/elements/checkout-total/checkout-total'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import { useShoppingCart } from 'context/shoppingCartContext'
import React from 'react'
import { pageProps } from 'types'
import './_shopping-cart-page.scss'

interface ShoppingCartPageProps extends pageProps {
  checkoutProductsOverviewProps: {
    productTextLabel: string
    productArticleNumberLabel: string
    quantityLabel: string
    priceLabel: string
    removeProductLabel: string
    noProductsText: string
    removeProductEndpoint: string
    editProductQuantityEndpoint: string
  }
  totalPriceText: string
  priceWithoutShippingLabel: string
  shippingCostLabel: string
  backToStoreLink: React.ComponentProps<typeof Button>
  goToCheckoutLink: React.ComponentProps<typeof Button>
}

export const ShoppingCartPage = ({
  layout,
  header,
  checkoutProductsOverviewProps,
  totalPriceText,
  priceWithoutShippingLabel,
  shippingCostLabel,
  backToStoreLink,
  goToCheckoutLink,
}: ShoppingCartPageProps) => {
  return (
    <Layout {...layout} theme={layout.theme ?? { color: 'green' }} className='shopping-cart-page'>
      {header && <Header {...header} />}
      <div className='shopping-cart-page__body'>
        <CheckoutProductsOverview {...checkoutProductsOverviewProps} />
        {totalPriceText && <CheckoutTotal priceWithoutShippingLabel={priceWithoutShippingLabel} shippingCostLabel={shippingCostLabel} totalPriceText={totalPriceText} />}
        <div className='shopping-cart-page__buttons'>
          {backToStoreLink && (
            <Button className='shopping-cart-page__button' outline={true} {...backToStoreLink} />
          )}
          {goToCheckoutLink && <ToCheckoutButton goToCheckoutLink={goToCheckoutLink} />}
        </div>
      </div>
    </Layout>
  )
}

interface ToCheckoutButtonProps {
  goToCheckoutLink: React.ComponentProps<typeof Button>
}

const ToCheckoutButton = ({ goToCheckoutLink }: ToCheckoutButtonProps) => {
  const { productsCount } = useShoppingCart()

  return (
      <Button disabled={productsCount <= 0} {...goToCheckoutLink} />
  )
}
