import { Form } from 'components/blocks/form/form'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import './_create-user-page.scss'

interface CreateUserPageProps {
  layout: React.ComponentProps<typeof Layout>
  header: React.ComponentProps<typeof Header>
  createUserForm?: React.ComponentProps<typeof Form>
}

export const CreateUserPage = ({ layout, header, createUserForm }: CreateUserPageProps) => {
  return (
    <Layout {...layout} theme={layout.theme ?? {color: 'white', type: 'solid'}} className='create-user-page'>
      {header && <Header {...header} />}
      {createUserForm && <Form {...createUserForm} />}
    </Layout>
  )
}
