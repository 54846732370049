import { Heading } from 'components/elements/heading/heading'
import React from 'react'
import { Link } from 'types'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'
import './_entrance-links.scss'
import { useColor } from 'context/themeContext'
import cn from 'classnames'

interface EntranceLinksProps {
  title: string
  links: Link[]
}

export const EntranceLinks = ({ title, links }: EntranceLinksProps) => {
  const { color } = useColor()
  return (
    <div
      className={cn('entrance-links', {
        [`color-${color === 'green' || color === 'blue' ? color : 'dark-red'}`]: color !== 'white',
      })}
    >
      <Heading level={2} size='xs' className='entrance-links__title'>
        {title}
      </Heading>
      <ul className='entrance-links__list'>
        {links &&
          links.map((link, i) => (
            <li key={i}>
              <div>
                <ArrowRightIcon className='entrance-links__icon' />
              </div>
              <a href={link.url} target={link.target} className='entrance-links__link'>
                <span>{link.text}</span>
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}
