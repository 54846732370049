import React from 'react'
import { Layout } from '../../components/layout/layout'
import './online-store-page.scss'
import { Header } from 'components/layout/header'
import { pageProps } from 'types'
import { ProductPageList } from 'components/blocks/product-page-list/product-page-list'
import { EntranceLinks } from 'components/blocks/entrance-links/entrance-links'
import { NewsArticleBlock } from 'components/blocks/news-article-block/news-article-block'
import { InfoBlock } from 'components/blocks/info-block/info-block'

interface OnlineStorePageProps extends pageProps {
  shopCategories: React.ComponentProps<typeof EntranceLinks>
  promotedProduct: React.ComponentProps<typeof NewsArticleBlock>
  productPageList: React.ComponentProps<typeof ProductPageList>
  infoBlock?: React.ComponentProps<typeof InfoBlock>
}

export const OnlineStorePage = ({
  layout,
  header,
  shopCategories,
  promotedProduct,
  productPageList,
  infoBlock,
}: OnlineStorePageProps) => {
  return (
    <Layout
      {...layout}
      theme={layout.theme ?? { color: 'green', type: 'solid' }}
      className='online-store-page'
    >
      {header && <Header {...header} />}
      <div className='online-store-page__content'>
        {shopCategories && <EntranceLinks {...shopCategories} />}
      </div>
      {promotedProduct && <NewsArticleBlock {...promotedProduct} />}
      <div className='online-store-page__content'>
        {productPageList && <ProductPageList {...productPageList} />}
        {infoBlock && (
          <div className='online-store-page__info-block'>
            <InfoBlock {...infoBlock} />
          </div>
        )}
      </div>
    </Layout>
  )
}
