import React from 'react'
import './_shortcuts-block.scss'
import { ReactComponent as HomeShortcutsIcon } from 'assets/icons/home-shortcuts.svg'
import { ReactComponent as RocketIcon } from 'assets/icons/rocket.svg'
import { ReactComponent as ActivityRecIcon } from 'assets/icons/activity-rec.svg'
import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg'
import { ReactComponent as ReceiptIcon } from 'assets/icons/receipt.svg'
import { ReactComponent as SprinkleIcon } from 'assets/icons/sprinkle.svg'
import { ReactComponent as WaterMistIcon } from 'assets/icons/water-mist.svg'
import { ReactComponent as FoamIcon } from 'assets/icons/foam.svg'
import { ReactComponent as GasIcon } from 'assets/icons/gas.svg'
import { ReactComponent as MoreOptionsIcon } from 'assets/icons/more-options.svg'
import { Heading } from 'components/elements/heading/heading'
import cn from 'classnames'
import { useColor } from 'context/themeContext'

interface ShortcutsBlockProps {
  className?: string
  title: string
  list: {
    icon?: 'home-shortcuts' | 'rocket' | 'activity-rec' | 'heart' | 'receipt' | 'sprinkle' | 'water-mist' | 'foam' | 'gas' | 'more-options'
    text: string
    url: string
  }[]
}

export const ShortcutsBlock = ({ title, list, className }: ShortcutsBlockProps) => {
  const { color } = useColor()
  return (
    <div
      className={cn('shortcuts-block', className, {
        [`shortcuts-block__color-${color}`]: color,
      })}
    >
      <Heading className={cn('shortcuts-block__title', className)}>{title}</Heading>
      {list.length !== 0 && (
        <ul className={cn('shortcuts-block__items', className)}>
          {list.map((item) => (
            <li className='shortcuts-block__item' key={item.text}>
              <a
                className={cn('shortcuts-block__link', className, {
                  [`shortcuts-block__link__color-${color}`]: color,
                })}
                href={item.url}
              >
                <span></span>
                {item.icon && item.icon === 'home-shortcuts' ? (
                  <HomeShortcutsIcon />
                ) : item.icon === 'rocket' ? (
                  <RocketIcon />
                ) : item.icon === 'activity-rec' ? (
                  <ActivityRecIcon />
                ) : item.icon === 'heart' ? (
                  <HeartIcon />
                ) : item.icon === 'receipt' ? (
                  <ReceiptIcon />
                ) : item.icon === 'sprinkle' ? (
                  <SprinkleIcon />
                ): item.icon === 'water-mist' ? (
                  <WaterMistIcon />
                ): item.icon === 'foam' ? (
                  <FoamIcon />
                ): item.icon === 'more-options' ? (
                  <MoreOptionsIcon />
                ) : item.icon === 'gas' ? (
                  <GasIcon />
                ) : null}
                {item.text}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
