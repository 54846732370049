import React from 'react'
import { RichText } from '../../blocks/rich-text/rich-text'
import { ReactComponent as Logo } from 'assets/logo/OFAS-logo.svg'
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg'
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg'
import { ReactComponent as LinkedInIcon } from 'assets/icons/linkedin.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg'
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg'
import './OFAS-footer.scss'
import { Link } from 'types'

interface OFASFooterProps {
  logo: {
    ariaLabel: string
    url: string
  }

  address: {
    title: string
    line1?: string
    line2?: string
    postalCode?: string
  }

  linkList?: {
    title: string
    links: Link[]
  }

  contact: {
    emailText: string
    emailUrl: string
    phoneText: string
    phoneUrl: string
  }

  editors: {
    label: string
    text: string
    url: string
  }[]

  socialMediaLinks: {
    icon?: 'facebook' | 'instagram' | 'twitter' | 'linkedin'
    url: string
    ariaLabel: string
  }[]

  infoText: string
}

export const OFASFooter = ({
  logo,
  address,
  linkList: links,
  contact,
  editors,
  socialMediaLinks,
  infoText,
}: OFASFooterProps) => (
  <footer className='OFAS-footer'>
    <div className='OFAS-footer__content'>
      <a className='OFAS-footer__logo' href={logo.url} aria-label={logo.ariaLabel}>
        <Logo />
      </a>
      <div className='OFAS-footer__contact'>
        {address && (
          <div className='OFAS-footer__contact-address'>
            {address.title && <h2 className='OFAS-footer__title'>{address.title}</h2>}
            {address.line1 && <div>{address.line1}</div>}
            {address.line2 && <div>{address.line2}</div>}
            {address.postalCode && <div>{address.postalCode}</div>}
          </div>
        )}
        {links?.links && links.links.length !== 0 && (
          <div>
            {links.title && <h2 className='OFAS-footer__title'>{links.title}</h2>}
            <ul className='OFAS-footer__links'>
              {links.links.map((link) => (
                <li key={link.text}>
                  <a className='OFAS-footer__link' href={link.url}>
                    <span>{link.text}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div>
        {contact && (
          <ul className='OFAS-footer__contact-info'>
            <li>
              <a className='OFAS-footer__link' href={contact.emailUrl}>
                <div className='OFAS-footer__link-icon'>
                  <EmailIcon />
                </div>
                <span>{contact.emailText}</span>
              </a>
            </li>
            <li>
              <a className='OFAS-footer__link' href={contact.phoneUrl}>
                <div className='OFAS-footer__link-icon'>
                  <PhoneIcon />
                </div>
                <span>{contact.phoneText}</span>
              </a>
            </li>
          </ul>
        )}
        {editors && editors.length !== 0 && (
          <ul className='OFAS-footer__editors'>
            {editors.map((editor) => (
              <li className='OFAS-footer__editor' key={editor.text}>
                <div className='OFAS-footer__editor-label'>{editor.label}</div>
                <a className='OFAS-footer__link' href={editor.url}>
                  <span>{editor.text}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div>
        {socialMediaLinks && socialMediaLinks.length !== 0 && (
          <ul className='OFAS-footer__social-media-links'>
            {socialMediaLinks.map((socialMedia) => (
              <li className='OFAS-footer__social-media-link' key={socialMedia.icon}>
                <a href={socialMedia.url} aria-label={socialMedia.ariaLabel}>
                  {socialMedia.icon === 'facebook' ? (
                    <FacebookIcon />
                  ) : socialMedia.icon === 'instagram' ? (
                    <InstagramIcon />
                  ) : socialMedia.icon === 'twitter' ? (
                    <TwitterIcon />
                  ) : socialMedia.icon === 'linkedin' ? (
                    <LinkedInIcon />
                  ) : null}
                </a>
              </li>
            ))}
          </ul>
        )}
        {infoText && <RichText html={infoText} />}
      </div>
    </div>
  </footer>
)
