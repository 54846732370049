import React from 'react'
import { CardList, CardListExternalProps } from '../card-list/card-list'
import { ProductCard } from 'components/elements/product-card/product-card'
import './_product-page-list.scss'

interface ProductPageListProps extends CardListExternalProps {
  products: React.ComponentProps<typeof ProductCard>[]
}

export const ProductPageList = ({ title, products, button }: ProductPageListProps) => (
  <div className='product-page-list'>
    <CardList
      data={products}
      title={title}
      button={button}
      renderItem={(item) => <ProductCard {...item} />}
    />
  </div>
)
