import React from 'react'

import cn from 'classnames'
import './_image-box.scss'

export interface ImageBoxProps {
  text?: string
  className?: string
}

export const ImageBox = ({ text, className }: ImageBoxProps) => {
  return (
    <div className='image-box'>
      <p className={cn('image-box__text', className)}>{text}</p>
    </div>
  )
}
