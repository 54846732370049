import './_infographic-block.scss'
import cn from 'classnames'

interface InfographicBlockProps {
    infoList: {
        value: string
        text: string
    }[]
    color?: "red" | "blue" | "orange" | "green"
}

export const InfographicBlock = ({infoList, color = "blue"} : InfographicBlockProps) => (
    <div className={cn('infographic', {
        [`infographic__color-${color}`]: color,
      })}>
        {infoList.map((info, i) => (
            <div className="infographic__item">
                <p className="infographic__value">{info.value}</p>
                <p className="infographic__text">{info.text}</p>
            </div>
        ))}
    </div>
)