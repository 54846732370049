import React from 'react'
import './_button.scss'
import cn from 'classnames'
import { Colors } from 'types'
import { useColor } from 'context/themeContext'
export interface ButtonProps {
  children?: React.ReactNode
  text?: string
  url?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  outline?: boolean
  target?: string
  size?: 's' | 'm'
  disabled?: boolean
  type?: 'button' | 'submit'
  form?: string
  color?: Colors
  ariaExpanded?: boolean
  ariaControls?: string
}
export const Button = ({
  children,
  text,
  url,
  onClick,
  className,
  outline = false,
  target,
  size = 'm',
  disabled = false,
  type,
  form,
  color = 'orange',
  ariaExpanded,
  ariaControls
}: ButtonProps) => {
  const colorTheme = useColor()
  const colorToUse = color ?? colorTheme.color

  return url ? (
    <a
      className={cn('button button__link button__' + size, className, {
        [`button__color-${colorToUse}`]: colorToUse,
        [`button__outline-${colorToUse}`]: outline,
        [`button__disabled`]: disabled,
      })}
      href={url}
      target={target}
    >
      {text ? text : children}
    </a>
  ) : (
    <button
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
      type={type}
      form={form}
      disabled={disabled}
      onClick={onClick}
      className={cn('button button__button button__' + size, className, {
        [`button__color-${colorToUse}`]: colorToUse,
        [`button__outline-${colorToUse}`]: outline,
        [`button__disabled`]: disabled,
      })}
    >
      {text ? text : children}
    </button>
  )
}
