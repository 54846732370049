import { ComponentList } from 'components/blocks/component-list/component-list'
import { InstructorList } from 'components/blocks/instructor-list/instructor-list'
import { ListSearchBlock } from 'components/blocks/list-search-block/list-search-block'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import {
  filterPropsType,
  instructorInfoType,
  pagedFilterType,
  pageProps,
  searchBarPropsType,
} from 'types'
import './_instructor-search-page.scss'

interface InstructorSearchPageProps extends pageProps {
  instructorCardLabels: {
    companyNameLabel: string
    companyAddressLabel: string
    countyLabel: string
    phoneLabel: string
    emailLabel: string
    certificateNumberLabel: string
    isValidStatusLabel: string
    isValidLabel: string
    isInvalidLabel: string
  }

  listSearchProps: {
    queryUrl: string
    searchBarProps: searchBarPropsType
    filterProps: filterPropsType
    defaultFilter: pagedFilterType
    noResultsText: string
    errorMessage: string
  }
}

export const InstructorSearchPage = ({
  layout,
  header,
  blocks,
  instructorCardLabels,
  listSearchProps,
}: InstructorSearchPageProps) => {
  return (
    <Layout {...layout}
    theme={layout.theme ?? { color: 'blue' }} 
    className='instructor-search-page'>
      {header && <Header {...header} />}
      <div className='instructor-search-page__body'>
        <ListSearchBlock
          queryUrl={listSearchProps.queryUrl}
          searchBarProps={{
            placeHolderLabel: listSearchProps.searchBarProps.placeHolderLabel,
            searchButtonLabel: listSearchProps.searchBarProps.searchButtonLabel,
          }}
          defaultFilter={{
            page: listSearchProps.defaultFilter.page,
            pageSize: listSearchProps.defaultFilter.pageSize,
          }}
          noResultsText={listSearchProps.noResultsText}
          errorMessage={listSearchProps.errorMessage}
          displayData={(results: instructorInfoType[]) => (
            <InstructorList
              instructors={results}
              instructorCardLabels={instructorCardLabels}
            />
          )}
        />
      </div>
      {blocks && <ComponentList blocks={blocks} />}
    </Layout>
  )
}
