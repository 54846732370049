import axios from 'axios'
import { Logo } from 'components/elements/logo/logo'
import { Search } from 'components/elements/search/search'
import React, { useCallback, useEffect, useState } from 'react'
import {
  filterOptionsType,
  filterPropsType,
  filterType,
  pagedFilterType,
  searchBarPropsType,
} from 'types'
import { Filter } from '../filter/filter'
import { PageNav } from '../page-nav/page-nav'
import './_list-search-block.scss'

interface ListSearchBlock<T> {
  queryUrl: string
  searchBarProps?: searchBarPropsType
  filterProps?: filterPropsType
  defaultFilter: pagedFilterType
  noResultsText: string
  errorMessage: string
  displayData: (results: T[]) => React.ReactNode
}

export const ListSearchBlock = <T extends object>({
  queryUrl,
  searchBarProps,
  filterProps,
  defaultFilter,
  noResultsText,
  errorMessage,
  displayData,
}: ListSearchBlock<T>) => {
  const [query, setQuery] = useState('')
  const [queryInput, setQueryInput] = useState('')
  const [page, setPage] = useState(defaultFilter.page)
  const [results, setResults] = useState(Array<T>())
  const [data, setData] = useState({
    showNext: undefined,
    showPrev: undefined,
    hitsText: undefined,
  })
  const pageSize = defaultFilter.pageSize
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [filter, setFilter] = useState<filterType>({})
  const [filterOptions, setFilterOptions] = useState<filterOptionsType | undefined>(
    filterProps?.filterOptions,
  )

  const search = useCallback(async () => {
    let params = getParams()
    try {
      updateCurrentHistoryEntry(params)
      setLoading(true)
      await getAndSetSearchResults(params)
    } catch (error) {
      setError(true)
    }
    setLoading(false)
    // }, [filter, page, pageSize, queryUrl])
  }, [filter, page, pageSize, queryInput, queryUrl]) // for searchpage

  const getAndSetSearchResults = async (params: filterType) => {
    await axios
      .get(queryUrl, {
        params: params,
      })
      .then((res) => {
        if (res.data.value.filterOptions) setFilterOptions(res.data.value.filterOptions)
        setData(res.data.value)
        setResults(res.data.value.result)
        setError(false)
      })
      .catch((error) => {
        setError(true)
      })
  }

  // Retrieves the parameter and filter data from filter object
  const getParams = () => {
    let params: filterType = filter
    if (searchBarProps && queryInput !== '') {
      params.query = queryInput
    } else {
      params.query = ''
    }

    params.page = '' + page
    params.pageSize = '' + pageSize
    return params
  }

  const saveWindowHistoryState = () => {
    let params = getParams()
    const current: URL = new URL(window.location.toString())
    current.search = new URLSearchParams(params).toString()
    window.history.pushState(params, '', current) // Add the current page to the browser history before going to another page
  }

  const updateCurrentHistoryEntry = (params: filterType) => {
    const current: URL = new URL(window.location.toString())
    current.search = new URLSearchParams(params).toString()
    window.history.replaceState(params, '', current) // Updates the history browser state
  }
  // Check if any parameters is set in the url when rendering the page.
  useEffect(() => {
    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: any) => searchParams.get(prop),
    })

    setQueryInput(params.query ?? '')
    setPage(params.page ?? 1)

    if (filterProps) {
      let newFilter: filterType = {}

      Object.entries(filterProps.filterOptions).forEach(([key]) => {
        if (params.key) {
          newFilter[key] = params.key!
        }
      })

      setFilter(newFilter)
    }
    if (!searchBarProps && !filterProps) {
      search()
    }
  }, [])

  const notInitialRender = React.useRef(false)
  React.useEffect(() => {
    if (notInitialRender.current) {
      search()
    } else {
      notInitialRender.current = true
    }
  }, [search])

  window.addEventListener(
    'popstate',
    async function (event) {
      const currentUrl = new URL(`${window.location.href}`)
      const initialSelectedPage = currentUrl.searchParams.get('page') ?? '1'
      const currentSearch = currentUrl.searchParams.get('query') ?? ''
      setQuery(currentSearch)
      setQueryInput(currentSearch)
      setPage(parseInt(initialSelectedPage)) // Sets the page to the new page after back or forth button in browser have been pressed. This is to trigger a new search for new data on this new page.
    },
    false,
  )

  return (
    <div className='list-search-block'>
      <div className='list-search-block__filter'>
        {searchBarProps && (
          <Search
            searchButtonText={searchBarProps.searchButtonLabel}
            search={() => {
              saveWindowHistoryState()
              setQueryInput(query)
              setPage(1)
            }}
            onChange={(e) => {
              setQuery(e.target.value)
            }}
            resetSearchString={() => {
              saveWindowHistoryState()
              setQuery('')
              setResults([])
              setQueryInput('')
              setPage(1)
            }}
            value={query}
            {...{
              text: searchBarProps.searchButtonLabel,
              placeholder: searchBarProps.placeHolderLabel,
              searchPageUrl: 'sokside',
              enpointUrl: 'sokside/search',
            }}
          />
        )}
        {filterProps && filterOptions && (
          <div className={searchBarProps ? 'list-search-block__filter-box' : ''}>
            <Filter
              filterOptions={filterOptions}
              setFilter={setFilter}
              setPage={setPage}
              filter={filter}
              filterLabel={filterProps.filterLabel}
              clearFilterText={filterProps.clearFilterText}
            />
          </div>
        )}
      </div>
      {loading ? (
        <div className='search-page__loading'>
          <Logo loop />
        </div>
      ) : error ? (
        <div>{errorMessage}</div>
      ) : (
        <div>
          {results !== undefined && results.length !== 0 && (
            <div>
              {displayData(results)}
              <PageNav
                nextPage={() => {
                  saveWindowHistoryState()
                  setPage(page + 1)
                }}
                showNext={data.showNext}
                showPrev={data.showPrev}
                hitsText={data.hitsText ? data.hitsText : JSON.stringify(page)}
                prevPage={() => {
                  saveWindowHistoryState()
                  setPage(page - 1)
                }}
              />
            </div>
          )}
          {(results == undefined || results?.length === 0) && queryInput !== "" && <div>{noResultsText}</div>}
        </div>
      )}
    </div>
  )
}
