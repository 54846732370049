import { InputField } from 'components/elements/forms/input-field/input-field';
import { Form } from 'components/blocks/form/form';

export const emailIsValid = (input: string) => {
    return input.includes("@") && input.includes(".")
  }

export const postalCodeIsValid = (input: string) => {
    return true
  }

export const phoneNumberIsValid = (number: string) => {
    const PHONE_REGEX = new RegExp(/^[1-9][0-9]{7,9}$/)
    return number.match(PHONE_REGEX)
  }

export const passwordIsValid = (input: string) => {
  return input !== '' && input.length >= 8
}

export const hasRequiredFieldsMissing = (inputData: React.ComponentProps<typeof InputField>[]) => {
    if (
      inputData.some((input) => input.required && input.value === '') ||
      inputData.some(
        (input) => input.value && input.validationType === 'email' && !emailIsValid(input.value),
      ) ||
      inputData.some(
        (input) =>
          input.value && input.validationType === 'postalCode' && !postalCodeIsValid(input.value),
      ) ||
      inputData.some(
        (input) =>
          input.value && input.validationType === 'newPassword' && !passwordIsValid(input.value),
      ) ||
      inputData.some(
        (input) =>
          input.value && input.validationType === 'phone' && !phoneNumberIsValid(input.value),
      )
    ) {
      return true
    } else return false
  }
  
    export const validateInput = (
    inputField: React.ComponentProps<typeof InputField>,
    e: any,
    form: React.ComponentProps<typeof Form>,
  ) => {
    if (inputField.validationType !== 'email' || 'postalCode' || 'password') {
      inputField.error = e.target.value === '' && inputField.required
    }
    if (inputField.validationType === 'email') {
      inputField.errorText = emailIsValid(e.target.value)
        ? undefined
        : form.inputErrorMessages.email
      inputField.error = !emailIsValid(e.target.value) || e.target.value === ''
    }
if (inputField.validationType === 'postalCode') {
      inputField.errorText = postalCodeIsValid(e.target.value)
        ? undefined
        : form.inputErrorMessages.postalCode
      inputField.error = !postalCodeIsValid(e.target.value) || e.target.value === ''
    }
    if (inputField.validationType === 'newPassword') {
      inputField.errorText = passwordIsValid(e.target.value)
        ? undefined
        : form.inputErrorMessages.password
      inputField.error = !passwordIsValid(e.target.value) || e.target.value === ''
    }
    if (inputField.validationType === 'phone') {
      inputField.errorText = phoneNumberIsValid(e.target.value)
        ? undefined
        : form.inputErrorMessages.phone
      inputField.error = !phoneNumberIsValid(e.target.value) || e.target.value === ''
    }
  }

   export const applyInputErrorsOnSubmit = (inputFields: React.ComponentProps<typeof InputField>[], form: React.ComponentProps<typeof Form> | undefined ) => {
    inputFields.forEach((input: React.ComponentProps<typeof InputField>) =>
      (input.required && input.value === '') ||
      (input.value && input.validationType === 'email' && !emailIsValid(input.value)) ||
(input.value && input.validationType === 'postalCode' && !postalCodeIsValid(input.value)) ||
      (input.value && input.validationType === 'newPassword' && !passwordIsValid(input.value)) ||
      (input.value && input.validationType === 'phone' && !phoneNumberIsValid(input.value))
        ? ((input.error = true),
          (input.errorText =
            input.validationType === 'email'
              ? form && form.inputErrorMessages.email
: input.validationType === 'postalCode'
              ? form && form.inputErrorMessages.postalCode
              : input.validationType === 'newPassword'
              ? form && form.inputErrorMessages.password
              : input.validationType === 'repeatedNewPassword'
              ? 'Passord matcher ikke'
              : input.validationType === 'phone'
              ? form && form.inputErrorMessages.phone
              : undefined))
        : (input.error = false),
    )
  }