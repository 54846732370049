import React from 'react'
import { Layout } from '../../components/layout/layout'
import { ComponentList } from '../../components/blocks/component-list/component-list'
import { Header } from 'components/layout/header'
import { pageProps } from 'types'
import { CertificateSearch } from 'components/blocks/certificate-search/certificate-search'
import './_certificate-search-page.scss'

interface CertificateSearchPageProps extends pageProps {
  certificateSearch: React.ComponentProps<typeof CertificateSearch>
}

export const CertificateSearchPage = ({
  layout,
  header,
  certificateSearch,
  blocks
}: CertificateSearchPageProps) => (
  <Layout {...layout} theme={layout.theme ?? {color: 'blue'}} className='certificate-search-page'>
    <div className='certificate-search-page-container'>
      {header && <Header {...header} />}
      {certificateSearch && <CertificateSearch {...certificateSearch} />}
      {blocks && <ComponentList blocks={blocks} />}
    </div>
  </Layout>
)
