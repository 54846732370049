import { chapter, subChapter } from 'pages/digitalManualPage/digitalManualPage'
import React, { useEffect } from 'react'
import './_content-table.scss'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import useAnimateHeight from 'hooks/useAnimateHeight.hook'
import cn from 'classnames'
import { Heading } from '../../elements/heading/heading'

interface ContentTableProps {
  contentTableTitle: string
  chapters: chapter[]
  active: { index: number; subindex: number }
  style?: React.CSSProperties | undefined
  onChapterClicked: (index: number, subIndex: number) => void
}

export const ContentTable = ({
  contentTableTitle,
  chapters,
  active,
  style,
  onChapterClicked,
}: ContentTableProps) => {
  const [open, setOpen] = React.useState(window.innerWidth > 700)
  const [height, setHeight] = React.useState<number | undefined>(open ? undefined : 0)
  const collapseRef = React.useRef<HTMLUListElement>(null)

  useAnimateHeight(open, setHeight, collapseRef)

  return (
    <div className='content-table' style={style}>
      <Heading size='m' level={2} className='content-table__title'>
        <span>{contentTableTitle}</span>{' '}
      </Heading>
      <div className='content-table__content'>
        <ul ref={collapseRef}>
          {/* <li className='content-table__search-wrapper'> // Vet ikke hva denne skal gjøre
            <div className='content-table__search'>
              <input />
              <SearchIcon />
            </div>
          </li> */}
          {chapters &&
            chapters.map((chapter, i) => (
              <Chapter
                index={i}
                key={i}
                title={chapter.title}
                active={active}
                chapters={chapter.subChapters!}
                onChapterClicked={onChapterClicked}
              />
            ))}
        </ul>
      </div>
    </div>
  )
}

const Chapter = ({
  index,
  title,
  active,
  chapters,
  onChapterClicked,
}: {
  index: number
  title: string
  active: { index: number; subindex: number }
  chapters: subChapter[] | null | undefined
  onChapterClicked: (index: number, subIndex: number) => void
}) => {
  const [open, setOpen] = React.useState(active.index === index)
  const [height, setHeight] = React.useState<number | undefined>(open ? undefined : 0)
  const collapseRef = React.useRef<HTMLUListElement>(null)

  useAnimateHeight(open, setHeight, collapseRef)

  useEffect(() => {
    if (index === active.index && active.subindex !== -1) {
      setOpen(true)
    } else if (index !== active.index) {
      setOpen(false)
    }
  }, [active, index])

  return (
    <li className='content-table__chapter'>
      <button
        className={cn(
          'content-table__chapter-header',
          {
            'content-table__active': index === active.index && active.subindex === -1,
          },
          'content-table__button',
        )}
        onClick={() => {
          onChapterClicked(index, -1)
          if (chapters && chapters.length > 0) setOpen(!open)
        }}
        aria-expanded={chapters && chapters.length > 0 ? open : undefined}
        aria-controls={chapters && chapters.length > 0 ? `subchapters-list-${index}` : undefined}
      >
        {title}
        {chapters && chapters.length > 0 && (
          <div className={cn('content-table__chevron-icon', { 'content-table__is-open': open })}>
            <ChevronDownIcon />
          </div>
        )}
      </button>
      {chapters && (
        <div id={`subchapters-list-${index}`} style={{ height }} className='content-table__subchapters'>
          <ul ref={collapseRef}>
            {chapters.map((chapter, i) => (
              <li
                key={i}
                className={cn('content-table__subchapter', {
                  'content-table__active': index === active.index && active.subindex === i,
                })}
              >
                <button
                  className={cn('content-table__button', {
                    'content-table__active': index === active.index && active.subindex === i,
                  })}
                  onClick={() => onChapterClicked(index, i)}
                >
                  {chapter.title}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  )
}
