import { ArticleCard } from 'components/elements/article-card/article-card'
import React from 'react'
import { CardList, CardListExternalProps } from '../card-list/card-list'

type ArticleListProps = CardListExternalProps & {
  articles: React.ComponentProps<typeof ArticleCard>[]
}

export const ArticleList = ({ title, articles, button, grid }: ArticleListProps) => (
  <CardList
    data={articles}
    title={title}
    button={button}
    renderItem={(item) => <ArticleCard {...item} />}
    grid={grid}
  />
)
