import React from 'react'
import './_media-block.scss'
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg'
import { Image, Video } from 'types'
import { ImageBox } from 'components/blocks/image-box/image-box'

interface MediaBlockProps {
  image?: Image
  video?: Video
  imagetext: string
  photographerLabel?: string
}

export const MediaBlock = ({ image, video, imagetext, photographerLabel }: MediaBlockProps) => {
  return (
    <div className='media-block'>
      <div>
        {video ? (
          <iframe title={video.title} className='media-block__video' src={video.url} />
        ) : (
          image && <img className='media-block__image' src={image.url} alt={image.alt} />
        )}
        <div className='media-block__text'>
          <ArrowUpIcon className='media-block__icon' />
          {`${imagetext ?? image?.alt ?? ''} ${image?.photographer ? `${photographerLabel ?? 'Foto: '}${image.photographer}`: ''}`}
        </div>
      </div>
    </div>
  )
}
