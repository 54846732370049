import React from 'react'
import './_instructor-card.scss'
import { ReactComponent as CrossCircle } from 'assets/icons/cross-circle.svg'
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg'
import { instructorInfoType } from 'types'
import cn from 'classnames'
import { useColor } from 'context/themeContext'

interface InstructorCardProps {
  instructorInfo: instructorInfoType
  companyNameLabel: string
  companyAddressLabel: string
  countyLabel: string
  phoneLabel: string
  emailLabel: string
  certificateNumberLabel: string
  isValidStatusLabel: string
  isValidLabel: string
  isInvalidLabel: string
}

export const InstructorCard = ({
  instructorInfo,
  companyNameLabel,
  companyAddressLabel,
  countyLabel,
  phoneLabel,
  emailLabel,
  certificateNumberLabel,
  isValidStatusLabel,
  isValidLabel,
  isInvalidLabel,
}: InstructorCardProps) => {
  const { color } = useColor()
  const certificateString = instructorInfo.isValid ? isValidLabel : isInvalidLabel
  const certificateIcon = instructorInfo.isValid ? (
    <CheckCircle className='instructor-card__valid-icon' />
  ) : (
    <CrossCircle className='instructor-card__unvalid-icon' />
  )

  const personalInfoFields = [
    { key: companyNameLabel, value: instructorInfo.companyName },
    { key: companyAddressLabel, value: instructorInfo.companyAddress },
    { key: countyLabel, value: instructorInfo.county },
    { key: phoneLabel, value: instructorInfo.phone, url: instructorInfo.phoneUrl },
    { key: emailLabel, value: instructorInfo.email, url: instructorInfo.emailUrl },
  ]

  const certificateFields = [
    {
      key: certificateNumberLabel,
      value: (
        <div className='instructor-card__certificate-field-value'>
          <p>{instructorInfo.certificateNumber}</p>
        </div>
      ),
    },
    {
      key: isValidStatusLabel,
      value: (
        <div className='instructor-card__certificate-field-value'>
          <p>{certificateString}</p>
          <div className='instructor-card__certificate-icon-box'>{certificateIcon}</div>
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className={cn('instructor-card', { [`instructor-card__color-${color}`]: color })}>
        <div className='instructor-card__personal-info'>
          <h2 className='instructor-card__name'>{instructorInfo.name}</h2>
          {personalInfoFields.map((field, index) => {
            return (
              <div key={index} className='instructor-card__personal-info-field'>
                <span className='instructor-card__field-name'>{field.key}:</span>
                <span className='instructor-card__field-value'>
                  {field.url ? <a href={field.url}>{field.value}</a> : field.value}
                </span>
              </div>
            )
          })}
        </div>
        <div
          className={cn('instructor-card__certificate-info', {
            [`instructor-card__certificate-info__color-${color}`]: color,
          })}
        >
          {certificateFields.map((field, index) => {
            return (
              <div key={index} className='instructor-card__certificate-info-field'>
                <p className='instructor-card__certificate-field-name'>{field.key}:</p>
                {field.value}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
