import React from 'react'
import { Layout } from '../../components/layout/layout'
import { ComponentList } from '../../components/blocks/component-list/component-list'
import { Header } from 'components/layout/header'
import { pageProps } from 'types'
import './_article-page.scss'
import { MetaInfo } from 'components/elements/meta-info/meta-info'

interface ArticlePageProps extends pageProps {
  metaInfo: React.ComponentProps<typeof MetaInfo>
  ingress: string
}

export const ArticlePage = ({ layout, header, blocks, metaInfo, ingress }: ArticlePageProps) => (
  <Layout
    {...layout}
    theme={layout.theme ?? { color: 'orange', type: 'gradient' }}
    className='article-page'
  >
    {header && <Header {...header} />}

    <div className='article-page__header'>
      {ingress && <p className='article-page__ingress'>{ingress}</p>}
      {metaInfo && (
        <div className='article-page__meta-info'>
          <MetaInfo {...metaInfo} />
        </div>
      )}
    </div>
    {blocks && <ComponentList blocks={blocks} />}
  </Layout>
)
