import React from 'react'
import './_shoppingcart-feedback.scss'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import cn from 'classnames'
import { Link } from 'types'
export interface ShoppingcartFeedbackProps {
  shoppingCart: Link
  checkout: Link
  textFront: string
  textBack: string
}

export const ShoppingcartFeedback = ({
  shoppingCart,
  checkout,
  textFront,
  textBack,
}: ShoppingcartFeedbackProps) => {
  const [feedbackIsVisible, setfeedbackIsVisible] = React.useState(true)

  return (
    <div
      className={cn('shoppingcart-feedback', {
        'shoppingcart-feedback--hide-header': !feedbackIsVisible,
      })}
    >
      <div className='shoppingcart-feedback__content'>
        {textFront} <a href={shoppingCart.url}> handlekurven</a> {textBack}
        <a href={checkout.url}> kassen.</a>
      </div>
      <button onClick={() => setfeedbackIsVisible(false)} className='shoppingcart-feedback__button'>
        <CloseIcon className='shoppingcart-feedback__icon' />
      </button>
    </div>
  )
}
