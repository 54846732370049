import { ComponentList } from 'components/blocks/component-list/component-list'
import { ShortcutsBlock } from 'components/blocks/shortcuts-block/shortcuts-block'
import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import React from 'react'
import { pageProps } from 'types'
import './OFASPage.scss'
interface OFASPageProps extends pageProps {
  shortcuts: React.ComponentProps<typeof ShortcutsBlock>
}

export const OFASPage = ({ layout, header, shortcuts, blocks }: OFASPageProps) => (
  <Layout {...layout} theme={layout.theme ?? {color: 'red', type: 'solid'}} className='OFAS-page'>
    {header && <Header {...header} />}
    {shortcuts && <ShortcutsBlock {...shortcuts} />}
    {blocks && <ComponentList blocks={blocks} />}
  </Layout>
)
