import React, { useEffect, useState } from 'react'
import { ReactComponent as PlusIcon } from 'assets/icons/plus-circle.svg'
import { ReactComponent as MinusIcon } from 'assets/icons/minus-circle.svg'
import './_quantity-counter.scss'
import { useShoppingCart } from 'context/shoppingCartContext'
import { useColor } from 'context/themeContext'
import cn from "classnames"

interface QuantityCounterProps {
  initQuantity: number
  productId: number
  editProductQuantityEndpoint: string
  removeProductEndpoint: string
}

export const QuantityCounter = ({
  initQuantity,
  productId,
  editProductQuantityEndpoint,
  removeProductEndpoint,
}: QuantityCounterProps) => {
  const [quantity, setQuantity] = useState(initQuantity)
  const { editProductQuantity, maxProductAmount } = useShoppingCart()
  const { color } = useColor()
  const colorStyle = color === 'green' ? 'green' : 'blue'

  useEffect(() => {
    editProductQuantity(productId, quantity, editProductQuantityEndpoint, removeProductEndpoint)
  }, [quantity])

  function increment(prevQuantity: number) {
    if (quantity < maxProductAmount) {
      setQuantity(prevQuantity + 1)
    }
  }

  function decrement(prevQuantity: number) {
    if (quantity !== 0) {
      setQuantity(prevQuantity - 1)
    }
  }

  return (
    <div className={cn('quantity-counter', `quantity-counter__${colorStyle}`)}>
      <button className='quantity-counter__counter' onClick={() => decrement(quantity)}>
        <MinusIcon />
      </button>
      <div className='quantity-counter__quantity'>
        <p>{quantity}</p>
      </div>
      <button className='quantity-counter__counter' onClick={() => increment(quantity)}>
        <PlusIcon />
      </button>
    </div>
  )
}
