import cn from 'classnames'
import React, { forwardRef, useImperativeHandle, Ref, useState, useEffect, MouseEventHandler, useRef } from 'react'
import { Colors } from 'types'
import './_menu-dropdown.scss'

export interface MenuDropdownFuncs {
  toggle(): void
  closeMenuDropdown(): void
}

interface MenuDropDownProps {
  id?: string
  initialState?: boolean
  linkItems: {
    label: string
    url: string
  }[]
  color: Colors
}

export const MenuDropdown = forwardRef<MenuDropdownFuncs, MenuDropDownProps>(
  ({ id, initialState, linkItems, color}: MenuDropDownProps, ref) => {
    const [open, setOpen] = useState<boolean>(initialState ?? false)

    useImperativeHandle(ref, () => ({
      toggle() {
        setOpen(!open)
      },
      closeMenuDropdown() {
        setOpen(false)
      }
    }))

    return open ? (
      <div id={id}>
        <ul className={cn('menu-dropdown', `menu-dropdown__color-${color}`)}>
          {linkItems.map((linkItem, index) => (
            <li
              key={index}
              className={cn('menu-dropdown__menu-item', `menu-dropdown__menu-item__color-${color}`)}
            >
              <a href={linkItem.url}>{linkItem.label}</a>
            </li>
          ))}
        </ul>
      </div>
    ) : null
  },
)
