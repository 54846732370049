import { GraphicalHeader } from 'components/layout/header/graphical/graphical-header'
import React from 'react'
import { Block } from 'types'
import { ArticleHeader } from './article-header/article-header'
import { ImageHeader } from './image/image-header'
import { OFASHeader } from './OFAS-header/OFAS-header'
import { PromoHeader } from './promo/promo-header'
import { SimpleHeader } from './simple/simple-header'

interface HeaderProps extends Block {}

// Add allowed headers here
const Headers: { [key: string]: any } = {
  SimpleHeader: SimpleHeader,
  GraphicalHeader: GraphicalHeader,
  ImageHeader: ImageHeader,
  PromoHeader: PromoHeader,
  ArticleHeader: ArticleHeader,
  OFASHeader: OFASHeader,
}

// TODO: Spacing between items

export const Header = ({ name, data }: HeaderProps) => (
  <div className='header'>
    {Headers[name] === undefined ? (
      <div>
        header <b>'{name}'</b> is not supported
      </div>
    ) : (
      React.createElement(Headers[name], {
        ...data,
      })
    )}
  </div>
)
