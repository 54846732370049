import React from 'react'
import './_media-parallax.scss'
import { Image, Video } from 'types'
import { ImageBox } from 'components/blocks/image-box/image-box'

interface MediaParallaxProps {
  image?: Image
  video?: Video
  imageText: string
}

export const MediaParallax = ({ image, imageText }: MediaParallaxProps) => {
  return (
    <div className='media-parallax'>
      <div>
        {image && (
          <>
            <div className='media-parallax__wrapper'>
              <div
                className='media-parallax__image'
                style={{
                  backgroundImage: 'url(' + image.url + ')',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
            </div>
            <div className='media-parallax__image-box'>
              <div className='media-parallax__image-box-content'>
                <ImageBox text={imageText} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
