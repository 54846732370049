import React from 'react'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import { ReactComponent as FileIcon } from 'assets/icons/file-text.svg'
import cn from 'classnames'
import './_info-link.scss'
import { Colors } from 'types'
import { Heading } from '../heading/heading'
import { useColor } from 'context/themeContext'

export interface InfoLinkProps {
  url: string
  title: string
  extra?: { text: string; time: string }
  className?: string
}

export const InfoLink = ({ url, title, extra, className }: InfoLinkProps) => {
  const { color } = useColor()

  return (
    <a
      href={url}
      className={cn('info-link', className, {
        [`info-link__color-${color}`]: color,
        'info-link__smalltext': extra,
      })}
    >
      <div className='info-link__content'>
        {extra ? (
          <>
            <Heading className='info-link__heading' level={2} size='xs'>
              {title}
            </Heading>
            <div className='info-link__extra'>
              {extra.text ?
              <div className='info-link__extra-content'>
                <FileIcon className='info-link__extra-icon' />
                {extra.text}
              </div> : null}
              {extra.time ?
              <div className='info-link__extra-content'>
                <TimeIcon className='info-link__extra-icon' />
                {extra.time}
              </div> : null}
            </div>
          </>
        ) : (
          <Heading className='info-link__heading' level={2} size='s'>
            {title}
          </Heading>
        )}
      </div>
      <ArrowRightIcon className='info-link__icon' />
    </a>
  )
}
