import React, { useEffect } from 'react'
import { Layout } from '../../components/layout/layout'
import { ComponentList } from '../../components/blocks/component-list/component-list'
import { Block, pageProps } from 'types'
import './_digital-manual-page.scss'
import { Header } from 'components/layout/header'
import { Heading } from 'components/elements/heading/heading'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { ContentTable } from 'components/blocks/content-table/content-table'
import { MetaInfo } from 'components/elements/meta-info/meta-info'
import useScrollListener from 'hooks/useScrollListener.hook'

export type chapter = {
  title: string
  content?: Block[]
  subChapters?: subChapter[]
}
export type subChapter = {
  title: string
  content?: Block[]
}
interface DigitalManualPageProps extends Omit<pageProps, 'blocks'> {
  contentTableTitle: string
  nextPageLabel: string
  previousPageLabel: string
  chapters: chapter[]
  metainfo: React.ComponentProps<typeof MetaInfo>
}

export const DigitalManualPage = ({
  layout,
  header,
  contentTableTitle,
  nextPageLabel,
  previousPageLabel,
  chapters,
  metainfo,
}: DigitalManualPageProps) => {
  const [{ index, subindex }, setIndex] = React.useState<{ index: number; subindex: number }>({
    index: 0,
    subindex: -1,
  })
  const [{ title, content }, setCurrentChapter] = React.useState<chapter>(chapters[0])

  const [lastY, setLastY] = React.useState<number>(0)
  const [top, setTop] = React.useState<'24px' | '132px'>('132px')
  const scrollData = useScrollListener()

  const nextChapter = () => {
    if (chapters[index].subChapters && chapters[index].subChapters!.length - 1 > subindex) {
      setIndex({ index: index, subindex: subindex + 1 })
    } else if (chapters[index + 1]) {
      setIndex({ index: index + 1, subindex: -1 })
    }
    window.scrollTo(0, 0)
  }

  const prevChapter = () => {
    if (chapters[index].subChapters && chapters[index].subChapters!.length >= 0 && subindex > -1) {
      setIndex({ index: index, subindex: subindex - 1 })
    } else if (index > 0) {
      setIndex({
        index: index - 1,
        subindex: chapters[index - 1].subChapters
          ? chapters[index - 1].subChapters!.length - 1
          : -1,
      })
    }
    window.scrollTo(0, 0)
  }

  const updateCurrentChapter = (index: number, subIndex: number) => {
    if (subIndex === -1) {
      setCurrentChapter(chapters[index])
    } else if (chapters[index].subChapters) {
      setCurrentChapter(chapters[index].subChapters![subIndex])
    }
  }

  const onChapterClicked = (index: number, subIndex: number) => {
    setIndex({ index: index, subindex: subIndex })
    updateCurrentChapter(index, subindex)
  }

  useEffect(() => {
    updateCurrentChapter(index, subindex)
  }, [chapters, index, subindex])

  React.useEffect(() => {
    if (lastY + 200 > scrollData.y && scrollData.lastY < scrollData.y && top === '24px') {
      // scrolled down less than 200px

      setTop('132px')
    } else if (lastY + 200 <= scrollData.y && scrollData.lastY < scrollData.y && top === '132px') {
      // scrolled down more than 200px

      setTop('24px')
    } else if (scrollData.lastY > scrollData.y) {
      // scrolled up

      if (top === '24px') {
        // hide header if its visible

        setTop('132px')
      }
      if (scrollData.y !== lastY) {
        // update header y if it has changed

        setLastY(scrollData.y)
      }
    }
  }, [scrollData, lastY, top])

  return (
    <Layout
      {...layout}
      theme={layout.theme ?? { color: 'orange', type: 'solid' }}
      className='digital-manual-page'
    >
      {header && <Header {...{ ...header, data: { ...header.data, background: 'yellow' } }} />}
      {chapters && (
        <div className='digital-manual-page__content'>
          <ContentTable
            onChapterClicked={onChapterClicked}
            contentTableTitle={contentTableTitle}
            chapters={chapters}
            active={{ index, subindex }}
            style={{ top }}
          />
          <MetaInfo {...metainfo} />
          <Heading level={2} size='l'>
            {title}
          </Heading>

          {content && <ComponentList blocks={content} />}
          <div className='digital-manual-page__buttons'>
            <button onClick={() => prevChapter()} disabled={index === 0 && subindex === -1}>
              <ArrowLeftIcon />
              <span>{previousPageLabel}</span>
            </button>
            <button
              onClick={() => nextChapter()}
              disabled={
                index === chapters.length - 1 &&
                (!chapters[index].subChapters ||
                  subindex === chapters[index].subChapters!.length - 1)
              }
            >
              <span>{nextPageLabel}</span>
              <ArrowRightIcon />
            </button>
          </div>
        </div>
      )}
    </Layout>
  )
}
