import React from 'react'
import './_search.scss'
import { Button } from '../button/button'
import { Colors } from 'types'
import { ReactComponent as CrossIcon } from 'assets/icons/close.svg'
import cn from 'classnames'
import { useColor } from 'context/themeContext'

export interface SearchProps {
  search: () => void
  className?: string
  clearButtonAriaLabel?: string
  searchButtonText: string,
  value?: string
  placeholder: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  resetSearchString: () => void
}
export const Search = React.forwardRef(
  (
    { clearButtonAriaLabel, searchButtonText, search, value, placeholder, onChange, resetSearchString }: SearchProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const { color } = useColor()

    return (
      <div className={cn('search', { [`search__color-${color}`]: color })}>
        <input
          className={cn('search__input', { [`search__color-${color}__input`]: color })}
          placeholder={placeholder}
          value={value ?? ''}
          onChange={onChange}
          onKeyPress={(e) => {
            if (e.code === 'Enter') {
              search()
            }
          }}
          ref={ref}
        ></input>
        <button aria-label={clearButtonAriaLabel} className='search__remove-button'>
          <CrossIcon
            className={cn('search__cross-icon', { [`search__color-${color}`]: color })}
            onClick={resetSearchString}
          />
        </button>
        <Button className='search__button' color={color === 'white' ? 'orange' : color} text={searchButtonText} onClick={() => search()} />
      </div>
    )
  },
)
