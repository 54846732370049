import React, { createContext, useContext, useState } from 'react'

type HeaderContextType = {
  showHeader: boolean
  setShowHeader: React.Dispatch<React.SetStateAction<boolean>>
}

const initialHeaderContext: HeaderContextType = {
  showHeader: true,
  setShowHeader: () => {},
}

export const HeaderContext = createContext<HeaderContextType>(initialHeaderContext)

interface HeaderContextProviderProps {
  children: React.ReactNode
}

export const HeaderContextProvider = ({ children }: HeaderContextProviderProps) => {
  const [showHeader, setShowHeader] = useState<boolean>(true)

  return (
    <HeaderContext.Provider
      value={{
        showHeader,
        setShowHeader,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeader = () => {
  const header = useContext(HeaderContext)
  if (header === undefined) throw new Error('useHeader was used outside of its Provider')
  return header
}
