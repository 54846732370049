import React from 'react'
import './_article-card.scss'
import cn from 'classnames'

import { Image } from 'types'
import { Heading } from '../heading/heading'
import { useColor } from 'context/themeContext'
import { Tag } from 'components/elements/tag/tag'

export interface ArticleCardProps {
  url?: string
  image?: Image
  date?: string
  meta?: { published?: string }
  title?: string
  tag?: React.ComponentProps<typeof Tag>
}

export const ArticleCard = ({ image, url, date, title, tag, meta }: ArticleCardProps) => {
  const { color } = useColor()
  return (
    <div className='article-card'>
      <a href={url} className='article-card__link'>
        {image && image.url ? (
          <div className='article-card__image-container'>
            <div className={cn('article-card__image', `article-card__image-${color}`)}>
              <img src={image.url} alt={image.alt} />
            </div>
          </div>
        ) : <div className='article-card__default-image-container'>
          </div>}
        <div
          className={cn(
            'article-card__date',
            `article-card__date-${
              color === 'blue' || color === 'red' || color === 'green' ? color : 'default'
            }`,
          )}
        >
          <div className='article-card__date-tag-row'>
            {date ? date : meta?.published}
            {tag && (
              <Tag
                tag={tag.tag}
                color={color === 'blue' || color === 'red' || color === 'green' ? color : 'default'}
                background={tag.background}
                icon={tag.icon}
              />
            )}
          </div>
        </div>
        <Heading
          size='s'
          level={3}
          className={cn('article-card__title', `article-card__title-${color}`)}
        >
          <span className='article-card__title-span'>{title}</span>
        </Heading>
      </a>
    </div>
  )
}
