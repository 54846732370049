import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { ReactComponent as FireIcon } from 'assets/icons/fire.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg'
import { ReactComponent as NewspaperIcon } from 'assets/icons/newspaper.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'
import { ReactComponent as ShoppingcartIcon } from 'assets/icons/shoppingcart.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg'
import { ReactComponent as LogoText } from 'assets/logo/logo-without-illustration.svg'

import cn from 'classnames'
import useAnimateHeight from '../../../hooks/useAnimateHeight.hook'
import './_menu.scss'
import useScrollListener from '../../../hooks/useScrollListener.hook'
import { Search } from '../../elements/search/search'
import { Button } from 'components/elements/button/button'
import { Link, productType } from 'types'
import { useShoppingCart } from 'context/shoppingCartContext'
import { useUser } from 'context/userContext'
import { Logo } from 'components/elements/logo/logo'
import { MenuDropdown, MenuDropdownFuncs } from 'components/elements/menu-dropdown/menu-dropdown'
import { ReactComponent as LogoIcon } from 'assets/logo/bvf-logo.svg'
import { useHeader } from 'context/headerContext'

export interface MenuFuncs {
  closeExpandables(): void
}

interface MenuProps {
  logo: {
    ariaLabel: string
    url: string
  }
  signin?: Link
  ecommerce?: Link
  search?: {
    text: string
    placeholder: string
    searchPageUrl: string
    enpointUrl: string
  }
  profileMenuDropDownItems: {
    label: string
    url: string
  }[]
  closeButtonLabel: string
  openButtonLabel: string
  expandedAriaLabel: string
  itemsInShoppingCartAriaLabel: string
  clearSearchTextAriaLabel: string
  groups: {
    icon?: 'fire' | 'check' | 'newspaper' | 'home' | 'calendar'
    title: string
    url: string
    links: Link[]
  }[]
  hasAnimatedLogo?: boolean
}

function useOutsideAlerter(ref: any, buttonRef: any, func: () => void) {
  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        func()
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [ref])
}

export const Menu = forwardRef<MenuFuncs, MenuProps>(
  (
    {
      logo,
      signin,
      ecommerce,
      search,
      profileMenuDropDownItems,
      expandedAriaLabel,
      closeButtonLabel,
      openButtonLabel,
      itemsInShoppingCartAriaLabel,
      clearSearchTextAriaLabel,
      groups,
      hasAnimatedLogo,
    }: MenuProps,
    ref,
  ) => {
    const { showHeader, setShowHeader } = useHeader()

    const { productsCount } = useShoppingCart()
    const [menuIsOpen, setMenuIsOpen] = React.useState(false)
    const [searchIsOpen, setSearchIsOpen] = React.useState(false)
    const [userOperationsIsOpen, setUserOperationsIsOpen] = React.useState(false)
    const [lastHeaderY, setLastHeaderY] = React.useState<number>(0)
    const menuDropDownRef = useRef<MenuDropdownFuncs>(null)

    const { user } = useUser()

    const [height, setHeight] = React.useState<number | undefined>(menuIsOpen ? undefined : 0)
    const [searchHeight, setSearchHeight] = React.useState<number | undefined>(
      searchIsOpen ? undefined : 0,
    )

    const [searchQuery, setSearchQuery] = React.useState<string | undefined>()

    const collapseRef = React.useRef<HTMLUListElement>(null)
    const searchRef = React.useRef<HTMLDivElement>(null)
    const inputRef = React.useRef<HTMLInputElement>(null)

    useAnimateHeight(menuIsOpen, setHeight, collapseRef)
    useAnimateHeight(searchIsOpen, setSearchHeight, searchRef)

    const scrollData = useScrollListener()

    const menuDropDownContainerRef = useRef(null)
    const menuDropDownContainerButtonRef = useRef(null)
    useOutsideAlerter(menuDropDownContainerRef, menuDropDownContainerButtonRef, () => {
      menuDropDownRef.current?.closeMenuDropdown()
      setUserOperationsIsOpen(false)
    })

    useImperativeHandle(ref, () => ({
      closeExpandables() {
        setMenuIsOpen(false)
        setUserOperationsIsOpen(false)
        menuDropDownRef.current?.closeMenuDropdown()
        setSearchIsOpen(false)
      },
    }))

    const toggleMenu = (type: 'menu' | 'search') => {
      if (menuIsOpen && type === 'search') {
        setMenuIsOpen(false)
        setSearchIsOpen(true)
      } else if (menuIsOpen || searchIsOpen) {
        setMenuIsOpen(false)
        setSearchIsOpen(false)
      } else if (type === 'menu') {
        setMenuIsOpen(!menuIsOpen)
      } else if (type === 'search') {
        setSearchIsOpen(!searchIsOpen)
      }
      console.log(type)

      if (type === 'search' && !searchIsOpen) {
        setTimeout(() => {
          inputRef.current?.focus()
        }, 0.1)
      }
    }

    const searchfn = () => {
      if (searchQuery) {
        window.location.href = search?.searchPageUrl + '?query=' + searchQuery
      }
    }

    const resetSearchString = () => {
      setSearchQuery('')
    }

    React.useEffect(() => {
      if (menuIsOpen || searchIsOpen) return
      if (lastHeaderY + 200 > scrollData.y && scrollData.lastY < scrollData.y && !showHeader) {
        // scrolled down less than 200px

        setShowHeader(true)
      } else if (
        lastHeaderY + 200 <= scrollData.y &&
        scrollData.lastY < scrollData.y &&
        showHeader
      ) {
        // scrolled down more than 200px

        setShowHeader(false)
        menuDropDownRef.current?.closeMenuDropdown()
        setUserOperationsIsOpen(false)
      } else if (scrollData.lastY > scrollData.y) {
        // scrolled up

        if (!showHeader) {
          // hide header if its visible

          setShowHeader(true)
        }
        if (scrollData.y !== lastHeaderY) {
          // update header y if it has changed

          setLastHeaderY(scrollData.y)
        }
      }
    }, [scrollData, lastHeaderY, menuIsOpen, searchIsOpen])

    return (
      <nav
        className={cn('menu', {
          'menu--is-open-color': menuIsOpen,
          'menu--is-open': menuIsOpen || searchIsOpen,
          'menu--hide-header': !showHeader,
        })}
      >
        <div className='menu__header'>
          <a className='menu__logo' href={logo.url} aria-label={logo.ariaLabel}>
            {hasAnimatedLogo ? (
              <React.Fragment>
                <Logo loop={0} />
                <span className='menu__logo-text'>
                  <LogoText />
                </span>
              </React.Fragment>
            ) : (
              <span className='menu__logo-not-animated'>
                <LogoIcon />
              </span>
            )}
          </a>
          <div className='menu__bar'>
            {user ? (
              <div ref={menuDropDownContainerRef}>
                <button
                  id='open-profile-operations-button'
                  type='button'
                  className='menu__link menu__button'
                  aria-expanded={userOperationsIsOpen}
                  aria-controls='user-operations'
                  ref={menuDropDownContainerButtonRef}
                  onClick={() => {
                    setUserOperationsIsOpen(!userOperationsIsOpen)
                    menuDropDownRef.current?.toggle()
                  }}
                >
                  <ProfileIcon className='menu__linkicon' />
                  {user.firstname}
                </button>
                <MenuDropdown
                  id='user-operations'
                  color={menuIsOpen ? 'green' : 'dark-red'}
                  linkItems={profileMenuDropDownItems}
                  ref={menuDropDownRef}
                />
              </div>
            ) : (
              <a href={signin?.url} className='menu__link'>
                <ProfileIcon className='menu__linkicon' aria-hidden='true' />
                {signin?.text}
              </a>
            )}
            <a href={ecommerce?.url} className='menu__link'>
              <div>
                <ShoppingcartIcon
                  aria-label={itemsInShoppingCartAriaLabel}
                  className='menu__linkicon'
                />
                {productsCount !== 0 && <span>{productsCount}</span>}
              </div>
              {ecommerce?.text}
            </a>
            <Button
              onClick={() => toggleMenu('search')}
              className='menu__searchbutton'
              text={search?.text}
              ariaExpanded={searchIsOpen}
              ariaControls={'menu__search-content-id'}
            ></Button>
            <button
              aria-label={menuIsOpen ? closeButtonLabel : openButtonLabel}
              aria-expanded={menuIsOpen}
              aria-controls='menu__content-id'
              onClick={() => toggleMenu('menu')}
              className='menu__hamburger-button'
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
        <div
          id='menu__search-content-id'
          className={cn('menu__search-content', { 'menu__search-content__hide': !searchIsOpen })}
          style={{ height: searchHeight }}
        >
          <div className='menu__search' ref={searchRef}>
            <Search
              searchButtonText={search?.text || ''}
              clearButtonAriaLabel={clearSearchTextAriaLabel}
              search={searchfn}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              ref={inputRef}
              placeholder={search === undefined ? '' : search?.placeholder}
              resetSearchString={resetSearchString}
            />
          </div>
        </div>
        {groups && groups.length !== 0 && (
          <div
            id='menu__content-id'
            className={cn('menu__content', { menu__content__hide: !menuIsOpen })}
            style={{ height }}
          >
            <ul className='menu__groups' ref={collapseRef} aria-label={expandedAriaLabel}>
              <li className='menu__mobile'>
                <Search
                  searchButtonText={search?.text || ''}
                  clearButtonAriaLabel={clearSearchTextAriaLabel}
                  className='menu__mobile-search'
                  search={searchfn}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={search === undefined ? '' : search?.placeholder}
                  resetSearchString={resetSearchString}
                />
                <div className='menu__mobile-links'>
                  <a href={ecommerce?.url} className='menu__mobile-link'>
                    <div>
                      <ShoppingcartIcon className='menu__linkicon' />
                      {productsCount !== 0 && <span>{productsCount}</span>}
                    </div>
                    {ecommerce?.text}
                  </a>
                  {user ? (
                    <div ref={menuDropDownContainerRef}>
                      <button
                        aria-expanded={userOperationsIsOpen}
                        aria-controls='user-operations-options'
                        onClick={() => setUserOperationsIsOpen(!userOperationsIsOpen)}
                        className='menu__mobile-link menu__button'
                      >
                        <ProfileIcon className='menu__mobile-linkicon' />
                        {user.firstname}
                      </button>
                      <ul
                        hidden={!userOperationsIsOpen}
                        id='user-operations-options'
                        className={cn('menu__group-links')}
                      >
                        {profileMenuDropDownItems.map((linkItem) => (
                          <li className='menu__group-link-container' key={linkItem.label}>
                            <ArrowRightIcon />
                            <a className='menu__group-link' href={linkItem.url}>
                              <span>{linkItem.label}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <a href={signin?.url} className='menu__mobile-link'>
                      <ProfileIcon className='menu__mobile-linkicon' />
                      {signin?.text}
                    </a>
                  )}
                </div>
              </li>
              {groups.map((group) => (
                <li className='menu__group' key={group.title}>
                  <div className='menu__group-header'>
                    {group.icon && group.icon === 'fire' ? (
                      <FireIcon aria-hidden='true' />
                    ) : group.icon === 'check' ? (
                      <CheckIcon aria-hidden='true' />
                    ) : group.icon === 'newspaper' ? (
                      <NewspaperIcon aria-hidden='true' />
                    ) : group.icon === 'home' ? (
                      <HomeIcon aria-hidden='true' />
                    ) : group.icon === 'calendar' ? (
                      <CalendarIcon aria-hidden='true' />
                    ) : null}
                    <h2 id={group.title} className='menu__group-title'>
                      <a href={group.url}>{group.title}</a>
                    </h2>
                  </div>
                  {group.links && group.links.length !== 0 && (
                    <div>
                      <ul aria-labelledby={group.title} className='menu__group-links'>
                        {group.links.map((link) => (
                          <li className='menu__group-link-container' key={link.text}>
                            <ArrowRightIcon aria-hidden='true' />
                            <a className='menu__group-link' href={link.url}>
                              <span>{link.text}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </nav>
    )
  },
)
