import React from 'react'
import { Block } from 'types'
import cn from 'classnames'
import { ArticleList } from '../article-list/article-list'
import { EntranceLinks } from '../entrance-links/entrance-links'
import { InfoBlock } from '../info-block/info-block'
import { LinkList } from '../link-list/link-list'
import { NewsArticleBlock } from '../news-article-block/news-article-block'
import { PromoBlock } from '../promo-block/promo-block'
import { Quote } from '../quote/quote'
import { RichText } from '../rich-text/rich-text'
import { ShortcutsBlock } from '../shortcuts-block/shortcuts-block'
import { InfographicBlock } from 'components/blocks/infographic-block/infographic-block'
import { ImportantInfoBlock } from '../important-info-block/important-info-block'
import { Margin } from '../margin/margin'
import { MediaBlock } from '../media-block/media-block'
import { SignupBlock } from '../signup-block/signup-block'
import { CourseInstructors } from '../course-instructors/course-instructors'
import { List } from '../list/list'
import { Accordion } from '../accordion/accordion'
import { Form } from '../form/form'
import { EmployeeList } from '../employee-list/employee-list'
import { MetaInfo } from 'components/elements/meta-info/meta-info'
import { ProductPageList } from '../product-page-list/product-page-list'
import { ImageBox } from '../image-box/image-box'
import { MediaParallax } from '../media-parallax/media-parallax'

interface ComponentListProps {
  blocks: Block[]
}

// Add allowed components here
const Components: { [key: string]: any } = {
  RichText: RichText,
  ShortcutsBlock: ShortcutsBlock,
  Quote: Quote,
  PromoBlock: PromoBlock,
  LinkList: LinkList,
  EntranceLinks: EntranceLinks,
  NewsArticleBlock: NewsArticleBlock,
  ArticleList: ArticleList,
  ProductPageList: ProductPageList,
  InfoBlock: InfoBlock,
  InfographicBlock: InfographicBlock,
  ImportantInfoBlock: ImportantInfoBlock,
  Margin: Margin,
  MediaBlock: MediaBlock,
  SignupBlock: SignupBlock,
  CourseInstructors: CourseInstructors,
  List: List,
  Accordion: Accordion,
  Form: Form,
  EmployeeList: EmployeeList,
  MetaInfo: MetaInfo,
  ImageBox: ImageBox,
  MediaParallax: MediaParallax,
}

// TODO: Spacing between items

export const ComponentList = ({ blocks }: ComponentListProps) => {
  let hasFullWidthChildren = blocks.some((block) => block['fullWidth'] === true)

  return (
    <div className='component-list__wrapper'>
      <div
        className={cn('component-list', {
          'component-list--not-full-width': !hasFullWidthChildren,
        })}
      >
        {blocks &&
          blocks.map((block, i) =>
            Components[block.name] === undefined ? (
              <div className='compontent-list--item' key={i}>
                Component <b>'{block.name}'</b> is not supported
              </div>
            ) : block.data && !hasFullWidthChildren && block.name !== 'Margin' ? (
              React.createElement(Components[block.name], {
                ...{ ...block.data, key: i },
              })
            ) : (
              block.data &&
              hasFullWidthChildren && (
                <div
                  className={cn('component-list--item', {
                    'component-list--item-full-width': block.fullWidth,
                  })}
                  key={i}
                >
                  {React.createElement(Components[block.name], {
                    ...{ ...block.data, key: i },
                  })}
                </div>
              )
            ),
          )}
      </div>
      <div className='sidebar'>
        {blocks &&
          blocks.map(
            (block, i) =>
              block.name === 'Margin' &&
              React.createElement(Components[block.name], {
                ...{ ...block.data, key: i },
              }),
          )}
      </div>
    </div>
  )
}
