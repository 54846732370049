import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Footer } from './components/layout/footer/footer'
import { Menu } from './components/layout/menu/menu'
import { RichText } from './components/blocks/rich-text/rich-text'
import { FrontPage } from './pages/frontPage/frontPage'
import { ShortcutsBlock } from './components/blocks/shortcuts-block/shortcuts-block'
import { Quote } from './components/blocks/quote/quote'
import { Button } from './components/elements/button/button'
import { Breadcrumbs } from './components/layout/breadcrumbs/breadcrumbs'
import { Search } from './components/elements/search/search'
import { PromoBlock } from './components/blocks/promo-block/promo-block'
import { LinkList } from './components/blocks/link-list/link-list'
import { ArticlePage } from './pages/articlePage/articlePage'
import { ContentPage } from './pages/contentPage/contentPage'
import { CoursePage } from './pages/coursePage/coursePage'
import { DigitalManualPage } from './pages/digitalManualPage/digitalManualPage'
import { LandingPage } from './pages/landingPage/landingPage'
import { ProductPage } from './pages/productPage/productPage'
import { SearchPage } from './pages/searchPage/searchPage'
import { ArticleList } from 'components/blocks/article-list/article-list'
import { EntranceLinks } from 'components/blocks/entrance-links/entrance-links'
import { ImportantInfoBlock } from 'components/blocks/important-info-block/important-info-block'
import { InfoBlock } from 'components/blocks/info-block/info-block'
import { InfographicBlock } from 'components/blocks/infographic-block/infographic-block'
import { NewsArticleBlock } from 'components/blocks/news-article-block/news-article-block'
import { InfoLink } from 'components/elements/info-link/info-link'
import { ShoppingcartFeedback } from 'components/elements/shoppingcart-feedback/shoppingcart-feedback'
import { Header } from 'components/layout/header'
import { ImageHeader } from 'components/layout/header/image/image-header'
import { ArticleHeader } from 'components/layout/header/article-header/article-header'
import { SimpleHeader } from 'components/layout/header/simple/simple-header'
import { CategoryPage } from 'pages/categoryPage/categoryPage'
import { MediaBlock } from 'components/blocks/media-block/media-block'
import { Margin } from 'components/blocks/margin/margin'
import { SearchCard } from 'components/elements/search-card/search-card'
import { SignupBlock } from 'components/blocks/signup-block/signup-block'
import { CourseInstructors } from 'components/blocks/course-instructors/course-instructors'
import { List } from 'components/blocks/list/list'
import { Form } from 'components/blocks/form/form'
import { Accordion } from 'components/blocks/accordion/accordion'
import { DropDown } from 'components/elements/dropdown/dropdown'
import { Filter } from 'components/blocks/filter/filter'
import { ProductPageList } from 'components/blocks/product-page-list/product-page-list'
import { OnlineStorePage } from 'pages/onlineStorePage/onlineStorePage'
import { FireSafetyMagazineLandingPage } from 'pages/fireSafetyMagazine/fireSafetyMagazineLandingPage/LandingPage'
import { FireSafetyMagazineArticlePage } from 'pages/fireSafetyMagazine/fireSafetyMagazineArticlePage/fireSafetyMagazineArticlePage'
import { ImageBox } from 'components/blocks/image-box/image-box'
import { MediaParallax } from 'components/blocks/media-parallax/media-parallax'
import { LoginPage } from 'pages/loginPage/loginPage'
import { ForgotPasswordPage } from 'pages/forgotPasswordPage/forgotPasswordPage'
import { VarmeArbeiderPage } from 'pages/varmeArbeiderPage/varmeArbeiderPage'
import { OFASPage } from 'pages/OFASPage/OFASPage'
import { OFASHeader } from 'components/layout/header/OFAS-header/OFAS-header'
import { OFASFooter } from 'components/layout/OFAS-footer/OFAS-footer'
import { OFASMenu } from 'components/layout/OFAS-menu/OFAS-menu'
import { ErrorPage } from 'pages/errorPage/errorPage'
import { ListPage } from 'pages/listPage/listPage'
import { CreateUserPage } from 'pages/createUserPage/createUserPage'
import { MyProfilePage } from 'pages/myProfilePage/myProfilePage'
import { CertificateSearchPage } from 'pages/certificateSeachPage/certificateSearchPage'
import { CheckoutPage } from 'pages/checkoutPage/checkoutPage'
import { ShoppingCartPage } from 'pages/shoppingCartPage/shoppingCartPage'
import { EmployeeListPage } from 'pages/employeeListPage.tsx/employee-list-page'
import { InstructorSearchPage } from 'pages/instructorSearchPage/instructor-search-page'
import { ProductListPage } from 'pages/productListPage/productListPage'
import { EmployeeList } from 'components/blocks/employee-list/employee-list'
import { CheckoutConfirmationPage } from 'pages/checkoutConfirmationPage/checkoutConfirmationPage'

// Add new components here
const ComponentTypes: { [key: string]: any } = {
  Footer: Footer,
  OFASFooter: OFASFooter,
  Menu: Menu,
  OFASMenu: OFASMenu,
  RichText: RichText,
  FrontPage: FrontPage,
  VarmeArbeiderPage: VarmeArbeiderPage,
  ArticlePage: ArticlePage,
  CategoryPage: CategoryPage,
  ContentPage: ContentPage,
  CoursePage: CoursePage,
  DigitalManualPage: DigitalManualPage,
  FireSafetyMagazineLandingPage: FireSafetyMagazineLandingPage,
  FireSafetyMagazineArticlePage: FireSafetyMagazineArticlePage,
  LandingPage: LandingPage,
  ProductPage: ProductPage,
  SearchPage: SearchPage,
  OnlineStorePage: OnlineStorePage,
  LoginPage: LoginPage,
  ForgotPasswordPage: ForgotPasswordPage,
  ShortcutsBlock: ShortcutsBlock,
  Header: Header,
  SimpleHeader: SimpleHeader,
  OFASHeader: OFASHeader,
  ImageHeader: ImageHeader,
  Quote: Quote,
  Button: Button,
  Breadcrumbs: Breadcrumbs,
  Search: Search,
  PromoBlock: PromoBlock,
  LinkList: LinkList,
  LinkListBlock: InfoLink,
  EntranceList: EntranceLinks,
  NewsArticleBlock: NewsArticleBlock,
  ArticleList: ArticleList,
  ProductPageList: ProductPageList,
  InfoBlock: InfoBlock,
  ShoppingcartFeedback: ShoppingcartFeedback,
  ImportantInfoBlock: ImportantInfoBlock,
  InfographicBlock: InfographicBlock,
  ArticleHeader: ArticleHeader,
  MediaBlock: MediaBlock,
  Margin: Margin,
  SearchCard: SearchCard,
  DropDown: DropDown,
  SignupBlock: SignupBlock,
  CourseInstructors: CourseInstructors,
  List: List,
  Form: Form,
  Accordion: Accordion,
  Filter: Filter,
  ImagBox: ImageBox,
  MediaParallax: MediaParallax,
  OFASPage: OFASPage,
  ErrorPage: ErrorPage,
  ListPage: ListPage,
  CreateUserPage: CreateUserPage,
  MyProfilePage: MyProfilePage,
  CertificateSearchPage: CertificateSearchPage,
  CheckoutPage: CheckoutPage,
  ShoppingCartPage: ShoppingCartPage,
  EmployeeList: EmployeeList,
  EmployeeListPage: EmployeeListPage,
  InstructorSearchPage: InstructorSearchPage,
  ProductListPage: ProductListPage,
  CheckoutConfirmationPage: CheckoutConfirmationPage
}

document.addEventListener('DOMContentLoaded', function (event) {
  function initialize(componentContainer: HTMLElement) {
    var props = JSON.parse(componentContainer.dataset.props || '{}')

    props.propertyName = componentContainer.dataset.propertyName
    ReactDOM.render(
      React.createElement(ComponentTypes[componentContainer.dataset.reactComponent!], props),
      componentContainer,
    )
  }

  var reactComponents = document.querySelectorAll<HTMLElement>('[data-react-component]')

  reactComponents.forEach(initialize)
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
