import { Header } from 'components/layout/header'
import { Layout } from 'components/layout/layout'
import React from 'react'
import './_forgot-password-page.scss'
import { ForgotPasswordForm } from 'components/elements/forms/forgot-passord-form/forgot-password-form'

interface ForgotPasswordPageProps {
  layout: React.ComponentProps<typeof Layout>
  header: React.ComponentProps<typeof Header>
  forgotPasswordForm: React.ComponentProps<typeof ForgotPasswordForm>
}

export const ForgotPasswordPage = ({ layout, header, forgotPasswordForm }: ForgotPasswordPageProps) => {
  return (
    <Layout {...layout} theme={layout.theme ?? {color: 'blue'}} className='forgot-password-page'>
      {header && <Header {...header} />}
      <ForgotPasswordForm {...forgotPasswordForm}/>
    </Layout>
  )
}
