import React from 'react'
import { Layout } from '../../components/layout/layout'
import { ComponentList } from '../../components/blocks/component-list/component-list'
import { pageProps } from 'types'

interface LandingPageProps extends pageProps {}

export const LandingPage = ({ layout, blocks }: LandingPageProps) => (
  <Layout {...layout} theme={layout.theme ?? {color: 'blue', type: 'solid'}}>
    {blocks && <ComponentList blocks={blocks} />}
  </Layout>
)
