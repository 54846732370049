import React from 'react'
import './_tag.scss'
import { Colors } from 'types'
import cn from 'classnames'
import { ReactComponent as BookIcon } from 'assets/icons/book-open.svg'
import { ReactComponent as FireIcon } from 'assets/icons/fire.svg'

interface TagProps {
  background?: boolean
  color?: string
  tag?: string
  icon?: 'book' | 'fire' //Add based on the tag that are available
  url?: string
}

export const Tag = ({ background, color = 'orange', tag, icon, url }: TagProps) => {
  const content = (
    <>
      {icon && icon === 'fire' ? <FireIcon /> : icon === 'book' ? <BookIcon /> : null}
      {tag}
    </>
  )
  return url ? (
    <a
      className={cn('tag tag__link', {
        [`tag__bg-${color}`]: background,
        [`tag-${color}`]: color,
      })}
      href={url}
    >
      {content}
    </a>
  ) : (
    <div
      className={cn('tag ', {
        [`tag__bg-${color}`]: background,
        [`tag-${color}`]: color,
      })}
    >
      {content}
    </div>
  )
}
