import './_signup-block.scss'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'
import { Heading } from 'components/elements/heading/heading'
import cn from 'classnames'
import { useColor } from 'context/themeContext'

interface SignupBlockProps {
  events: {
    location: string
    fromDate: string
    toDate?: string
    price: string
    signupLink: {
      text: string
      url?: string
      target?: string
    }
  }[]
}

export const SignupBlock = ({ events }: SignupBlockProps) => {
  const { color } = useColor()

  return (
    <div
      className={cn('signup-block', {
        [`signup-block__color-${color}`]: color,
      })}
    >
      <table className='signup-block__table'>
        <tr className='signup-block__title-row'>
          <th>
            <Heading level={3} size='s' className='signup-block__title'>
              Sted
            </Heading>
          </th>
          <th>
            <Heading level={3} size='s' className='signup-block__title'>
              Dato
            </Heading>
          </th>
          <th>
            <Heading level={3} size='s' className='signup-block__title'>
              Pris
            </Heading>
          </th>
          <th>
            <Heading level={3} size='s' className='signup-block__title'>
              Påmelding
            </Heading>
          </th>
        </tr>
        {events &&
          events.map((item, i) => (
            <tr className='signup-block__items-row'>
              <td className='signup-block__text'>{item.location}</td>
              <td className='signup-block__text'>
                {item.toDate ? `${item.fromDate} - ${item.toDate}` : item.fromDate}
              </td>
              <td className='signup-block__text'>{item.price}</td>
              <td>
                {item.signupLink.url ? (
                  <>
                    <a
                      href={item.signupLink.url}
                      target={item.signupLink.target}
                      className='signup-block__link'
                    >
                      <span>{item.signupLink.text}</span>
                    </a>
                    <ArrowRightIcon className='signup-block__icon' />
                  </>
                ) : (
                  <div className='signup-block__text-faded'>{item.signupLink.text}</div>
                )}
              </td>
            </tr>
          ))}
      </table>
    </div>
  )
}
