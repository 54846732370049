import React, { forwardRef, useImperativeHandle } from 'react'
import { Heading } from 'components/elements/heading/heading'
import { Radio } from 'components/elements/forms/radio/radio'
import { InputField } from 'components/elements/forms/input-field/input-field'
import { Button } from 'components/elements/button/button'
import axios from 'axios'
import './_certificate-search.scss'
import '../../elements/instructor-card/_instructor-card.scss'
import cn from 'classnames'
import { ErrorMessage } from 'components/elements/error-message/error-message'
import { ReactComponent as SadFaceIcon } from 'assets/icons/sad-face.svg'
import { CertificateCard } from 'components/elements/certificate-card/certificate-card'

interface CertificateSearchProps {
  title: string
  certificateNumberRadio: React.ComponentProps<typeof Radio>
  personRadio: React.ComponentProps<typeof Radio>
  certificateNumberInput: React.ComponentProps<typeof InputField>
  personInputs: {
    lastName: React.ComponentProps<typeof InputField>
    firstName: React.ComponentProps<typeof InputField>
    birthDateLabel: string
    birthDateParamName: string
    birthDateErrorText: string
    dayInputAriaLabel: string
    monthInputAriaLabel: string
    yearInputAriaLabel: string
  }
  searchButtonText: string
  searchResultsTitle: string
  certificateFoundText: string
  certificateNumberLabel: string
  certificateStatusLabel: string
  certificateStatusTextValid: string
  certificateStatusTextInvalid: string
  noCertificateFoundTitleText: string
  noCertificateFoundInfoText: string
  endpoint: string
}

interface CertificateSearchResult {
  certificateNumber: number
  valid: boolean
  validTo: string
}

export const CertificateSearch = ({
  title,
  certificateNumberRadio,
  personRadio,
  certificateNumberInput,
  personInputs,
  searchButtonText,
  endpoint,
  searchResultsTitle,
  certificateFoundText,
  certificateNumberLabel,
  certificateStatusLabel,
  certificateStatusTextValid,
  certificateStatusTextInvalid,
  noCertificateFoundTitleText,
  noCertificateFoundInfoText,
}: CertificateSearchProps) => {
  const [currentSelected, setCurrentSelected] = React.useState(
    certificateNumberRadio ? certificateNumberRadio.value : '',
  )
  const [results, setResults] = React.useState<CertificateSearchResult[] | undefined>()

  const [certificateNumber, setCertificateNumber] = React.useState('')

  const [lastName, setLastName] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [day, setDay] = React.useState<string>('')
  const [month, setMonth] = React.useState<string>('')
  const [year, setYear] = React.useState<string>('')

  const [certificateNumberError, setCertificateNumberError] = React.useState(false)
  const [lastNameError, setLastNameError] = React.useState(false)
  const [firstNameError, setFirstNameError] = React.useState(false)
  const [dateError, setDateError] = React.useState(false)
  const dateRefs = {
    dayRef: React.useRef<BirthDateInputFuncs>(null),
    monthRef: React.useRef<BirthDateInputFuncs>(null),
    yearRef: React.useRef<BirthDateInputFuncs>(null),
  }

  certificateNumberRadio.isSelected = currentSelected === certificateNumberRadio.value
  personRadio.isSelected = currentSelected === personRadio.value

  const onRadioChange = (e: any) => {
    clearErrors()
    clearValues()
    setResults(undefined)
    setCurrentSelected(e.target.value)
  }

  const search = () => {
    let params

    if (currentSelected === certificateNumberRadio.value && validateCertificationInput()) {
      params = { [certificateNumberInput.id ?? 'certificateId']: certificateNumber }
      sendRequest(params)
    }

    if (currentSelected === personRadio.value && validatePersonInputs()) {
      params = {
        [personInputs.lastName.id ?? 'lastname']: lastName,
        [personInputs.firstName.id ?? 'firstname']: firstName,
        [personInputs.birthDateParamName]: `${year}-${month}-${day}`,
      }

      sendRequest(params)
    }
  }

  const sendRequest = (params: any) => {
    axios
      .get(endpoint, { params: params })
      .then((response) => {
        if (response.data.certificatesList !== undefined) setResults(response.data.certificatesList)
        else setResults([])
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const validateCertificationInput = () => {
    if (certificateNumber === '') {
      setCertificateNumberError(true)
    } else {
      setCertificateNumberError(false)
    }

    return certificateNumber !== ''
  }

  const validatePersonInputs = () => {
    if (lastName === '') {
      setLastNameError(true)
    } else {
      setLastNameError(false)
    }

    if (firstName === '') {
      setFirstNameError(true)
    } else {
      setFirstNameError(false)
    }

    if (day.toString() === '0' || month.toString() === '0' || year.toString() === '0') {
      setDateError(true)
    } else {
      setDateError(false)
    }

    let dayIsValid = dateRefs.dayRef.current?.isValid()
    let monthIsValid = dateRefs.monthRef.current?.isValid()
    let yearIsValid = dateRefs.yearRef.current?.isValid()

    let date: Date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day)) // Month - 1 because month in javascript Date object is 0-indexed
    if (isNaN(date.getTime())) {
      setDateError(true)
      return false
    }

    return !(firstName === '' || lastName === '' || !dayIsValid || !monthIsValid || !yearIsValid)
  }

  const clearErrors = () => {
    setCertificateNumberError(false)
    setFirstNameError(false)
    setLastNameError(false)
    setDateError(false)
  }

  const clearValues = () => {
    setCertificateNumber('')
    setFirstName('')
    setLastName('')
    setDay('')
    setMonth('')
    setYear('')
  }

  const setDateValue = (value: any, func: React.Dispatch<React.SetStateAction<string>>) =>
    func(value === '' ? 0 : value)

  return (
    <div className='certificate-search'>
      <div className='certificate-search__form'>
        {title && <Heading>{title}</Heading>}
        {certificateNumberRadio && (
          <div className='certificate-search__form__radio'>
            <Radio
              onChange={onRadioChange}
              isSelected={currentSelected === certificateNumberRadio.value}
              {...certificateNumberRadio}
            />
          </div>
        )}
        {personRadio && (
          <Radio
            onChange={onRadioChange}
            isSelected={currentSelected === personRadio.value}
            {...personRadio}
          />
        )}
        <div className='certificate-search__form__inputs'>
          {currentSelected === certificateNumberRadio.value && (
            <InputField
              onChange={(e) => setCertificateNumber(e.target.value)}
              error={certificateNumberError}
              {...certificateNumberInput}
            />
          )}
          {currentSelected === personRadio.value && (
            <React.Fragment>
              <InputField
                onChange={(e) => setFirstName(e.target.value)}
                error={firstNameError}
                {...personInputs.firstName}
              />
              <InputField
                onChange={(e) => setLastName(e.target.value)}
                error={lastNameError}
                {...personInputs.lastName}
              />
              <label className='certificate-search__form__inputs__label'>
                {personInputs.birthDateLabel}
              </label>
              <div className='certificate-search__form__inputs__date'>
                <BirthDateInput
                  onChange={(value) => setDateValue(value, setDay)}
                  placeholder={'DD'}
                  value={day}
                  ariaLabel={personInputs.dayInputAriaLabel}
                  ref={dateRefs.dayRef}
                  minValue={1}
                  maxValue={31}
                />
                <BirthDateInput
                  onChange={(value) => setDateValue(value, setMonth)}
                  placeholder={'MM'}
                  value={month}
                  ariaLabel={personInputs.monthInputAriaLabel}
                  ref={dateRefs.monthRef}
                  minValue={1}
                  maxValue={12}
                />
                <BirthDateInput
                  onChange={(value) => setDateValue(value, setYear)}
                  placeholder={'YYYY'}
                  value={year}
                  ariaLabel={personInputs.yearInputAriaLabel}
                  ref={dateRefs.yearRef}
                  minValue={1900}
                  maxValue={new Date().getFullYear()}
                />
              </div>
              <div aria-live='assertive'>
                {dateError ? (
                  <ErrorMessage textId='dateError' text={personInputs.birthDateErrorText} />
                ) : null}
              </div>
            </React.Fragment>
          )}
        </div>
        {searchButtonText && <Button onClick={search} text={searchButtonText} />}
      </div>
      {results && (
        <div className='certificate-search__results'>
          <Heading>{searchResultsTitle}</Heading>
          {results !== undefined && results.length > 0 && results[0].valid ? (
            <div className='certificate-search__results__found'>
              <div>
                <p className='results-text'>{certificateFoundText}</p>
              </div>
              {results.map((result: CertificateSearchResult, index) => (
                <CertificateCard
                  key={index}
                  numberLabel={certificateNumberLabel}
                  number={result.certificateNumber.toString()}
                  statusLabel={certificateStatusLabel}
                  statusText={
                    result.valid ? certificateStatusTextValid : certificateStatusTextInvalid
                  }
                  status={result.valid}
                  validTo={result.validTo}
                />
              ))}
            </div>
          ) : (
            <div className='certificate-search__results__none'>
              <SadFaceIcon />
              <Heading>{noCertificateFoundTitleText}</Heading>
              <p>{noCertificateFoundInfoText}</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

interface BirthDateInputFuncs {
  isValid(): boolean
}

interface BirthDateInputProps {
  onChange: (str: string) => void
  placeholder: string
  value?: string
  ariaLabel: string
  minValue: number
  maxValue: number
}

export const BirthDateInput = forwardRef<BirthDateInputFuncs, BirthDateInputProps>(
  (
    { onChange, placeholder, value, ariaLabel, minValue = 1, maxValue }: BirthDateInputProps,
    ref,
  ) => {
    const [error, setError] = React.useState<boolean>(false)

    useImperativeHandle(ref, () => ({
      isValid() {
        if (value != null && value.toString() !== '') {
          setError(false)
          return true
        } else {
          setError(true)
          return false
        }
      },
    }))

    const optionValues: number[] = Array.from(Array(maxValue - minValue + 1).keys())
      .map((x) => x + minValue)
      .reverse()

    return (
      <div>
        <select
          value={value}
          onChange={(e) => {
            setError(e.target.value === '')
            onChange(e.target.value)
          }}
          className={cn('certificate-search__form__inputs__date__field', {
            'certificate-search__form__inputs__date__field__error': error,
          })}
        >
          <option value='' disabled>
            {placeholder}
          </option>
          {optionValues.map((x, index) => (
            <option key={index} value={x < 10 ? `0${x}` : x}>
              {x < 10 ? `0${x}` : x}
            </option>
          ))}
        </select>
      </div>
    )
  },
)
