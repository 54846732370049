import React from 'react'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import './_error-message.scss'

export interface ErrorMessageProps {
  text: string,
  textId?: string
}

export const ErrorMessage = ({ text, textId }: ErrorMessageProps) => {
  return (
    <div aria-live='assertive' className='error-message'>
      <InfoIcon className='error-message__icon' />
      <p id={textId} className='error-message__text'>{text}</p>
    </div>
  )
}
