import React from 'react'
import cn from 'classnames'
import './_title-and-ingress.scss'
import { Heading } from 'components/elements/heading/heading'
import { Colors } from 'types'

interface TitleAndIngressProps {
  heading: React.ComponentProps<typeof Heading>
  ingress?: string
  color?: Extract<Colors, 'orange' | 'green' | 'blue' | 'red' | 'dark-red'>
  type?: 'display'
  className?: string
}

export const TitleAndIngress = ({
  heading = {
    title: 'Du har glemt heading',
    level: 1,
    size: 'l',
  },
  ingress,
  color = 'dark-red',
  type,
  className,
}: TitleAndIngressProps) => (
  <div
    className={cn('title-and-ingress', className, {
      [`title-and-ingress__color-${color === 'orange' || color === 'red' ? 'dark-red' : color}`]:
        color,
      [`title-and-ingress__type-${type}`]: type,
    })}
  >
    <Heading className='title-and-ingress__title' {...heading} />
    {ingress && <p className='title-and-ingress__ingress'>{ingress}</p>}
  </div>
)
