import React, { forwardRef, useImperativeHandle } from 'react'
import { ReactComponent as Logo } from 'assets/logo/OFAS-logo.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'

import cn from 'classnames'
import useAnimateHeight from '../../../hooks/useAnimateHeight.hook'
import './OFAS-menu.scss'
import useScrollListener from '../../../hooks/useScrollListener.hook'
import { Link } from 'types'
import { useHeader } from 'context/headerContext'

export interface OFASMenuFuncs {
  closeExpandables(): void
}
interface OFASMenuProps {
  logo: {
    ariaLabel: string
    url: string
  }
  signin?: Link
  ecommerce?: Link
  search?: {
    text: string
    placeholder: string
    searchPageUrl: string
    enpointUrl: string
  }
  profileMenuDropDownItems: {
    label: string
    url: string
  }[]
  closeButtonLabel: string
  openButtonLabel: string
  expandedAriaLabel: string
  itemsInShoppingCartAriaLabel: string
  clearSearchTextAriaLabel: string
  groups: {
    title: string
    url: string
    links: Link[]
  }[]
  hasAnimatedLogo?: boolean
}

export const OFASMenu = forwardRef<OFASMenuFuncs, OFASMenuProps>(
  ({ logo, expandedAriaLabel, closeButtonLabel, openButtonLabel, groups }: OFASMenuProps, ref) => {
    const { showHeader, setShowHeader } = useHeader()

    const [menuIsOpen, setMenuIsOpen] = React.useState(false)
    const [searchIsOpen, setSearchIsOpen] = React.useState(false)
    const [lastHeaderY, setLastHeaderY] = React.useState<number>(0)

    const [height, setHeight] = React.useState<number | undefined>(menuIsOpen ? undefined : 0)
    const [searchHeight, setSearchHeight] = React.useState<number | undefined>(
      searchIsOpen ? undefined : 0,
    )

    const collapseRef = React.useRef<HTMLUListElement>(null)
    const searchRef = React.useRef<HTMLDivElement>(null)
    const inputRef = React.useRef<HTMLInputElement>(null)

    useAnimateHeight(menuIsOpen, setHeight, collapseRef)
    useAnimateHeight(searchIsOpen, setSearchHeight, searchRef)

    const scrollData = useScrollListener()

    const toggleMenu = (type: 'menu' | 'search') => {
      if (menuIsOpen && type === 'search') {
        setMenuIsOpen(false)
        setSearchIsOpen(true)
      } else if (menuIsOpen || searchIsOpen) {
        setMenuIsOpen(false)
        setSearchIsOpen(false)
      } else if (type === 'menu') {
        setMenuIsOpen(!menuIsOpen)
      } else if (type === 'search') {
        setSearchIsOpen(!searchIsOpen)
      }
      console.log(type)

      if (type === 'search' && !searchIsOpen) {
        setTimeout(() => {
          inputRef.current?.focus()
        }, 0.1)
      }
    }

    useImperativeHandle(ref, () => ({
      closeExpandables() {
        setMenuIsOpen(false)
        setSearchIsOpen(false)
      }
    }))

    React.useEffect(() => {
      if (menuIsOpen || searchIsOpen) return
      if (lastHeaderY + 200 > scrollData.y && scrollData.lastY < scrollData.y && !showHeader) {
        // scrolled down less than 200px

        setShowHeader(true)
      } else if (
        lastHeaderY + 200 <= scrollData.y &&
        scrollData.lastY < scrollData.y &&
        showHeader
      ) {
        // scrolled down more than 200px

        setShowHeader(false)
      } else if (scrollData.lastY > scrollData.y) {
        // scrolled up

        if (!showHeader) {
          // hide header if its visible

          setShowHeader(true)
        }
        if (scrollData.y !== lastHeaderY) {
          // update header y if it has changed

          setLastHeaderY(scrollData.y)
        }
      }
    }, [scrollData, lastHeaderY, showHeader, menuIsOpen])

    return (
      <nav
        className={cn('OFAS-menu', {
          'OFAS-menu--is-open-color': menuIsOpen,
          'OFAS-menu--is-open': menuIsOpen,
          'OFAS-menu--hide-header': !showHeader,
        })}
      >
        <div className='OFAS-menu__header'>
          <a className='OFAS-menu__logo' href={logo.url} aria-label={logo.ariaLabel}>
            <Logo />
          </a>
          <div className='OFAS-menu__bar'>
            <button
              aria-label={menuIsOpen ? closeButtonLabel : openButtonLabel}
              aria-expanded={menuIsOpen}
              onClick={() => toggleMenu('menu')}
              className='OFAS-menu__hamburger-button'
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
        <div className='OFAS-menu__search-content' style={{ height: searchHeight }}>
          <div className='OFAS-menu__search' ref={searchRef}></div>
        </div>
        {groups && groups.length !== 0 && (
          <div className='OFAS-menu__content' style={{ height }}>
            <ul className='OFAS-menu__groups' ref={collapseRef} aria-label={expandedAriaLabel}>
              <li className='OFAS-menu__mobile'>
                <div className='OFAS-menu__mobile-links'></div>
              </li>
              {groups.map((group) => (
                <li className='OFAS-menu__group' key={group.title}>
                  <div className='OFAS-menu__group-header'>
                    <h2 id={group.title} className='OFAS-menu__group-title'>
                      <a href={group.url}>{group.title}</a>
                    </h2>
                  </div>
                  {group.links && group.links.length !== 0 && (
                    <ul aria-labelledby={group.title} className='OFAS-menu__group-links'>
                      {group.links.map((link) => (
                        <li className='OFAS-menu__group-link-container' key={link.text}>
                          <ArrowRightIcon />
                          <a className='OFAS-menu__group-link' href={link.url}>
                            <span>{link.text}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </nav>
    )
  },
)
