import React from 'react'
import cn from 'classnames'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { useColor } from 'context/themeContext'
import './_page-nav.scss'

interface PageNavProps {
  nextPage: () => void
  showNext?: boolean
  hitsText?: string
  prevPage: () => void
  showPrev?: boolean
}

export const PageNav = ({ nextPage, showNext, hitsText, prevPage, showPrev }: PageNavProps) => {
  const { color } = useColor()

  return (
    <div
      className={cn('page-nav', {
        [`page-nav--color-${color === 'green' || color === 'blue' ? color : 'dark-red'}`]:
          color !== 'white',
      })}
    >
      <button
        onClick={(e) => {
          e.preventDefault()
          prevPage()
        }}
        className={cn('page-nav__button')}
        disabled={!showPrev}
      >
        <ArrowLeftIcon />
        <span>Forrige side</span>
      </button>
      <div className='page-nav__hits'>{hitsText}</div>
      <button
        onClick={(e) => {
          e.preventDefault()
          nextPage()
        }}
        className={cn('page-nav__button')}
        disabled={!showNext}
      >
        <span>Neste side</span>
        <ArrowRightIcon />
      </button>
    </div>
  )
}
