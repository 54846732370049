import { InstructorCard } from 'components/elements/instructor-card/instructor-card';
import React from 'react'
import { instructorInfoType } from 'types';
import './_instructor-list.scss'

export interface InstructorListProps {
    instructors: instructorInfoType[]
    instructorCardLabels: {
        companyNameLabel: string,
        companyAddressLabel: string,
        countyLabel: string,
        phoneLabel: string,
        emailLabel: string,
        certificateNumberLabel: string,
        isValidStatusLabel: string,
        isValidLabel: string,
        isInvalidLabel: string,
    }
}

export const InstructorList = ({instructors, instructorCardLabels}: InstructorListProps) => (
    <div className='instructor-list'>
        {instructors && (
            <ul className='instructor-list__list'>
                {instructors.map((instructor, index) => (
                    <li key={index} className='instructor-list__item'>
                        <InstructorCard {...instructorCardLabels} instructorInfo={instructor}/>
                        <hr className='instructor-list__break-line' />
                    </li>
                ))}
            </ul>
        )}
    </div>
);
