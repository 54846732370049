import { createContext, useContext } from 'react'
import { Colors } from 'types'
export type colorType = {
  color: Extract<Colors, 'orange' | 'red' | 'dark-red' | 'blue' | 'green' | 'yellow' | 'white'>
  type?: 'solid' | 'gradient'
}

const DefaultValue: colorType = { color: 'white', type: 'solid' }

export const colorContext: React.Context<colorType> = createContext(DefaultValue)
export const ColorProvider = colorContext.Provider

export const useColor = () => {
  const color = useContext(colorContext)
  return color
}
