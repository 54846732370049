import { Heading } from 'components/elements/heading/heading'
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg'
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg'

import { Image } from 'types'
import './_employee-list.scss'

export interface EmployeeListProps {
  title: string
  employees: {
    image: Image
    name: string
    position: string
    info?: string
    email: string
    phone: string
  }[]
}

export const EmployeeList = ({ title, employees }: EmployeeListProps) => (
  <div className='employee-list'>
    <Heading level={1} size='s' weight={700} className='employee-list__title'>
      {title}
    </Heading>
    {employees && (
      <ul className='employee-list__list'>
        {employees.map((employee) => (
          <li className='employee-list__employee'>
            <div className='employee-list__employee-text'>
              <Heading className='employee-list__employee-name' level={2} size='s' weight={700}>
                {employee.name}
              </Heading>
              <p className='employee-list__employee-position'>{employee.position}</p>
              {employee.info && <p className='employee-list__employee-info'>{employee.info}</p>}
              <div className='employee-list__employee-contact'>
                <a className='employee-list__link' href={'mailto:' + employee.email}>
                  <div className='employee-list__link-icon'>
                    <EmailIcon />
                  </div>
                  <span>{employee.email}</span>
                </a>
                <a className='employee-list__link'>
                  <div className='employee-list__link-icon'>
                    <PhoneIcon />
                  </div>
                  <span>{employee.phone}</span>
                </a>
              </div>
            </div>
            <img
              className='employee-list__employee-image'
              src={employee.image.url}
              alt={employee.image.alt}
            />
          </li>
        ))}
      </ul>
    )}
  </div>
)
