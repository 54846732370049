import React from 'react'
import './_quote.scss'
import { ReactComponent as QuoteIcon } from 'assets/icons/quote.svg'
import { Colors } from 'types'
import cn from 'classnames'
import { useColor } from 'context/themeContext'

interface QuoteProps {
  text: string
  color?: Extract<Colors, 'orange' | 'red' | 'blue'>
}

export const Quote = ({ text }: QuoteProps) => {
  const { color } = useColor()
  return (
    <div
      className={cn('quote ', {
        [`quote-${color}`]: color,
      })}
    >
      <QuoteIcon
        className={cn('quote__icon ', {
          [`quote__icon-${color}`]: color,
        })}
      />
      <p className='quote__text'>{text}</p>
    </div>
  )
}
