import React from 'react'
import { Button, ButtonProps } from '../../elements/button/button'
import './_news-article-block.scss'
import { ReactComponent as VectorIcon } from 'assets/icons/vector.svg'
import { Image } from 'types'
import { TitleAndIngress } from 'components/elements/title-and-ingress/title-and-ingress'
import { Heading } from 'components/elements/heading/heading'
import { useColor } from 'context/themeContext'
import cn from 'classnames'

interface NewsArticleBlockProps {
  tag?: {
    title: string
  }
  date: string
  title: string
  text: string
  imageType: 'landscape' | 'standing'
  image: Image
  button: ButtonProps
  buttonSideText?: string
}

export const NewsArticleBlock = ({
  tag,
  title,
  date,
  text,
  imageType,
  image,
  button,
  buttonSideText,
}: NewsArticleBlockProps) => {
  const { color } = useColor()

  if (!imageType) imageType = 'landscape'

  return (
    <div
      className={cn('bg', {
        [`color-${
          color === 'green' || color === 'blue' ? color : color === 'red' ? 'white' : 'dark-red'
        }`]: color !== 'white',
        [`bgcolor-${color === 'red' ? 'dark-red' : color}${
          color === 'orange' ? '-2' : color === 'red' ? '' : '-1'
        }`]: color !== 'white',
      })}
    >
      <div className='news-article-block'>
        <div className='news-article-block__wrapper'>
          {tag && (
            <div className='news-article-block__tag'>
              <VectorIcon className='news-article-block__tagicon' />
              {tag.title}
            </div>
          )}

          <div className={`news-article-block__image news-article-block__image__${imageType}`}>
            <img src={image.url} alt={image.alt} />
          </div>

          <div className='news-article-block__title-content'>
            <div className='news-article-block__date'>{date}</div>

            <Heading className='news-article-block__title' level={2} size='l'>
              {title}
            </Heading>
            <p className='news-article-block__text'>{text}</p>
            <div className='news-article-block__button'>
              <Button
                {...button}
                color={
                  color === 'red' || color === 'dark-red'
                    ? 'white'
                    : color === 'yellow'
                    ? 'red'
                    : color
                }
              />
              {buttonSideText && (
                <span className='news-article-block__button-side-text'>{buttonSideText}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
