import axios from 'axios'

const addProduct = async (
  data: {
    productId: number
    quantity: number
  },
  endpoint: string,
) => {
  try {
    const response = await axios.post(endpoint, data)
    if (response.data.success) {
      return response.data
    }
    return -1
  } catch (e) {
    console.error(e)
    return -1
  }
}

const editProductQuantity = async (
  data: {
    productId: number
    newQuantity: number
  },
  endpoint: string,
) => {
  try {
    const response = await axios.post(endpoint, data)
    if (response.data.success) {
      return response.data
    }
    return -1
  } catch (e) {
    console.error(e)
    return -1
  }
}

const removeProduct = async (
  data: {
    productId: number
  },
  endpoint: string,
) => {
  try {
    const response = await axios.post(endpoint, data)
    if(response.data.success){
      return response.data
    }
    return -1
  } catch (e) {
    console.error(e)
    return -1
  }
}

const ShoppingCartService = {
  addProduct,
  editProductQuantity,
  removeProduct,
}

export default ShoppingCartService
