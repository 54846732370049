import { Button } from 'components/elements/button/button'
import React, { useState } from 'react'
import { InputField } from '../input-field/input-field'
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg'
import { ErrorMessage } from 'components/elements/error-message/error-message'
import { phoneNumberIsValid } from 'helpers/validation'
import UserService from 'services/user_service'

interface ForgotPasswordFormProps {
  phoneInputLabel: string
  buttonLable: string
  invalidPhoneNumberErrorMessage: string
  emptyInputErrorMessage: string
  failedSendingResetLinkMessage: string
  verificationMessageText: string
  endpoint: string
}

export const ForgotPasswordForm = ({
  phoneInputLabel,
  buttonLable,
  verificationMessageText,
  invalidPhoneNumberErrorMessage,
  failedSendingResetLinkMessage,
  emptyInputErrorMessage,
  endpoint,
}: ForgotPasswordFormProps) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [showVerificationMsg, setVerificationMsg] = useState(false)
  const [emptyInputError, setEmptyInputError] = useState(false)
  const [invalidPhoneNumberError, setInvalidPhoneNumberError] = useState(false)
  const [sendResetLinkFailed, setSendResetLinkFailed] = useState(false)

  const handleChange = (newPhoneNumber: string) => {
    setPhoneNumber(newPhoneNumber)
  }

  return (
    <div className='forgot-password-page__form'>
      <div className='forgot-password-page__content'>
        <div className='forgot-password-page__input-field'>
          <InputField
            title={phoneInputLabel}
            required
            onChange={(e) => handleChange(e.target.value)}
            error={emptyInputError || invalidPhoneNumberError}
          />
        </div>
        <div className='forgot-password-page__submit-button'>
          <div className='forgot-password-page__button-box'>
            <Button
              size='m'
              text={buttonLable}
              onClick={() => {
                if (phoneNumber === '') {
                  setEmptyInputError(true)
                  setVerificationMsg(false)
                  setInvalidPhoneNumberError(false)
                  setSendResetLinkFailed(false)
                } else if (!phoneNumberIsValid(phoneNumber)) {
                  setInvalidPhoneNumberError(true)
                  setVerificationMsg(false)
                  setEmptyInputError(false)
                  setSendResetLinkFailed(false)
                } else {
                  setSendResetLinkFailed(false)
                  UserService.forgotPassword(
                    phoneNumber,
                    endpoint,
                    setSendResetLinkFailed,
                    setVerificationMsg,
                  )
                  setEmptyInputError(false)
                  setInvalidPhoneNumberError(false)
                }
              }}
            />
          </div>
          {emptyInputError && emptyInputErrorMessage && (
            <div className='forgot-password-page__response-message forgot-password-page__error'>
              <ErrorMessage text={emptyInputErrorMessage} />
            </div>
          )}
          {invalidPhoneNumberError && invalidPhoneNumberErrorMessage && (
            <div className='forgot-password-page__response-message forgot-password-page__error'>
              <ErrorMessage text={invalidPhoneNumberErrorMessage} />
            </div>
          )}
          {sendResetLinkFailed && failedSendingResetLinkMessage && (
            <div className='forgot-password-page__response-message forgot-password-page__error'>
              <ErrorMessage text={failedSendingResetLinkMessage} />
            </div>
          )}
          {showVerificationMsg ? (
            <div className='forgot-password-page__response-message forgot-password-page__valid'>
              <CheckCircle />
              <p>{verificationMessageText}</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
