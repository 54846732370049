import React from 'react'
import './_filter.scss'
import cn from 'classnames'
import useAnimateHeight from 'hooks/useAnimateHeight.hook'
import { DropDown } from 'components/elements/dropdown/dropdown'
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'
import { ReactComponent as MinusCircleIcon } from 'assets/icons/minus-circle.svg'
import { ReactComponent as PlusCircleIcon } from 'assets/icons/plus-circle.svg'
import { useColor } from 'context/themeContext'
import { filterOptionsType, filterType } from 'types'

type FilterProps = {
  filterOptions: filterOptionsType
  setFilter?: (filter: filterType) => void
  filter?: filterType
  setPage?: any
  filterLabel?: string
  clearFilterText?: string
}
export const Filter = ({
  filterOptions,
  setFilter,
  filter,
  setPage,
  filterLabel,
  clearFilterText,
}: FilterProps) => {
  const [open, setOpen] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)
  const [height, setHeight] = React.useState<number | undefined>(open ? undefined : 0)
  useAnimateHeight(open, setHeight, ref)

  const { color } = useColor()
  return (
    <div
      className={cn('filter__filters', {
        [`color-${color === 'white' ? 'dark-red' : color}`]: color,
      })}
      style={{ overflow: open ? undefined : 'hidden' }}
    >
      <div className='filter__wrapper'>
        <button className={cn('filter__button', { 'filter__button__open': open })} onClick={() => setOpen(!open)}>
          {open ? (
            <MinusCircleIcon className='filter__icon-plus' />
          ) : (
            <PlusCircleIcon className='filter__icon-plus' />
          )}
          {filterLabel}
        </button>
      </div>
      <div style={{ height }}>
        <div className={cn('filter__filter')} ref={ref}>
          {Object.entries(filterOptions).map(([key, option], i) => (
            <DropDown
              key={i}
              options={option.options}
              setValue={(value) => {
                let newFilter: filterType = {}
                newFilter[key] = value

                setFilter && setFilter({ ...filter, ...newFilter })
                setPage(1)
              }}
              value={filter && filter[key] ? filter[key] : option.defaultValue}
            />
          ))}

          <button
            onClick={() => {
              setFilter && setFilter({})
              setPage(1)
            }}
            className='filter__button-trash'
          >
            <div className='filter__trash'>
              <TrashIcon className='filter__icon-trash' />
              {clearFilterText}
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}
